import React, { useLayoutEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { StatusIndicator, StatusContent } from './Header.styles'
import { Placement } from '@shared/Popover'
import { DELIVERABLE_STATUS_COLORS } from '@helpers/statuses'
import { createTooltip, defaultTooltipOptions } from '@imo/imo-ui-toolkit'
import { omit } from 'lodash/fp'
import { renderToString } from 'react-dom/server'
import { followCursor } from 'tippy.js'

const Status = ({ status }) => {
  const statusRef = useRef()

  useLayoutEffect(() => {
    const tooltip = createTooltip(statusRef?.current, {
      ...omit(['animation', 'offset'], defaultTooltipOptions),
      content: renderToString(<StatusContent color={DELIVERABLE_STATUS_COLORS[status]}>{status}</StatusContent>),
      placement: Placement.BOTTOM,
      followCursor: 'horizontal',
      plugins: [followCursor],
      allowHTML: true,
    })

    return () => tooltip.destroy()
  }, [statusRef, status])

  return <StatusIndicator status={status} ref={statusRef} />
}

Status.propTypes = {
  status: PropTypes.string,
}

Status.defaultProps = {
  status: '',
}

export default Status
