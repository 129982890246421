import styled from 'styled-components'
import { Dialog, Callout } from '@blueprintjs/core'
// @ts-expect-error allow svg imports
import MyImoLogo from '@assets/img/logo/my-imo-logo.svg'
import { FONT_FAMILY } from '@imo/imo-ui-toolkit'

export const StyledDialog = styled(Dialog)`
  .bp4-dialog-header > .bp4-heading {
    margin: 4px 0 0 0;
  }
`

export const TopErrorBlock = styled.div`
  display: flex;
  justify-content: flex-end;
  height: 130px;
  margin: 0 0 20px 0;
`

export const ScrolledErrorBlock = styled(Callout)`
  max-height: 150px;
  overflow-y: scroll;
`

export const Logo = styled.div`
  background-image: url(${MyImoLogo});
  background-attachment: unset;
  background-repeat: no-repeat;
  background-position: top;
  background-size: contain;
  width: 200px;
  height: 50px;
  margin: 28px 0 0;
`

export const StyledText = styled.div`
  color: #272727;
  font: 14px/20px ${FONT_FAMILY};
  font-weight: 300;
  padding: 20px 0;
`

export const StyledBody = styled.div`
  margin: 10px 20px;
`

export const StyledFooter = styled.div`
  margin: 0 20px 20px;
`

export const FooterActions = styled.div`
  display: flex;
  justify-content: flex-end;

  .bp4-button {
    margin-left: 10px;
  }
`
