import { call, put } from 'redux-saga/effects'
import { fetchKeyIssues, fetchTeamWeekStatus } from '@teamHome/actions/actions'
import { getMyTeamApi } from '@common/net'

import { createSaga } from '@common/sagaCreator/createSaga'

export const deleteKeyIssue = createSaga(function* deleteKeyIssue({
  payload,
}: $TSFixMe): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const { teamId, keyIssueId } = payload
  const myTeamApi = yield getMyTeamApi()
  yield call(myTeamApi.request('deleteKeyIssue', { query: { teamId, keyIssueId } }))

  yield put(fetchKeyIssues(teamId))
  yield put(fetchTeamWeekStatus(teamId))
})
