import { userRoles, accessRights, teams } from '../constants'
import { routesList } from '../routesList'

export const CENTRAL_LEAD_PERMISSIONS = {
  name: teams.CENTRAL,
  role: userRoles.LEAD,
  priority: 3,
  teamsPermissions: {
    deliverable: accessRights.READ_WRITE,
  },
  permissions: [
    {
      route: routesList.DASHBOARD.DELIVERABLE.ROOT,
      accessLevel: accessRights.READ_WRITE,
    },
    {
      route: routesList.DASHBOARD.MY_TEAM.ROOT,
      accessLevel: accessRights.READ_WRITE,
    },
    {
      route: routesList.DASHBOARD.MY_TEAM.DECISION_LOG,
      accessLevel: accessRights.READ_WRITE,
    },
    {
      route: routesList.DASHBOARD.MY_TEAM.RISK_LOG,
      accessLevel: accessRights.READ_WRITE,
    },
    {
      route: routesList.DASHBOARD.MY_TEAM.TEAM_HOME,
      accessLevel: accessRights.READ_WRITE,
    },
    {
      route: routesList.DASHBOARD.MY_TEAM.INTEGRATION_TEAM_DELIVERABLE_TRACKER,
      accessLevel: accessRights.READ_WRITE,
    },
    {
      route: routesList.DASHBOARD.MY_TEAM.VALUE_CAPTURE_V2.ROOT,
      accessLevel: accessRights.READ_WRITE,
    },
    {
      route: routesList.DASHBOARD.MY_TEAM.ORG_DESIGN.ROOT,
      accessLevel: accessRights.READ_WRITE,
    },
    {
      route: routesList.DASHBOARD.MY_TEAM.TEAM_HYPERCARE_LOG,
      accessLevel: accessRights.READ_WRITE,
    },
    {
      route: routesList.DASHBOARD.MY_TEAM.TEAM_WORKSPACE,
      accessLevel: accessRights.READ_WRITE,
    },
    {
      route: routesList.DASHBOARD.MY_TEAM.TEAM_PROJECT_ANALYTICS.ROOT,
      accessLevel: accessRights.READ_WRITE,
    },
    {
      route: routesList.DASHBOARD.MY_TEAM.TEAM_STATUS_UPDATE,
      accessLevel: accessRights.READ_WRITE,
    },
    {
      route: routesList.DASHBOARD.PROJECTS_AND_TASKS,
      accessLevel: accessRights.READ_WRITE,
    },
    {
      route: routesList.TEAM_RESOURCES.ROOT,
      accessLevel: accessRights.READ_ONLY,
    },
    {
      route: routesList.TEAM_RESOURCES.DECISION_LOG,
      accessLevel: accessRights.READ_ONLY,
    },
    {
      route: routesList.TEAM_RESOURCES.RISK_LOG,
      accessLevel: accessRights.READ_ONLY,
    },
    {
      route: routesList.TEAM_RESOURCES.MATRIX,
      accessLevel: accessRights.READ_ONLY,
    },
    {
      route: routesList.COMMUNICATIONS.ROOT,
      accessLevel: accessRights.READ_ONLY,
    },
    {
      route: routesList.DASHBOARD.MY_TEAM.KNOWLEDGE_CENTER,
      accessLevel: accessRights.READ_WRITE,
    },
    {
      route: routesList.DASHBOARD.READ_MORE_PDF,
      accessLevel: accessRights.READ_WRITE,
    },
    {
      route: routesList.DASHBOARD.MY_TEAM.TSA_MASTER_LIST,
      accessLevel: accessRights.READ_WRITE,
    },
  ],
}
