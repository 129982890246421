import { find, get } from 'lodash/fp'
import { createSelector } from 'reselect'
import {
  getSortedVCTeamsFromState,
  sortCategoriesComparator,
  getSortedTargetsListForGrid,
} from '@valueCaptureTrackerV2/teamSetup/selectors/utils'

const localState = get(['client', 'valueCaptureTrackerV2', 'teamSetup'])

export const getTargetList = createSelector(localState, getSortedTargetsListForGrid)

export const getValueCaptureTeams = createSelector(localState, getSortedVCTeamsFromState)

export const getSelectedTargetId = get('selectedTargetId')
export const getSelectedTarget = createSelector(getTargetList, getSelectedTargetId, (items, targetId) =>
  targetId ? find({ id: targetId }, items) : null,
)

export const getBaselineAssumptions = createSelector(localState, get('baselineAssumptions'))

export const getSynergyCategories = createSelector(localState, get('synergyCategories'))
export const getSortSynergyCategories = createSelector(getSynergyCategories, sortCategoriesComparator)

export const getOneTimeCosts = createSelector(localState, get('oneTimeCosts'))
export const getSortOneTimeCosts = createSelector(getOneTimeCosts, sortCategoriesComparator)
export const getIntegrationTeamsList = createSelector(getTargetList, getValueCaptureTeams, (targetList, vcTeams) => {
  const teamsAdded = targetList.map((target: $TSFixMe) => target.team?.id || [])

  return vcTeams.filter((team: $TSFixMe) => !teamsAdded.includes(team.id))
})

export const getIntegrationTeamsListWithTarget = createSelector(
  getTargetList,
  getValueCaptureTeams,
  (targetList, vcTeams) => {
    const teamsAdded = targetList.map((target: $TSFixMe) => target.team?.id || [])

    return vcTeams.filter((team: $TSFixMe) => teamsAdded.includes(team.id))
  },
)
