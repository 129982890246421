import React from 'react'
import { createBrowserRouter, RouteObject } from 'react-router-dom'
import Config from '../../config'
import RouteSwitcher from '../RouteSwitcher'
import { AppLayout } from '../../app'
import NotFoundPage from '../../views/shared/NotFoundPage'
import { controlTowerRouter } from './control-tower-router'
import { dashboardRouter } from './dashboard-router'
import { orgDesignTrackerRouter } from './org-design-tracker-router'
import { vcTrackerRouter } from './vc-tracker-router'
import { teamResourcesRouter } from './team-resources-router'
import { ImoIcon } from '@imo/imo-ui-toolkit'
import { configRouter } from './config-router'

const getRouterBasename = () => {
  let basename = Config.BASE_PATH

  if (basename[basename.length - 1] === '/') {
    basename = basename.slice(0, -1)
  }

  return basename
}

export const routes: RouteObject[] = [
  {
    element: <AppLayout />,
    children: [
      { path: '/', element: <RouteSwitcher /> },
      { path: '*', element: <NotFoundPage /> },
      { path: '/auth/callback', element: <RouteSwitcher /> },
      {
        path: '/communications',
        lazy: async () => {
          const { CommunicationsWithAccessControl } = await import(
            /* webpackChunkName: "communications" */ /* webpackPrefetch: true */ '@views/myImoClient/pages/Communications'
          )
          return { element: <CommunicationsWithAccessControl /> }
        },
        handle: { crumb: { icon: <ImoIcon icon="communications" size={18} />, title: 'Communications Calendar' } },
      },
      {
        path: '/executive-dashboard',
        lazy: async () => {
          const { ExecutiveDashboardRenderer } = await import(
            /* webpackChunkName: "executive-dashboard" */ /* webpackPrefetch: true */ '@views/myImoClient/pages/ExecutiveDashboard/ExecutiveDashboardRenderer'
          )
          return { element: <ExecutiveDashboardRenderer /> }
        },
        handle: { crumb: { icon: 'playbook', title: 'Executive Dashboard' } },
      },
      ...dashboardRouter,
      ...controlTowerRouter,
      ...vcTrackerRouter,
      ...orgDesignTrackerRouter,
      ...teamResourcesRouter,
      ...configRouter,
    ],
  },
]

export const browserRouter = createBrowserRouter(routes, {
  basename: getRouterBasename(),
})
