import styled from 'styled-components'
import { FONT_FAMILY_BOWER, greyNeutral } from '@imo/imo-ui-toolkit'
import { Colors } from '@blueprintjs/core'

export const StyledHeaderTitle = styled.div`
  display: flex;
  align-items: center;
  margin-right: 10px;
  color: ${greyNeutral};
`

export const Title = styled.h1<{ $fontFamily?: string; $fontSize?: string }>`
  font: ${(props) => props.$fontSize ?? '20px'} ${(props) => props.$fontFamily ?? FONT_FAMILY_BOWER};
  font-weight: 500;
  color: ${Colors.BLACK};
  margin: 0;
  margin-right: 5px;
  line-height: 1.2em;
`

export const Question = styled.div`
  line-height: 1;
`
