import { get } from 'lodash'
import { listItemTypes } from '@shared/DayOne/constants'
import { getReceiverProjectOwner, getOtherTeamMembers } from '@shared/DayOne/utils/mapDayOneData'
import { getValueLabel } from '@helpers/gridUtils'
import { synergyTypes } from '@helpers/constants'
import { isTask } from '@shared/DayOne/utils/getProcessStatus'
import { get as getFp } from 'lodash/fp'
import { ParentMap, getParentNode } from './projectListHierarchy'
import { TFunction } from 'i18next'

export const getRiskProbabilityText = (t: TFunction, value?: number | null, simple = false) => {
  const prefix = simple ? 'RISK_PROBABILITY.SIMPLE' : ('RISK_PROBABILITY' as const)
  if (value === null || value === undefined) return undefined
  if (value <= 10) return t(`${prefix}.VALUE.HIGHLY_UNLIKELY`)
  if (value <= 30) return t(`${prefix}.VALUE.UNLIKELY`)
  if (value <= 60) return t(`${prefix}.VALUE.POSSIBLE`)
  if (value <= 90) return t(`${prefix}.VALUE.LIKELY`)
  return t(`${prefix}.VALUE.VERY_LIKELY`)
}

export const getProjectName =
  ({ parentMap, prefixMirrors }: { parentMap?: ParentMap; prefixMirrors?: boolean }) =>
  ({ data, context }: $TSFixMe) => {
    if (data.type === listItemTypes.KEY_PROCESS) return

    if (context.isAdvancedFinancials && data.isIncoming && isTask(data)) {
      return `${getFp('interdependency.senderTeam.longName', data)} - ${getFp('project.name', data)}`
    }

    const project = data.type === listItemTypes.PROJECT ? data : getParentNode(data, context.parentMap ?? parentMap)

    return project.mirror && prefixMirrors ? `${project.mirror.sendingTeam.name} - ${project.name}` : project.name
  }

export const getKeyProcessName =
  (parentMap?: ParentMap) =>
  ({ data, context }: $TSFixMe) => {
    if (data.type === listItemTypes.KEY_PROCESS) return data.name
    if (data.type === listItemTypes.PROJECT) return getParentNode(data, context.parentMap ?? parentMap)?.name
    if (data.type === listItemTypes.TASK) {
      const parentProject = getParentNode(data, context.parentMap ?? parentMap)
      if (parentProject) {
        return getParentNode(parentProject, context.parentMap ?? parentMap)?.name
      }
    }
  }

export const interdependencyOwnerValueGetter = ({ data }: $TSFixMe) =>
  data.type === 'task' && data.interdependency ? data.owner.displayName : null

export const additionalExportColumns = [
  {
    headerName: 'Description',
    field: 'description',
    hide: true,
  },
  {
    headerName: 'Linked synergy initiative',
    field: 'linkedInitiative',
    valueGetter: ({ data }: $TSFixMe) => data.linkedInitiative?.name,
    hide: true,
  },
  {
    headerName: 'Linked one-time cost',
    field: 'linkedOneTimeCost.name',
    valueGetter: ({ data }: $TSFixMe) => data.linkedOneTimeCost?.name,
    hide: true,
  },
  {
    headerName: 'Predecessor',
    field: 'predecessor.name',
    hide: true,
  },
  {
    headerName: 'Follower',
    field: 'follower.name',
    hide: true,
  },
  {
    headerName: 'Comments',
    field: 'comments',
    hide: true,
  },
  {
    headerName: 'Other team members',
    field: 'otherTeamMembers',
    forceExcelIterate: true,
    hide: true,
    valueGetter: getOtherTeamMembers,
  },
  {
    headerName: 'Interdependency status',
    field: 'interdependency.status',
    hide: true,
  },
  {
    headerName: 'Sending team name',
    field: 'interdependency.senderTeam.longName',
    hide: true,
  },
  {
    headerName: 'Sending owner',
    field: 'owner.displayName',
    valueGetter: interdependencyOwnerValueGetter,
    hide: true,
  },
  {
    headerName: 'Receiver team',
    field: 'interdependency.receiverTeam.longName',
    hide: true,
  },
  {
    headerName: 'Receiver project owner',
    field: 'receiverProjectOwner',
    forceExcelIterate: true,
    valueGetter: getReceiverProjectOwner,
    hide: true,
  },
  {
    headerName: 'Receiver task owner',
    field: 'interdependency.owner.displayName',
    hide: true,
  },
  {
    headerName: 'Receiver task ID',
    field: 'interdependency.projectListId',
    hide: true,
  },
  {
    headerName: 'Shared comments',
    field: 'interdependency.comments',
    hide: true,
  },
]

export const priorityProcessCellCallback = ({ value, context: { priorityOptions } }: $TSFixMe) => {
  if (!value) return null

  return getValueLabel(priorityOptions, value)
}

export const potentialImpactProcessCellCallback = ({ value, context: { potentialImpact } }: $TSFixMe) => {
  if (!value) return null

  return getValueLabel(potentialImpact, value)
}

export const projectCategoryCellCallback = ({ value, context: { categoryOptions } }: $TSFixMe) => {
  if (!value) return null

  return getValueLabel(categoryOptions, value)
}

export const customColumnProcessCellCallback = ({ value, context, column }: $TSFixMe) => {
  const options = get(context, column.colDef.headerName)

  if (!value) return null

  return getValueLabel(options, value)
}

export const getSynergyTypeValue = (value: $TSFixMe, context: $TSFixMe) =>
  value?.synergyType === synergyTypes.NWC ? context.nwcTitle || synergyTypes.NWC : value?.synergyType
