export const updateStateSingleItem =
  (stateKey: string) =>
  (state: $TSFixMe, action: { payload: { id: number; data: { id: number; isNew: boolean } } }) => {
    const { id, data } = action.payload

    return {
      ...state,
      [stateKey]: state[stateKey].map((item: $TSFixMe) => (item.id === id ? { ...item, ...data } : item)),
    }
  }
