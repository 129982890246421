import { put, call } from 'redux-saga/effects'
import * as actions from '@teamWorkspace/actions/actions'
import { getTeamWorkspaceApi } from '@common/net'
import { transformTopicsResponse } from '@helpers/utils'
import { omit, pick } from 'lodash'
import { createSaga } from '@common/sagaCreator/createSaga'

export const getTeamTopics = createSaga(function* getTeamTopics({
  payload,
}: $TSFixMe): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const api = yield getTeamWorkspaceApi()
  const data = yield call(api.request('getTeamTopics', { query: { teamId: payload } }))

  const { teamTopics, imoTopics } = transformTopicsResponse(data)

  yield put(actions.setTeamTopics(teamTopics))
  yield put(actions.setImoTopics(imoTopics))
})

export const createTeamTopic = createSaga(function* createTeamTopic({
  payload,
}: $TSFixMe): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const { teamId, data } = payload
  const api = yield getTeamWorkspaceApi()
  yield call(
    api.request('createTeamTopic', {
      query: { teamId },
      body: omit(data, 'isNew', 'id'),
    }),
  )

  yield put(actions.fetchTeamTopics(teamId))
})

export const updateTeamTopic = createSaga(function* updateTeamTopic({
  payload,
}: $TSFixMe): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const { teamId, data } = payload
  const api = yield getTeamWorkspaceApi()
  yield call(
    api.request('updateTeamTopic', {
      query: { teamId, topicId: data.id },
      body: pick(data, 'topicName', 'requestedBy'),
    }),
  )

  yield put(actions.fetchTeamTopics(teamId))
})

export const deleteTeamTopic = createSaga(function* deleteTeamTopic({
  payload,
}: $TSFixMe): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const { teamId, data } = payload
  const api = yield getTeamWorkspaceApi()
  yield call(api.request('deleteTeamTopic', { query: { teamId, topicId: data.id } }))

  yield put(actions.fetchTeamTopics(teamId))
})
