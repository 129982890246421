import HttpClient from '../HttpClient'
import Config from '@root/src/config'

export default class TeamWorkspaceApi extends HttpClient {
  constructor(fetch: $TSFixMe) {
    super(fetch, '')

    this.host = Config.API_SERVER
  }

  getWhiteboardList = ({ query }: $TSFixMe) => {
    return this.call({
      pathname: `teams/${query.teamId}/whiteboard`,
    })
  }

  createWhiteboard = ({ body, query }: $TSFixMe) => {
    return this.call(
      { pathname: `teams/${query.teamId}/whiteboard` },
      {
        method: 'POST',
        body,
      },
    )
  }

  updateWhiteboard = ({ body, query }: $TSFixMe) => {
    return this.call(
      { pathname: `teams/${query.teamId}/whiteboard/${query.whiteboardId}` },
      {
        method: 'PATCH',
        body,
      },
    )
  }

  deleteWhiteboard = ({ query }: $TSFixMe) => {
    return this.call(
      { pathname: `teams/${query.teamId}/whiteboard/${query.whiteboardId}` },
      {
        method: 'DELETE',
      },
    )
  }

  getTeamActions = ({ query }: $TSFixMe) => {
    return this.call({
      pathname: `teams/${query.teamId}/todo-actions`,
    })
  }

  createTeamAction = ({ body, query }: $TSFixMe) => {
    return this.call(
      { pathname: `teams/${query.teamId}/todo-actions` },
      {
        method: 'POST',
        body,
      },
    )
  }

  updateTeamAction = ({ body, query }: $TSFixMe) => {
    return this.call(
      { pathname: `teams/${query.teamId}/todo-actions/${query.actionId}` },
      {
        method: 'PATCH',
        body,
      },
    )
  }

  deleteTeamAction = ({ query }: $TSFixMe) => {
    return this.call(
      { pathname: `teams/${query.teamId}/todo-actions/${query.actionId}` },
      {
        method: 'DELETE',
      },
    )
  }

  getDecisions = ({ query }: $TSFixMe) => {
    return this.call({
      pathname: `teams/${query.teamId}/decision-logs${query.isFull ? '?isFull=true&sortBy=status,dueDate' : ''}`,
    })
  }

  getDecisionsForAllTeam = () => {
    return this.call({
      pathname: '/team-resources/decision-log',
    })
  }

  updateDecisionsForAllTeam = ({ body, query }: $TSFixMe) => {
    return this.call(
      { pathname: `/team-resources/decision-log/${query.decisionId}` },
      {
        method: 'PATCH',
        body,
      },
    )
  }

  getControlTowerDecision = () => {
    return this.call({
      pathname: '/team-resources/decision-log/v2',
    })
  }

  updateControlTowerDecision = ({ body, query }: $TSFixMe) => {
    return this.call(
      { pathname: `/team-resources/decision-log/v2/${query.decisionId}` },
      {
        method: 'PATCH',
        body,
      },
    )
  }

  createDecision = ({ body, query }: $TSFixMe) => {
    return this.call(
      { pathname: `teams/${query.teamId}/decision-logs` },
      {
        method: 'POST',
        body,
      },
    )
  }

  updateDecision = ({ body, query }: $TSFixMe) => {
    return this.call(
      { pathname: `teams/${query.teamId}/decision-logs/${query.decisionId}` },
      {
        method: 'PATCH',
        body,
      },
    )
  }

  deleteDecision = ({ query }: $TSFixMe) => {
    return this.call(
      { pathname: `teams/${query.teamId}/decision-logs/${query.decisionId}` },
      {
        method: 'DELETE',
      },
    )
  }

  getRisks = ({ query }: $TSFixMe) => {
    return this.call({
      pathname: `teams/${query.teamId}/risk-log${query.isFull ? '?isFull=true&sortBy=status,dueDate' : ''}`,
    })
  }

  createRisk = ({ body, query }: $TSFixMe) => {
    return this.call(
      { pathname: `teams/${query.teamId}/risk-log` },
      {
        method: 'POST',
        body,
      },
    )
  }

  updateRisk = ({ body, query }: $TSFixMe) => {
    return this.call(
      { pathname: `teams/${query.teamId}/risk-log/${query.riskId}` },
      {
        method: 'PATCH',
        body,
      },
    )
  }

  getRisksForAllTeam = () => {
    return this.call({
      pathname: '/team-resources/risk-log',
    })
  }

  updateRisksForAllTeam = ({ body, query }: $TSFixMe) => {
    return this.call(
      { pathname: `/team-resources/risk-log/${query.riskId}` },
      {
        method: 'PUT',
        body,
      },
    )
  }

  deleteRisk = ({ query }: $TSFixMe) => {
    return this.call(
      { pathname: `teams/${query.teamId}/risk-log/${query.riskId}` },
      {
        method: 'DELETE',
      },
    )
  }

  getControlTowerRisk = () => {
    return this.call({
      pathname: '/team-resources/risk-log/v2',
    })
  }

  updateControlTowerRisk = ({ body, query }: $TSFixMe) => {
    return this.call(
      { pathname: `/team-resources/risk-log/v2/${query.riskId}` },
      {
        method: 'PATCH',
        body,
      },
    )
  }

  getCalendar = ({ query }: $TSFixMe) => {
    return this.call({
      pathname: `teams/${query.teamId}/calendar-meetings`,
    })
  }

  createCalendar = ({ body, query }: $TSFixMe) => {
    return this.call(
      { pathname: `teams/${query.teamId}/calendar-meetings` },
      {
        method: 'POST',
        body,
      },
    )
  }

  updateCalendar = ({ body, query }: $TSFixMe) => {
    return this.call(
      { pathname: `teams/${query.teamId}/calendar-meetings/${query.calendarId}` },
      {
        method: 'PATCH',
        body,
      },
    )
  }

  deleteCalendar = ({ query }: $TSFixMe) => {
    return this.call(
      { pathname: `teams/${query.teamId}/calendar-meetings/${query.calendarId}` },
      {
        method: 'DELETE',
      },
    )
  }

  getTeamTopics = ({ query }: $TSFixMe) => {
    return this.call({
      pathname: `teams/${query.teamId}/weekly-meeting-topics`,
    })
  }

  createTeamTopic = ({ body, query }: $TSFixMe) => {
    return this.call(
      { pathname: `teams/${query.teamId}/weekly-meeting-topics` },
      {
        method: 'POST',
        body,
      },
    )
  }

  updateTeamTopic = ({ body, query }: $TSFixMe) => {
    return this.call(
      { pathname: `teams/${query.teamId}/weekly-meeting-topics/${query.topicId}` },
      {
        method: 'PATCH',
        body,
      },
    )
  }

  bulkUpdateTeamTopics = ({ body, query }: $TSFixMe) => {
    const { teamId, requestedBy } = query

    return this.call(
      {
        pathname: `teams/${teamId}/weekly-meeting-topics/bulk`,
        query: { requestedBy },
      },
      {
        method: 'POST',
        body,
      },
    )
  }

  deleteTeamTopic = ({ query }: $TSFixMe) => {
    return this.call(
      {
        pathname: `teams/${query.teamId}/weekly-meeting-topics/${query.topicId}`,
      },
      {
        method: 'DELETE',
      },
    )
  }
}
