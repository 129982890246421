const prefix = '@riskLog/'

export const FETCH_TR_RISK_LOG_DATA = `${prefix}FETCH_TR_RISK_LOG_DATA`
export const UPDATE_TR_RISK_LOG_DATA = `${prefix}UPDATE_TR_RISK_LOG_DATA`
export const FETCH_RISK_LOG_DATA_PENDING = `${prefix}FETCH_RISK_LOG_DATA_PENDING`
export const FETCH_RISK_LOG_DATA_SUCCESS = `${prefix}FETCH_RISK_LOG_DATA_SUCCESS`
export const FETCH_RISK_LOG_DATA_FAIL = `${prefix}FETCH_RISK_LOG_DATA_FAIL`

export const UPDATE_RISK_LOG_DATA_PENDING = `${prefix}UPDATE_RISK_LOG_DATA_PENDING`
export const UPDATE_RISK_LOG_DATA_SUCCESS = `${prefix}UPDATE_RISK_LOG_DATA_SUCCESS`
export const UPDATE_RISK_LOG_DATA_FAIL = `${prefix}UPDATE_RISK_LOG_DATA_FAIL`

export const ADD_RISK_LOG_DATA = `${prefix}ADD_RISK_LOG_DATA`
export const DELETE_RISK_LOG_DATA_PENDING = `${prefix}DELETE_RISK_LOG_DATA_PENDING`
export const DELETE_RISK_LOG_DATA_SUCCESS = `${prefix}DELETE_RISK_LOG_DATA_SUCCESS`
export const DELETE_RISK_LOG_DATA_FAIL = `${prefix}DELETE_RISK_LOG_DATA_FAIL`

export const FETCH_CONTROL_TOWER_RISK_LOG_DATA = `${prefix}FETCH_CONTROL_TOWER_RISK_LOG_DATA`
export const UPDATE_CONTROL_TOWER_RISK_LOG_DATA = `${prefix}UPDATE_CONTROL_TOWER_RISK_LOG_DATA`
export const FETCH_CONTROL_TOWER_RISK_LOG_DATA_SUCCESS = `${prefix}FETCH_CONTROL_TOWER_RISK_LOG_DATA_SUCCESS`

export const UPDATE_CONTROL_TOWER_RISK_LOG_DATA_SUCCESS = `${prefix}UPDATE_CONTROL_TOWER_RISK_LOG_DATA_SUCCESS`
