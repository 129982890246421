import React from 'react'
import { nameManagementType } from '@myImoConfig/containers/ConfigSteps/utils'

export const instructions = (managementType = '') => {
  const process = nameManagementType(managementType)

  return {
    integrationTeamDeliverablesTracker: `Live status dashboard for the Responsible central team and trackers for all ${process} team deliverables and logs`,
    deliverablesOverview: `Track the status of all deliverables across the selected ${process} team`,
    myTeam: 'Home page for all users. Users with multiple teams will see content for their currently selected team',
    controlTower: `Live status dashboards for the ${managementType} team and trackers for all ${managementType} deliverables and logs`,
    communicationsCalendar: 'Review and schedule your communications across all mediums and channels',
    valueCaptureTracker: 'Set up and track the progress of your global Value Capture initiatives',
    orgDesignTracker: 'Set up and track the progress of your Org Design program',
    otherTeamResources: 'Team calendar, useful links and shared documents',
    teamContacts: `Visual representation of our ${process} team architecture, and full contact list of all team members working on the ${process} program, including colleagues that may not be active users of my${managementType}`,
    statusDashboard: `Overall dashboards for all ${process} deliverables`,
    programProjectPlan:
      'Track, manage and filter all projects and tasks across all of the teams and functions that you oversee',
    configStep2:
      'Add deliverables and custom deliverable types by clicking on the add buttons. Click on any deliverable to edit details.',
    configStep3: `Create list of functions, geographies and business units you will use in my${managementType}.`,
    configStep4:
      'Create your team setup and assign appropriate level of visibility. Teams will be able to view all of the content of their assigned respective functions, geographies and business units.',
    configStep5: `Please create the hierarchy for the ${process} team structure.`,
    configStep6:
      'Assign deliverables to teams and override default start and due dates plus toggle active/inactive status for each deliverable.',
    configStep7:
      'Note: user creation emails will not be sent out until Step 9 is completed and the instance is launched. Please ensure all information displayed here is correct before launching the instance.',
    configStep8: 'Toggle global visibility of core components across the application.',
    configStep9:
      'Tailor introductory welcome note and client legal disclaimers. Client users will need to accept these legal disclaimers when they first log into the platform.',
    customDeliverableUploads: (
      <>
        <b>Note</b>
        <br />
        {`Please keep in mind that only the most recently uploaded file will be used for ${managementType} or central team review upon `}
        submission.
      </>
    ),
    masterplanIMO:
      'High level visual representation of significant milestones and events across all workstreams throughout the integration program',
    masterplanSMO:
      'High level visual representation of significant milestones and events across all workstreams throughout the separation program',
    planningAssumptionsReportIMO:
      'Central repository of all the Day 1 and Post Close Planning Assumptions across the program for all teams',
    planningAssumptionsReportSMO:
      'Central repository of all the Day 1 and Post Close Planning Assumptions across the program for all teams',
    scenarioReport: `Central repository of all the Day 1 Risk Scenarios tested across ${process} teams for purposes of checking whether the organization is ready for Day 1`,
    masterCalendarEventTime: 'All times are in EST',
    scopeTeamMembers: `Central repository of all ${process} teams' scope of work and team member structure as defined on each team's Team Charter`,
    interdependenciesSummary: `Central repository and status of all the interdependencies identified by the ${process} teams across the program`,
    decisionLog: 'Central repository of all the open and completed decisions',
    riskLog: 'Central repository of all the open and completed risks',
    trDecisionLog: `Central repository of all the open and completed decisions across the ${process} program for all teams`,
    trRiskLog: `Central repository of all the open and addressed ${process} related risks across the program for all teams`,
    fullProjectPlan: `Full "read-only" project and task plan across the ${process} program for all teams`,
    dataRequestTracker: 'Central repository to manage status of data requests between the two organizations',
    userManagement:
      'Directly create and manage all users of the instance here. Please click the Save button towards the bottom of the page to preserve changes. When changes are saved, any newly created user accounts in the active state will receive a welcome email with a unique activation link that will expire in 7 days.',
    projectMap: (
      <>
        The <b>Project Map</b> provides a list of the function’s {process} projects mapped to synergy initiatives and
        one-time costs, based on linkages set up in the initiative and one-time cost lists respectively. All projects
        are seen, including those not linked to synergy initiatives or one-time costs.
      </>
    ),
  }
}
