import { userRoles, accessRights, teams } from '../constants'
import { routesList } from '../routesList'

export const IMO_GLOBAL_MEMBER_PERMISSIONS = {
  name: teams.IMO_GLOBAL,
  role: userRoles.MEMBER,
  priority: 1,
  teamsPermissions: {
    deliverable: accessRights.READ_WRITE_REVIEW,
  },
  permissions: [
    {
      route: routesList.DASHBOARD.DELIVERABLE.ROOT,
      accessLevel: accessRights.READ_WRITE,
    },
    {
      route: routesList.DASHBOARD.MY_TEAM.ROOT,
      accessLevel: accessRights.READ_WRITE,
    },
    {
      route: routesList.DASHBOARD.MY_TEAM.DECISION_LOG,
      accessLevel: accessRights.READ_WRITE,
    },
    {
      route: routesList.DASHBOARD.MY_TEAM.RISK_LOG,
      accessLevel: accessRights.READ_WRITE,
    },
    {
      route: routesList.DASHBOARD.MY_TEAM.TEAM_HOME,
      accessLevel: accessRights.READ_WRITE,
    },
    {
      route: routesList.DASHBOARD.READ_MORE_PDF,
      accessLevel: accessRights.READ_WRITE,
    },
    {
      route: routesList.DASHBOARD.MY_TEAM.INTEGRATION_TEAM_DELIVERABLE_TRACKER,
      accessLevel: accessRights.READ_WRITE,
    },
    {
      route: routesList.DASHBOARD.MY_TEAM.VALUE_CAPTURE_V2.ROOT,
      accessLevel: accessRights.READ_WRITE,
    },
    {
      route: routesList.DASHBOARD.MY_TEAM.TEAM_HYPERCARE_LOG,
      accessLevel: accessRights.READ_WRITE,
    },
    {
      route: routesList.DASHBOARD.MY_TEAM.TEAM_WORKSPACE,
      accessLevel: accessRights.READ_WRITE,
    },
    {
      route: routesList.DASHBOARD.MY_TEAM.TEAM_PROJECT_ANALYTICS.ROOT,
      accessLevel: accessRights.READ_WRITE,
    },
    {
      route: routesList.DASHBOARD.MY_TEAM.TEAM_STATUS_UPDATE,
      accessLevel: accessRights.READ_WRITE,
    },
    {
      route: routesList.DASHBOARD.PROJECTS_AND_TASKS,
      accessLevel: accessRights.READ_WRITE,
    },
    {
      route: routesList.TEAM_RESOURCES.ROOT,
      accessLevel: accessRights.READ_WRITE,
    },
    {
      route: routesList.COMMUNICATIONS.ROOT,
      accessLevel: accessRights.READ_WRITE,
    },
    {
      route: routesList.IMO_CONTROL_TOWER.ROOT,
      accessLevel: accessRights.READ_WRITE,
    },
    {
      route: routesList.IMO_CONTROL_TOWER.IMO_DELIVERABLE_TRACKER,
      accessLevel: accessRights.READ_WRITE,
    },
    {
      route: routesList.VALUE_CAPTURE_TRACKER_V2.ROOT,
      accessLevel: accessRights.READ_WRITE,
    },
    {
      route: routesList.DASHBOARD.MY_TEAM.KNOWLEDGE_CENTER,
      accessLevel: accessRights.READ_WRITE,
    },
    {
      route: routesList.DASHBOARD.MY_TEAM.TSA_MASTER_LIST,
      accessLevel: accessRights.READ_WRITE,
    },
  ],
}
