import React from 'react'
import UserTooltip from './UserTooltip'
import TutorialTooltip from './TutorialTooltip/TutorialTooltip'
import { ImoIcon } from '@imo/imo-ui-toolkit'

const MENU_ICON_SIZE = 16

const getMainMenu = (managementType: $TSFixMe) => [
  {
    id: 'my-team',
    menuIcon: (color: $TSFixMe) => <ImoIcon icon="my-team" color={color} size={MENU_ICON_SIZE} />,
    tooltipMessage: 'My Team',
    pageName: 'My Team',
    path: '/dashboard',
  },
  {
    id: 'team-resources',
    menuIcon: (color: $TSFixMe) => <ImoIcon icon="team-resources" color={color} size={MENU_ICON_SIZE} />,
    tooltipMessage: 'Team Resources',
    pageName: 'Team Resources',
    path: '/team-resources',
  },
  {
    id: 'communications',
    menuIcon: (color: $TSFixMe) => <ImoIcon icon="communications" color={color} size={MENU_ICON_SIZE + 2} />,
    tooltipMessage: 'Communications Calendar',
    pageName: 'Communications',
    path: '/communications',
  },
  {
    id: 'org-design-tracker',
    menuIcon: (color: $TSFixMe) => <ImoIcon icon="diagram-tree" color={color} size={MENU_ICON_SIZE} />,
    tooltipMessage: 'Org Design Tracker',
    pageName: 'Org Design Tracker',
    path: '/org-design-tracker',
  },
  {
    id: 'value-capture-tracker',
    menuIcon: (color: $TSFixMe) => <ImoIcon icon="value-capture" color={color} size={MENU_ICON_SIZE} />,
    tooltipMessage: 'Value Capture Tracker',
    pageName: 'Value Capture Tracker',
    path: '/value-capture-tracker',
  },
  {
    id: 'imo-control-tower',
    menuIcon: (color: $TSFixMe) => <ImoIcon icon="control-tower" color={color} size={MENU_ICON_SIZE} />,
    tooltipMessage: `${managementType} Control Tower`,
    pageName: 'IMO Control Tower',
    path: '/imo-control-tower',
  },
  {
    id: 'executive-dashboard',
    menuIcon: (color: string) => <ImoIcon icon="playbook" color={color} size={MENU_ICON_SIZE} />,
    tooltipMessage: 'Executive Dashboard',
    pageName: 'Executive Dashboard',
    path: '/executive-dashboard',
  },
]

export const mainMenuData = (managementType: $TSFixMe) => {
  const mainMenu = getMainMenu(managementType)

  return mainMenu
}

export const userMenuData = ({ ...props }) => [
  {
    id: 'user',
    menuIcon: (color: $TSFixMe) => <ImoIcon icon="people" color={color} size={MENU_ICON_SIZE + 2} />,
    tooltipMessage: 'User Management',
    pageName: 'User Management',
    path: '/config/user-management',
  },
  {
    id: 'config',
    menuIcon: (color: $TSFixMe) => <ImoIcon icon="cog" color={color} size={MENU_ICON_SIZE} />,
    tooltipMessage: 'Admin Configuration',
    pageName: 'Admin Config',
    path: '/config',
  },
  {
    id: 'tutorial',
    menuIcon: (color: $TSFixMe) => <ImoIcon icon="help" color={color} size={MENU_ICON_SIZE} />,
    tooltipMessage: <TutorialTooltip managementType={props.managementType} supportEmail={props.supportEmail} />,
    pageName: null,
    hasPopover: true,
    path: '',
    contentStyles: { maxWidth: 'unset' },
    forceClickable: props.isSupportBotEnabled,
  },
  {
    id: 'profile',
    menuIcon: (color: $TSFixMe) => <ImoIcon icon="user" color={color} size={MENU_ICON_SIZE} />,
    tooltipMessage: (
      <UserTooltip
        userInfo={props.userInfo}
        onLogout={props.onLogout}
        managementType={props.managementType}
        onLogDiagnostics={props.onLogDiagnostics}
        isLoggingDiagnostics={props.isLoggingDiagnostics}
      />
    ),
    pageName: null,
    path: '',
    hasPopover: true,
  },
]
