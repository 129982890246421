// Libs
import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'

// Components
import { Intent, Checkbox } from '@blueprintjs/core'
import {
  Container,
  LogoContainer,
  LogoPrefix,
  ImoLogo,
  SmoLogo,
  AreaContainer,
  AreaHeader,
  AcceptButton,
  ActionsContainer,
  StyledTextarea,
  StyledPopup,
} from './TermsAndConditions.styles'

// Selectors
import { setIsFirstTimeUser } from '@generic/actions/actions'
import { getManagementType } from '@domain/instanceConfig/selectors'
import { getConfig } from '@generic/selectors'
import { isSMO } from '@myImoConfig/containers/ConfigSteps/utils'
import { useNavigate } from 'react-router-dom'
import { AccessControl } from '@root/src/views/shared/AccessControl/AccessControl'

const TermsAndConditions = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [acceptEnabled, setAcceptEnabled] = useState(false)

  const { welcomeNote, legalDisclaimer, termsConditions } = useSelector(getConfig)
  const managementType = useSelector(getManagementType)
  const areaHeaderText = `Message from the ${managementType}`
  const managementTypeLogo = isSMO(managementType) ? <SmoLogo /> : <ImoLogo />

  const handleAccept = () => {
    dispatch(setIsFirstTimeUser({ isFirstTimeUser: false, navigate }))
  }

  const handleCheckboxClick = () => {
    setAcceptEnabled(!acceptEnabled)

    setTimeout(() => {
      document.getElementById('accept-button').focus()
    })
  }

  return (
    <Container>
      <StyledPopup disableHeader={true}>
        <LogoContainer>
          <LogoPrefix>Welcome to</LogoPrefix> {managementTypeLogo}
        </LogoContainer>
        <AreaContainer>
          <AreaHeader>{areaHeaderText}</AreaHeader>
          <StyledTextarea style={{ height: '130px' }}>{welcomeNote}</StyledTextarea>
        </AreaContainer>
        <AreaContainer>
          <AreaHeader>Legal Disclaimer</AreaHeader>
          <StyledTextarea style={{ height: '130px' }}>{legalDisclaimer}</StyledTextarea>
        </AreaContainer>
        <AreaContainer>
          <AreaHeader>Terms and Conditions</AreaHeader>
          <StyledTextarea style={{ height: '130px' }}>{termsConditions}</StyledTextarea>
        </AreaContainer>
        <ActionsContainer className="accept-block">
          <Checkbox
            className="checkbox"
            checked={acceptEnabled}
            label="I have read and agree to the Terms and Conditions"
            onChange={handleCheckboxClick}
          />
          <AcceptButton id="accept-button" disabled={!acceptEnabled} intent={Intent.PRIMARY} onClick={handleAccept}>
            Accept
          </AcceptButton>
        </ActionsContainer>
      </StyledPopup>
    </Container>
  )
}

export default TermsAndConditions

export const TermsAndConditionsWithAccessControl = () => <AccessControl component={TermsAndConditions} />
