import React from 'react'
import { getOr, isNil, find, filter, isFunction } from 'lodash/fp'
import { all, call, put, select } from 'redux-saga/effects'
import { getInitiativeListV2Api, getSharedApi } from '@common/net'
import { getVCRevieweeTeamIds } from '@generic/selectors/selectedTeams'
import {
  fetchInitiativeList as fetchInitiativeListAction,
  fetchInitiativeFiltersAndLengthSilent as fetchInitiativeFiltersAndLengthSilentAction,
  fetchAllInitiativeMetadataSilent as fetchAllInitiativeMetadataSilentAction,
  fetchInitiativeFiltersAndLength as fetchInitiativeFiltersAndLengthAction,
  fetchAllInitiativeMetadata as fetchAllInitiativeMetadataAction,
  setInitiativeList,
  addInitiativeToInitiativeList,
  updateInitiativeSuccess,
  updateSeveralInitiatives,
  setInitiativeFiltersAndLength,
  uploadInitiativeAttachmentSuccess,
  deleteInitiativeAttachmentSuccess,
  fetchFinancials,
  fetchStageGateValidationStatus,
  resetInitiativeList,
  saveInitiativeFetchParams,
  deleteInitiativeSuccess,
  setAllInitiativeMetadata,
  fetchInitiativeListWithLoader,
} from '@teamValueCaptureV2/initiativeList/actions/actions'
import {
  getIsAllInitiative,
  getInitiativeList,
  getSelectedInitiativeId,
  getTeamId,
  getStatusLockedTabs,
  getInitiativeColumnsCustomNames,
  getInitiativeFetchParams,
} from '@teamValueCaptureV2/initiativeList/selectors'
import { createSaga } from '@common/sagaCreator/createSaga'
import { isDayOneDeliverableDisabled, transformEmptyStringsToNull } from '@helpers/utils'
import { fetchProjectPlanList } from '@teamHome/actions/actions'
import { getDeliverables } from '@teamDeliverables/selectors'
import { parseOwners } from '@shared/Grid/CellEditors/utils'
import { INFINITE_SCROLL_LIMIT } from '@teamValueCaptureV2/initiativeList/sagas/constants'
import { getAdvancedFinancialsModule } from '@domain/instanceConfig/selectors'
import {
  getCustomColumns,
  getIsDayOneMissed,
  getLocationState,
  getMirroringInitiativesVisibilityStatusForTeam,
} from '@generic/selectors'
import { openNotificationWithIcon } from '@imo/imo-ui-toolkit'
import { getInitiativeForUpdate, refreshPageDialogParams } from './utils'
import { getBodyParams } from '@teamValueCaptureV2/utils'
import * as advancedFinancialsActions from '@teamValueCaptureV2/advancedFinancials/actions/actions'
import { showWarningMessage } from '@generic/actions/actions'
import { actionTypes } from '@common/notifications/utils'
import { dictionariesNames } from '@helpers/constants'
import { setIsRedirect } from '@teamValueCaptureV2/userInitiativeFilter/actions/actions'

export const fetchInitiativeList = createSaga(function* fetchInitiativeList({
  payload,
}: $TSFixMe): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const { teamId, synergyType, linkedInitiativeId, linkedSynergyTypeForOTC } = payload
  const isDayOneMissed = yield select(getIsDayOneMissed)

  if (isDayOneMissed) return

  const api = yield getInitiativeListV2Api()
  const { lockedTabs, synergyInitiatives, initiativeColumnsCustomNames, deliverableInfo } = yield call(
    api.request('getInitiativeList', {
      query: {
        teamId,
        synergyType,
      },
    }),
  )

  yield put(
    setInitiativeList({
      lockedTabs,
      initiativeColumnsCustomNames,
      list: synergyInitiatives,
      linkedInitiativeId,
      linkedSynergyTypeForOTC,
      deliverableInfo,
    }),
  )
})

export const fetchAllInitiativeMetadata = createSaga(function* fetchAllInitiativesSupportData({
  payload,
}: $TSFixMe): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const isDayOneMissed = yield select(getIsDayOneMissed)
  const teamId = yield select(getTeamId)
  const { filters } = yield select(getInitiativeFetchParams)
  // @ts-expect-error fix selector types
  const customColumns = yield select((state) => getCustomColumns(state, dictionariesNames.VALUE_CAPTURE))

  if (isDayOneMissed) return

  if (payload?.isRedirect) return

  const api = yield getInitiativeListV2Api()

  const data = yield call(
    api.request('getInitiativesMetadata', {
      query: {
        teamId,
      },
      body: getBodyParams({ body: { synergyType: payload.synergyType }, filters, customColumns }),
    }),
  )

  yield put(setAllInitiativeMetadata(data))
})

export const fetchInitiativeFiltersAndLength = createSaga(function* fetchInitiativeFiltersAndLength({
  payload,
}: $TSFixMe): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const api = yield getInitiativeListV2Api()
  const teamId = yield select(getTeamId)
  const isDayOneMissed = yield select(getIsDayOneMissed)

  if (isDayOneMissed) return

  const initiativeFiltersAndLength = yield call(
    api.request('getInitiativeFiltersAndCount', {
      query: { teamId },
      body: getBodyParams({ body: { synergyType: payload.synergyType } }),
    }),
  )

  yield put(setInitiativeFiltersAndLength(initiativeFiltersAndLength))
})

export const fetchInfiniteInitiativeList = createSaga(function* fetchInfiniteInitiativeList({
  payload,
}: $TSFixMe): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const {
    teamId,
    synergyType,
    linkedInitiativeId,
    linkedSynergyTypeForOTC,
    offset = 0,
    withClearing,
    sorting,
    filters,
    isRedirect,
  } = payload

  const isDayOneMissed = yield select(getIsDayOneMissed)
  // @ts-expect-error fix selector types
  const customColumns = yield select((state) => getCustomColumns(state, dictionariesNames.VALUE_CAPTURE))
  const initiallyLockedTabs = yield select(getStatusLockedTabs)
  const initiallyInitiativeColumnsCustomNames = yield select(getInitiativeColumnsCustomNames)
  const initiativeFetchParams = yield select(getInitiativeFetchParams)
  const locationState = yield select(getLocationState)

  if (withClearing) {
    yield put(resetInitiativeList())
  }

  if (isDayOneMissed) return

  let dataFromServer = {
    lockedTabs: initiallyLockedTabs,
    initiativeColumnsCustomNames: initiallyInitiativeColumnsCustomNames,
    synergyInitiatives: [],
    deliverableInfo: {},
  }

  if (!isRedirect) {
    const api = yield getInitiativeListV2Api()
    const responseSort = sorting || initiativeFetchParams.sorting
    const responseFilter = filters || initiativeFetchParams.filters

    const body = {
      synergyType,
      limit: INFINITE_SCROLL_LIMIT,
      offset,
    }

    dataFromServer = yield call(
      api.request('getInfinityInitiativeList', {
        query: {
          teamId,
        },
        body: getBodyParams({ body, sorting: responseSort, filters: responseFilter, customColumns }),
      }),
    )

    yield put(
      saveInitiativeFetchParams({
        teamId,
        synergyType,
        linkedInitiativeId,
        linkedSynergyTypeForOTC,
        offset,
        withClearing,
        sorting: responseSort,
        filters: responseFilter,
      }),
    )
  } else {
    const advancedFinancialsModule = yield select(getAdvancedFinancialsModule)

    if (advancedFinancialsModule.active) return

    yield put(setIsRedirect(false))

    return
  }

  const { lockedTabs, synergyInitiatives, initiativeColumnsCustomNames, deliverableInfo } = dataFromServer

  yield put(
    addInitiativeToInitiativeList({
      locationState,
      lockedTabs,
      initiativeColumnsCustomNames,
      list: synergyInitiatives,
      linkedInitiativeId,
      linkedSynergyTypeForOTC,
      deliverableInfo,
    }),
  )
})

export const updateSelectedAndMirroredInitiatives = createSaga(function* updateSelectedAndMirroredInitiatives({
  payload,
}: {
  payload?: { initiativeId?: number }
}): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const selectedInitiativeId = yield select(getSelectedInitiativeId)
  const initiativeList = yield select(getInitiativeList)
  const isMirroringInitiativesInactive = yield select(getMirroringInitiativesVisibilityStatusForTeam)
  const revieweeTeamIds = yield select(getVCRevieweeTeamIds)
  const id = getOr(selectedInitiativeId, 'initiativeId', payload)
  const selectedInitiative = find({ id }, initiativeList)

  const api = yield getInitiativeListV2Api()
  const initiative = yield call(
    api.request('getInitiative', {
      query: {
        teamId: selectedInitiative.team.id,
        initiativeId: selectedInitiative.id,
        synergyType: selectedInitiative.synergyType,
      },
    }),
  )

  const callaArray = (initiative.mirroredInitiatives || []).map((initiative: $TSFixMe) => {
    return call(
      api.request('getInitiative', {
        query: {
          teamId: initiative.teamId,
          initiativeId: initiative.initiativeId,
          synergyType: selectedInitiative.synergyType,
        },
      }),
    )
  })

  const previousInitiatives = selectedInitiative.mirroredInitiatives || []
  let newInitiatives = yield all(callaArray)
  // not all mirrored initiatives should be shown after auto-mirroring (https://mckinsey.atlassian.net/browse/IMO-9386)
  newInitiatives = filter((initiative) => revieweeTeamIds.includes(initiative.team.id), newInitiatives)
  previousInitiatives.push({ initiativeId: initiative.id })
  newInitiatives.push({ ...initiative, ...(selectedInitiative.added ? { added: selectedInitiative.added } : {}) })

  yield put(fetchInitiativeFiltersAndLengthSilentAction())
  yield put(fetchAllInitiativeMetadataSilentAction())

  yield put(
    updateSeveralInitiatives({
      previousInitiatives,
      newInitiatives,
      isMirroringInitiativesInactive,
    }),
  )
})

export const fetchInitiative = createSaga(function* fetchInitiative({
  payload,
}: $TSFixMe): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const { teamId, initiativeId, synergyType, withoutUpdateSelectedInitiativeId } = payload
  const isAllInitiative = yield select(getIsAllInitiative)

  const api = yield getInitiativeListV2Api()
  const initiative = yield call(
    api.request('getInitiative', {
      query: {
        teamId,
        initiativeId,
        synergyType,
      },
    }),
  )

  yield put(
    updateInitiativeSuccess({
      id: initiativeId,
      data: initiative,
      teamId,
      isAllInitiative,
      withoutUpdateSelectedInitiativeId,
    }),
  )
})

export const fetchInitiativeAndUpdateList = createSaga(function* fetchInitiativeOrUpdateList({
  payload,
}: $TSFixMe): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const { teamId, initiativeId, synergyType, initiativeTeamId, withoutUpdateSelectedInitiativeId } = payload
  const api = yield getInitiativeListV2Api()

  yield call(fetchInitiative, {
    payload: {
      teamId: initiativeTeamId,
      initiativeId,
      synergyType,
      withoutUpdateSelectedInitiativeId,
    },
  })

  if (!teamId) return

  const { lockedTabs, synergyInitiatives, initiativeColumnsCustomNames, deliverableInfo } = yield call(
    api.request('getInitiativeList', { query: { teamId, synergyType } }),
  )

  yield put(setInitiativeList({ lockedTabs, initiativeColumnsCustomNames, list: synergyInitiatives, deliverableInfo }))
})

export const createInitiative = createSaga(function* createInitiative({
  payload,
}: $TSFixMe): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const { teamId, synergyType, linkedInitiativeId, data, disableLoadingOverlay } = payload
  const isAllInitiative = yield select(getIsAllInitiative)
  const body = getInitiativeForUpdate(data, synergyType)

  const linkedSynergyInitiativeId = getOr(linkedInitiativeId, 'linkedSynergyInitiative', data)
  if (linkedSynergyInitiativeId) body.linkedSynergyInitiativeId = linkedSynergyInitiativeId

  const api = yield getInitiativeListV2Api()

  const { id, initiativeListId } = yield call(
    api.request('createInitiative', {
      query: {
        teamId,
        synergyType,
      },
      body,
    }),
  )

  yield put(
    updateInitiativeSuccess({
      id: data.id,
      data: { ...data, id, initiativeListId, isNew: false },
      isAllInitiative,
    }),
  )

  yield disableLoadingOverlay()

  yield call(fetchInitiative, {
    payload: {
      teamId,
      synergyType,
      initiativeId: id,
    },
  })
})

export const updateCustomColumn = createSaga(function* updateCustomColumn(
  payload: $TSFixMe,
): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const { customColumn, data, teamId, synergyType, initiativeId, netImpactItemId, refetchAF, groupId } = payload
  const column = data.customColumns.find((column: $TSFixMe) => column.columnName === customColumn)
  const isAllInitiative = yield select(getIsAllInitiative)

  const body = {
    entityId: netImpactItemId || data.id,
    type: netImpactItemId ? 'netImpactItem' : 'valueCaptureInitiativeV2',
    optionValue: !isNil(column?.option?.id)
      ? getOr(null, ['option', 'id'], column)
      : getOr(null, ['option', 'name'], column),
  }

  const api = yield getSharedApi()

  try {
    yield call(
      api.request('updateCustomColumn', {
        query: {
          columnId: column.id,
        },
        body: groupId ? { ...body, groupId } : body,
      }),
    )

    if (isAllInitiative) {
      // @ts-expect-error fix generator types
      yield call(updateSelectedAndMirroredInitiatives)
    } else {
      yield call(fetchInitiative, {
        payload: {
          teamId,
          synergyType,
          initiativeId,
        },
      })
    }

    if (refetchAF) {
      yield put(advancedFinancialsActions.fetchAdvancedFinancials({ teamId, initiativeId }))
    }
  } catch (e) {
    const { status, message } = e as $TSFixMe
    if (status === 403) {
      yield put(
        showWarningMessage({
          actionType: actionTypes.VALIDATION_FAIL,
          errorMessage: (
            <>
              <b>{message}</b> team is restricted for initiative mirroring
            </>
          ),
        }),
      )
    }
  }
})

export function* updateInitiative({ payload }: $TSFixMe): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const { teamId, initiativeId, synergyType, data, financialData, customColumn, refetchAF, isAllInitiative, field } =
    payload

  const transformedData = {
    ...transformEmptyStringsToNull(data),
    owners: data.owners ? parseOwners(data.owners) : data.owners,
  }

  if (customColumn) {
    return yield call(updateCustomColumn, payload)
  }

  const api = yield getInitiativeListV2Api()

  try {
    const body = getInitiativeForUpdate(transformedData, synergyType)

    // optimistic state change on "confidential" change
    if (field === 'confidential') {
      const initiativeList = yield select(getInitiativeList)
      const oldInitiative = find({ id: initiativeId }, initiativeList)

      yield put(
        updateInitiativeSuccess({
          id: initiativeId,
          data: { ...oldInitiative, confidential: body.confidential },
          teamId,
          isAllInitiative,
        }),
      )
    }

    if (field === 'comments') delete body.description

    if (field === 'description') delete body.comments

    if (field !== 'oneTimeBenefit') delete body.oneTimeBenefit

    const updatedRow = yield call(
      api.request('updateInitiative', {
        query: { teamId, initiativeId },
        body,
      }),
    )

    const initiativeList = yield select(getInitiativeList)
    const oldInitiative = find({ id: initiativeId }, initiativeList)
    const isOldStatusOnHold = oldInitiative.status === null

    if (isOldStatusOnHold && oldInitiative.status !== updatedRow.status) {
      yield put(
        updateInitiativeSuccess({
          id: initiativeId,
          data: { ...oldInitiative, status: updatedRow.status },
          teamId,
          isAllInitiative,
        }),
      )
    }

    const deliverablesList = yield select(getDeliverables)

    if (!isDayOneDeliverableDisabled(deliverablesList, teamId)) {
      yield put(fetchProjectPlanList({ teamId, withoutInterdependencies: true }))
    }

    if (financialData?.subtype1 && financialData?.subtype2 && financialData?.subtype3)
      yield put(fetchFinancials(financialData))

    yield put(
      fetchStageGateValidationStatus({
        initiativeId,
        teamId,
      }),
    )

    if (refetchAF) {
      yield put(advancedFinancialsActions.fetchAdvancedFinancials({ teamId, initiativeId }))
    }
  } catch (e) {
    const { status } = e as $TSFixMe
    if (status === 400) {
      yield call(fetchInitiative, {
        payload: {
          teamId,
          synergyType,
          initiativeId,
        },
      })
    }

    throw e
  }
}

export function* preUpdateInitiative(action: $TSFixMe): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const { teamId, initiativeTeamId, initiativeId, synergyType, shouldShowLoader, field, linkedInitiativeId, callback } =
    action.payload

  const isAllInitiative = yield select(getIsAllInitiative)

  try {
    const onFinally = () => {
      if (isAllInitiative) {
        return call(updateSelectedAndMirroredInitiatives, { payload: { initiativeId } })
      } else {
        /* change of initiative can be in case of choosing another row, in this case "selectedInitiativeId" in
         the store should not change after fetch. For this added "withoutUpdateSelectedInitiativeId" */
        return call(fetchInitiativeAndUpdateList, {
          payload: {
            teamId,
            initiativeTeamId,
            synergyType,
            initiativeId,
            withoutUpdateSelectedInitiativeId: true,
          },
        })
      }
    }

    const updateInitiativeSaga = createSaga(updateInitiative, shouldShowLoader, null, null, onFinally)

    yield call(updateInitiativeSaga, {
      ...action,
      payload: {
        ...action.payload,
        teamId: initiativeTeamId,
        isAllInitiative,
      },
    })

    if (!isAllInitiative && field === 'confidential') {
      yield put(fetchInitiativeListWithLoader({ teamId: initiativeTeamId, synergyType, linkedInitiativeId }))
    }
  } catch (e) {
    const { status } = e as $TSFixMe
    if (status === 404) {
      openNotificationWithIcon(refreshPageDialogParams)
    }
  }

  if (isFunction(callback)) callback()
}

export const deleteInitiative = createSaga(function* deleteInitiative({
  payload,
}: $TSFixMe): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const { teamId, initiativeId, synergyType, isAllInitiatives } = payload

  if (Number.isNaN(Number(initiativeId))) {
    return
  }

  const api = yield getInitiativeListV2Api()
  yield call(
    api.request('deleteInitiative', {
      query: {
        teamId,
        initiativeId,
        synergyType,
      },
    }),
  )

  if (isAllInitiatives) {
    yield put(deleteInitiativeSuccess({ id: initiativeId }))
    yield put(fetchInitiativeFiltersAndLengthAction())
    yield put(fetchAllInitiativeMetadataAction())
  } else {
    yield put(fetchInitiativeListAction({ teamId, synergyType }))
  }
})

export const deleteInitiativeAttachment = createSaga(function* deleteInitiativeAttachment({
  payload,
}: $TSFixMe): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const { initiativeId, fileId, teamId, isAllInitiatives } = payload

  const api = yield getInitiativeListV2Api()
  yield call(api.request('deleteInitiativeAttachment', { query: { initiativeId, fileId, teamId } }))

  if (isAllInitiatives) {
    yield put(fetchAllInitiativeMetadataAction())
  }

  return yield put(deleteInitiativeAttachmentSuccess({ initiativeId, fileId }))
},
true)

export const uploadInitiativeAttachments = createSaga(function* uploadInitiativeAttachments(
  action: $TSFixMe,
): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const { teamId, file, initiativeId } = action.payload
  const formData = new FormData()
  formData.append('attachment', file)

  const api = yield getInitiativeListV2Api()
  const { id, key } = yield call(
    api.request('uploadInitiativeAttachments', {
      query: {
        teamId,
        initiativeId,
      },
      body: formData,
    }),
  )

  yield put(uploadInitiativeAttachmentSuccess({ attachment: { key, id } }))
},
true)
