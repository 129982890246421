import cn from 'classnames'
import { capitalize, noop } from 'lodash/fp'
import { validateDueDate, dueDateError } from '@helpers/schemas/utils'
import { CUSTOM_DATE_FORMAT } from '@shared/Grid/utils/excelStyles'
import { agRichKeyCreator, agSelectFilterValueFormatter, getParamsForAgRichSelect } from '@helpers/gridUtils'
import {
  dateComparator,
  datesComparator,
  formatDate,
  getMinDate,
  getMaxDate,
  getMaxDateWithThreshold,
  lastYear,
} from '@shared/Grid/utils/dates'
import { statusComparator } from '@shared/Grid/utils/statusCellUtils'
import {
  categoryTextMatcher,
  confidentialTextMatcher,
  priorityTextMatcher,
} from '@shared/Grid/utils/filterTextMatchers'
import {
  statusValueGetter,
  statusFilterValueGetter,
  statusTooltipValueGetter,
  tooltipTheme,
  StatusCellEditor,
  StatusCellRenderer,
  DateCellEditor,
  InputCellEditor,
} from '@imo/imo-ui-toolkit'
import { processItemPatterns, listItemTypes } from '../constants'
import { basicStatusList } from '@helpers/statuses'
import { booleanYesNoOptions, COLUMN_TYPE } from '@shared/Grid/constants'
import {
  isEditableField,
  listIdComparator,
  getCategoryColumn,
  doesStatusFilterPass,
  isEditableDueDate,
} from '@shared/DayOne/utils/getColumnDefs'
import { stringComparator } from '@helpers/utilsComparators'

import {
  listIdValueSetter,
  valueGetterWithInterdependency,
  getReceiverProjectOwner,
  getOtherTeamMembers,
} from '@shared/DayOne/utils/mapDayOneData'
import { processItemFields } from '@common/accessController/strategies/deliverables/constants'
import { getCustomColumnsColumnDefs } from '@shared/Grid/customColumnsColDef'
import {
  customColumnProcessCellCallback,
  getKeyProcessName,
  getProjectName,
  interdependencyOwnerValueGetter,
} from '@shared/DayOne/utils/exportUtils'
import { getSpecialAttributesColumn } from '@shared/DayOne/utils/specialAttributesColumn'
import { createOwnerColumnDef } from '@shared/DayOne/utils/owner-column-def'
import HierarchyFilter, { HierarchyFloatingFilter } from '@shared/Grid/HierarchyFilter/HierarchyFilter'
import { TFunction } from 'i18next'

const defaultColumnProps = {
  suppressSizeToFit: true,
}

const keyProcessProjectTaskColumns = [
  {
    headerName: 'Key process',
    field: 'keyProcess',
    valueGetter: getKeyProcessName(),
    hide: true,
    forceExcelIterate: true,
  },
  {
    headerName: 'Project',
    field: 'project',
    valueGetter: getProjectName({}),
    hide: true,
    forceExcelIterate: true,
  },
  {
    headerName: 'Task',
    field: 'task',
    forceExcelIterate: true,
    valueGetter: ({ data }: $TSFixMe) => (data.type === listItemTypes.TASK ? data.name : ''),
    hide: true,
  },
]

// TODO: fix duplication -> src/views/shared/DayOne/utils/exportUtils.js
export const additionalExportColumns = [
  {
    headerName: 'Description',
    field: 'description',
    hide: true,
  },
  {
    headerName: 'Linked synergy initiative',
    field: 'linkedInitiative',
    valueGetter: ({ data }: $TSFixMe) => data.linkedInitiative?.name,
    hide: true,
  },
  {
    headerName: 'Linked one-time cost',
    field: 'linkedOneTimeCost.name',
    valueGetter: ({ data }: $TSFixMe) => data.linkedOneTimeCost?.name,
    hide: true,
  },
  {
    headerName: 'Predecessor',
    field: 'predecessor.name',
    hide: true,
  },
  {
    headerName: 'Follower',
    field: 'follower.name',
    hide: true,
  },
  {
    headerName: 'Comments',
    field: 'comments',
    hide: true,
  },
  {
    headerName: 'Other team members',
    field: 'otherTeamMembers',
    forceExcelIterate: true,
    hide: true,
    valueGetter: getOtherTeamMembers,
  },
  {
    headerName: 'Interdependency status',
    field: 'interdependency.status',
    hide: true,
  },
  {
    headerName: 'Sending team name',
    field: 'interdependency.senderTeam.longName',
    hide: true,
  },
  {
    headerName: 'Sending owner',
    field: 'owner.displayName',
    valueGetter: interdependencyOwnerValueGetter,
    hide: true,
  },
  {
    headerName: 'Receiver team',
    field: 'interdependency.receiverTeam.longName',
    hide: true,
  },
  {
    headerName: 'Receiver project owner',
    field: 'receiverProjectOwner',
    forceExcelIterate: true,
    valueGetter: getReceiverProjectOwner,
    hide: true,
  },
  {
    headerName: 'Receiver task owner',
    field: 'interdependency.owner.displayName',
    hide: true,
  },
  {
    headerName: 'Receiver task ID',
    field: 'interdependency.projectListId',
    hide: true,
  },
  {
    headerName: 'Shared comments',
    field: 'interdependency.comments',
    hide: true,
  },
]

interface GetProjectPlanColumnDefsArgs {
  data: $TSFixMe
  specialAttributesColumnWidth: number
  managementType: $TSFixMe
  customColumns: $TSFixMe
  isAdvancedFinancials: boolean
  showLinkedTSA: boolean
  shouldDisplayProjectMirror: boolean
  isEntityUniqueIdEnabled: boolean
  t: TFunction
}

export const getProjectPlanColumnDefs = ({
  specialAttributesColumnWidth,
  managementType,
  customColumns,
  isAdvancedFinancials,
  showLinkedTSA,
  shouldDisplayProjectMirror,
  isEntityUniqueIdEnabled,
  t,
}: GetProjectPlanColumnDefsArgs) => {
  return [
    {
      headerName: `Primary ${managementType}`,
      field: 'primaryIMO',
      editable: false,
      pinned: 'left',
      width: 110,
      filter: 'agSetColumnFilter',
      comparator: stringComparator,
      filterParams: {
        debounceMs: 700,
        caseSensitive: false,
        suppressAndOrCondition: true,
        comparator: stringComparator,
      },
      ...defaultColumnProps,
    },
    {
      headerName: 'Team',
      field: 'longTeamName',
      comparator: stringComparator,
      filterParams: {
        debounceMs: 700,
        caseSensitive: false,
        suppressAndOrCondition: true,
        comparator: stringComparator,
      },
      width: 110,
      editable: false,
      filter: 'agSetColumnFilter',
      pinned: 'left',
      ...defaultColumnProps,
    },
    ...keyProcessProjectTaskColumns,
    {
      headerName: 'ID',
      field: 'projectListId',
      width: 60,
      cellClass: 'indexCell',
      editable: isEditableField,
      cellEditor: InputCellEditor,
      cellEditorParams: ({ data }: $TSFixMe) => ({
        pattern: processItemPatterns[data.type],
      }),
      valueSetter: listIdValueSetter,
      valueGetter: valueGetterWithInterdependency('projectListId'),
      comparator: listIdComparator,
      filter: 'agSetColumnFilter',
      ...defaultColumnProps,
    },
    getSpecialAttributesColumn({ showLinkedTSA, specialAttributesColumnWidth, shouldDisplayProjectMirror, t }),
    createOwnerColumnDef({ isEditableField }),
    {
      headerName: 'Status',
      cellRenderer: StatusCellRenderer,
      // suppressSizeToFit: true,
      width: 70,
      cellEditor: 'agRichSelectCellEditor',
      cellEditorPopup: true,
      field: 'status',
      editable: isEditableField,
      valueGetter: statusValueGetter,
      tooltipValueGetter: statusTooltipValueGetter,
      filterValueGetter: statusFilterValueGetter,
      comparator: (value1: $TSFixMe, value2: $TSFixMe) => statusComparator(value1?.label, value2?.label),
      cellEditorParams: {
        values: basicStatusList,
        cellRenderer: StatusCellEditor,
      },
      filter: 'agMultiColumnFilter',
      filterParams: {
        filters: [
          {
            filter: 'agTextColumnFilter',
          },
          {
            filter: HierarchyFilter,
            floatingFilterComponent: HierarchyFloatingFilter,
            filterParams: {
              debounceMs: 0,
              suppressAndOrCondition: true,
              model: [
                { field: 'type', values: ['project', 'task'], renderer: ({ value }: $TSFixMe) => capitalize(value) },
                {
                  field: 'status',
                  renderer: StatusCellEditor,
                  comparator: statusComparator,
                },
              ],
              doesFilterPass: doesStatusFilterPass,
            },
          },
        ],
      },
      ...defaultColumnProps,
    },
    {
      headerName: 'Priority',
      field: 'priority',
      tooltipField: 'priority',
      cellEditor: 'agRichSelectCellEditor',
      editable: isEditableField,
      filter: 'agMultiColumnFilter',
      keyCreator: agRichKeyCreator,
      filterParams: {
        debounceMs: 0,
        suppressAndOrCondition: true,
        filters: [
          {
            filter: 'agTextColumnFilter',
            filterParams: {
              textMatcher: priorityTextMatcher,
            },
          },
          {
            filter: 'agSetColumnFilter',
          },
        ],
      },
      ...getParamsForAgRichSelect(({ context }: $TSFixMe) => context.priorityOptions),
      tooltipValueGetter: noop,
      ...defaultColumnProps,
    },
    {
      headerName: 'Start date',
      field: processItemFields.START_DATE,
      type: COLUMN_TYPE.generalDate,
      editable: isEditableField,
      cellClass: `day1-date-cell ${CUSTOM_DATE_FORMAT}`,
      cellEditorParams: ({ context }: $TSFixMe) => ({
        getMaxDate: (data: $TSFixMe) => getMaxDate(data[processItemFields.DUE_DATE], getMaxDateWithThreshold()),
        getMinDate: () => getMinDate(null, context.tenantCreationDate ? lastYear(context.tenantCreationDate) : null),
        canClearSelection: true,
      }),
      cellEditorPopup: true,
    },
    {
      headerName: 'Due date',
      field: 'dueDate',
      width: 100,
      editable: isEditableDueDate,
      cellEditor: DateCellEditor,
      cellEditorPopup: true,
      cellClass: (data: $TSFixMe) =>
        cn('day1-date-cell', CUSTOM_DATE_FORMAT, { 'invalid-data': isAdvancedFinancials && !validateDueDate(data) }),
      tooltipComponentParams: { theme: tooltipTheme.ERROR },
      valueFormatter: formatDate,
      comparator: dateComparator,
      filter: 'agDateColumnFilter',
      filterParams: {
        comparator: datesComparator,
        browserDatePicker: true,
        suppressAndOrCondition: true,
        inRangeInclusive: true,
      },
      cellEditorParams: ({ context }: $TSFixMe) => ({
        getMinDate: (data: $TSFixMe) =>
          getMinDate(
            null,
            data[processItemFields.START_DATE] ||
              (context.tenantCreationDate ? lastYear(context.tenantCreationDate) : null),
          ),
        canClearSelection: true,
      }),
      tooltipValueGetter: (data: $TSFixMe) =>
        isAdvancedFinancials && !validateDueDate(data)
          ? dueDateError({ dataDueDate: data.data.linkedInitiative.plannedL4Date })
          : null,
      ...defaultColumnProps,
    },
    {
      headerName: 'Forecast due date',
      field: 'forecastDate',
      width: 120,
      cellEditor: DateCellEditor,
      cellEditorPopup: true,
      editable: isEditableField,
      valueFormatter: formatDate,
      comparator: dateComparator,
      filter: 'agDateColumnFilter',
      cellClass: CUSTOM_DATE_FORMAT,
      filterParams: {
        comparator: datesComparator,
        browserDatePicker: true,
        inRangeInclusive: true,
        suppressAndOrCondition: true,
      },
      cellEditorParams: ({ context }: $TSFixMe) => ({
        canClearSelection: true,
        getMinDate: () => getMinDate(null, context.tenantCreationDate ? lastYear(context.tenantCreationDate) : null),
      }),
      ...defaultColumnProps,
    },
    {
      headerName: 'Confidential',
      field: 'confidential',
      width: 90,
      editable: isEditableField,
      cellEditor: 'agRichSelectCellEditor',
      filter: 'agMultiColumnFilter',
      filterParams: {
        debounceMs: 0,
        suppressAndOrCondition: true,
        filters: [
          {
            filter: 'agTextColumnFilter',
            filterParams: {
              textMatcher: confidentialTextMatcher,
            },
          },
          {
            filter: 'agSetColumnFilter',
          },
        ],
      },
      headerClass: 'confidential',
      ...getParamsForAgRichSelect(booleanYesNoOptions),
      tooltipValueGetter: noop,
      ...defaultColumnProps,
      keyCreator: agSelectFilterValueFormatter(booleanYesNoOptions),
    },
    ...(isEntityUniqueIdEnabled
      ? [
          {
            headerName: 'Unique ID',
            field: processItemFields.UNIQUE_ID,
            suppressSizeToFit: true,
            width: 90,
            editable: false,
            filters: [
              {
                filter: 'agTextColumnFilter',
              },
              {
                filter: 'agSetColumnFilter',
              },
            ],
          },
        ]
      : []),
    getCategoryColumn({
      editable: isEditableField,
      filter: 'agMultiColumnFilter',
      filterParams: {
        debounceMs: 0,
        suppressAndOrCondition: true,
        filters: [
          {
            filter: 'agTextColumnFilter',
            filterParams: {
              textMatcher: categoryTextMatcher,
            },
          },
          {
            filter: 'agSetColumnFilter',
          },
        ],
      },
    }),
    ...getCustomColumnsColumnDefs(customColumns, {
      editable: isEditableField,
      processCellCallback: customColumnProcessCellCallback,
      width: 100,
      minWidth: 100,
      flex: 1,
    }),
    ...additionalExportColumns,
  ]
}
