import { synergyTypes } from '@helpers/constants'

export const MONTHS_CONSTANTS = {
  M1: 'M1',
  M2: 'M2',
  M3: 'M3',
  M4: 'M4',
  M5: 'M5',
  M6: 'M6',
  M7: 'M7',
  M8: 'M8',
  M9: 'M9',
  M10: 'M10',
  M11: 'M11',
  M12: 'M12',
}

export const FINANCIALS_FIELD = {
  ...MONTHS_CONSTANTS,
  INITIATIVE_ID: 'initiativeId',
  SUBTYPE1: 'subtype1',
  SUBTYPE2: 'subtype2',
  SUBTYPE3: 'subtype3',
  SYNERGY_TYPE: 'synergyType',
  TOTAL: 'total',
  YEAR: 'year',
  ADDITIONAL: 'additional',
  DATA_UPDATE: 'financialDataUpdate',
  MONTH_EXPLANATION: 'monthExplanation',
}

export const MONTH_NAMES = {
  [FINANCIALS_FIELD.M1]: 'Jan',
  [FINANCIALS_FIELD.M2]: 'Feb',
  [FINANCIALS_FIELD.M3]: 'Mar',
  [FINANCIALS_FIELD.M4]: 'Apr',
  [FINANCIALS_FIELD.M5]: 'May',
  [FINANCIALS_FIELD.M6]: 'Jun',
  [FINANCIALS_FIELD.M7]: 'Jul',
  [FINANCIALS_FIELD.M8]: 'Aug',
  [FINANCIALS_FIELD.M9]: 'Sep',
  [FINANCIALS_FIELD.M10]: 'Oct',
  [FINANCIALS_FIELD.M11]: 'Nov',
  [FINANCIALS_FIELD.M12]: 'Dec',
}

export const FINANCIAL_MONTH_NAMES = {
  [FINANCIALS_FIELD.TOTAL]: 'Total',
  ...MONTH_NAMES,
}

export const FINANCIALS_FILTER = {
  SYNERGY_RUN_RATE: 'SynergyRunRate',
  SYNERGY_IN_PERIOD: 'SynergyInPeriod',
  CURRENCY: 'Currency',
  FTEs: 'FTEs',
  PLANNED: 'Planned',
  FORECAST_ACTUALS: 'ForecastActuals',
  REVENUE: synergyTypes.REVENUE,
  MARGIN: 'Margin',
  IMPLEMENTATION_COST: 'ImplementationCost',
  COST: synergyTypes.COST,
  NWC: synergyTypes.NWC,
}

export const COLUMN_YEAR_COUNTER = 5

export const SYNERGY_IN_PERIOD = {
  key: '1',
  text: 'Synergy in-period',
  value: FINANCIALS_FILTER.SYNERGY_IN_PERIOD,
  icon: 'layout-circle',
  disabled: false,
}

export const SYNERGY_RUN_RATE = {
  key: '2',
  text: 'Synergy run-rate',
  value: FINANCIALS_FILTER.SYNERGY_RUN_RATE,
  icon: 'social-media',
  disabled: false,
}

export const CURRENCY_ITEM = {
  text: 'Currency',
  value: FINANCIALS_FILTER.CURRENCY,
  icon: 'dollar',
  disabled: false,
}

export const FTE_ITEM = {
  text: 'FTEs',
  value: FINANCIALS_FILTER.FTEs,
  icon: 'people',
  disabled: false,
}

export const PLANNED_ITEM = {
  text: 'Planned',
  value: FINANCIALS_FILTER.PLANNED,
  icon: 'form',
  disabled: false,
}

export const FORECAST_ACTUALS = {
  text: 'Forecast/actuals',
  value: FINANCIALS_FILTER.FORECAST_ACTUALS,
  icon: 'waterfall-chart',
  disabled: false,
}

export const REVENUE_ITEM = {
  text: 'Revenue',
  value: FINANCIALS_FILTER.REVENUE,
  icon: 'bank-account',
  disabled: false,
}

export const MARGIN_ITEM = {
  text: 'Margin',
  value: FINANCIALS_FILTER.MARGIN,
  icon: 'grouped-bar-chart',
  disabled: false,
}

export const IMPLEMENTATION_COST = {
  text: 'Implementation cost',
  value: FINANCIALS_FILTER.IMPLEMENTATION_COST,
  icon: 'build',
  disabled: false,
}

export const REVENUE_LIST = [REVENUE_ITEM, MARGIN_ITEM]
export const SYNERGY_LIST = [SYNERGY_IN_PERIOD, SYNERGY_RUN_RATE]
export const CURRENCY_LIST = [CURRENCY_ITEM, FTE_ITEM]
export const PLANNED_LIST = [PLANNED_ITEM, FORECAST_ACTUALS]

export const SYNERGY_TITLE = {
  IN_PERIOD: 'in-period',
  RUN_RATE: 'run-rate',
}

export const MAX_CELL_VALUE = 1000000
export const MIN_CELL_VALUE = -1000000

export const MAX_CELL_NUMBER_VALUE = 100000000000
export const MIN_CELL_NUMBER_VALUE = -100000000000

export const CONST_FINANCIALS_COMBINATIONS = {
  [synergyTypes.COST]: {
    costImpact: {
      subtype1: SYNERGY_RUN_RATE,
      subtype2: CURRENCY_ITEM,
      subtype3: PLANNED_ITEM,
    },
    FTE: {
      subtype1: SYNERGY_RUN_RATE,
      subtype2: FTE_ITEM,
      subtype3: PLANNED_ITEM,
    },
  },

  [synergyTypes.REVENUE]: {
    revenueImpact: {
      subtype1: SYNERGY_RUN_RATE,
      subtype2: REVENUE_ITEM,
      subtype3: PLANNED_ITEM,
    },
    marginImpact: {
      subtype1: SYNERGY_RUN_RATE,
      subtype2: MARGIN_ITEM,
      subtype3: PLANNED_ITEM,
    },
  },

  [synergyTypes.NWC]: {
    NWCImpact: {
      subtype1: SYNERGY_IN_PERIOD,
      subtype2: CURRENCY_ITEM,
      subtype3: PLANNED_ITEM,
    },
  },

  [synergyTypes.ONE_TIME_COST]: {
    oneTimeCostImpact: {
      subtype1: IMPLEMENTATION_COST,
      subtype2: CURRENCY_ITEM,
      subtype3: PLANNED_ITEM,
    },
  },
}
