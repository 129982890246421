import { put, takeLatest, all, call, fork, take, select } from 'redux-saga/effects'
import { omit } from 'lodash'
import {
  GET_USERS,
  SEND_INVITE_EMAIL,
  UPDATE_USERS,
  SEND_RESET_EMAIL,
  GET_WHO_LOCKED_DATA,
  SET_LOCK,
  CLOSE_WHO_LOCKED_DATA,
} from '@myImoConfigActions/users/actionTypes'
import { getUsersSuccess, setIsViewLocked, setWhoLockedData } from '@myImoConfigActions/users/actions'
import * as configConstants from '@myImoConfigActions/config/actionTypes'
import { api, getUserManagementApi } from '@common/net'
import { createSaga } from '@common/sagaCreator/createSaga'
import { handleForbiddenError } from '../utils'
import { USER_FIELD, USER_REQUEST_OMITTED_FIELDS } from '@helpers/userConstants'
import { eventChannel, delay } from 'redux-saga'
import Config from '../../../../config'
import { getActionType, getErrorMessage } from '@common/notifications'
import { showWarningMessage } from '@generic/actions/actions'
import { getIsCurrentUserLockedData } from '@domain/myImoConfig/selectors'
import { fetchUsers } from '@generic/sagas/fetchUsers'
import { fetchUserGenerator } from '@domain/generic/sagas/fetchUser'
import { fetchTeamsList } from '@domain/generic/sagas/fetchTeamsList'
import { getIsLaunched } from '@domain/instanceConfig/selectors'

const REFRESH_INTERVAL = 1000 * 10
const LOCK_SEND_PERIOD = 1000 * 60 * 5

let shouldSendLockCyclically = false

export const getUsers = createSaga(
  function* getUsers(): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
    const usersApi = yield getUserManagementApi()
    const { users, allTeams, restrictedDomains } = yield call(usersApi.request('getAllUsers'))

    yield put(getUsersSuccess({ list: users, allTeams, restrictedDomains }))
  },
  true,
  handleForbiddenError,
)

export const updateUsers = createSaga(function* updateUsers({
  payload: { navigate, users: updatedUsers, isUserManagementPage = false, withoutSave = false },
}: $TSFixMe): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  if (withoutSave) {
    yield put({ type: configConstants.SET_STEP_COMPLETED, payload: { no: 7 } })

    navigate('/config/module-options')

    return
  }

  const usersApi = yield getUserManagementApi()
  let newUsers = []

  const isLaunchedInstance = yield select(getIsLaunched)
  if (!isLaunchedInstance && updatedUsers.length === 0) {
    const { users } = yield call(usersApi.request('getAllUsers'))
    newUsers = users
  } else {
    newUsers = updatedUsers.map((user: $TSFixMe) =>
      user.isNew
        ? omit(user, [...USER_REQUEST_OMITTED_FIELDS, USER_FIELD.IS_NEW, USER_FIELD.ID])
        : omit(user, USER_REQUEST_OMITTED_FIELDS),
    )
  }

  yield call(
    usersApi.request('bulkUpdateUsers', {
      body: newUsers,
      query: { isUserManagementPage },
    }),
  )

  const { users, allTeams, restrictedDomains } = yield call(usersApi.request('getAllUsers'))

  yield put(getUsersSuccess({ list: users, allTeams, restrictedDomains }))
  yield call(fetchUsers, {})
  yield call(fetchUserGenerator)
  yield call(fetchTeamsList, {})

  if (isUserManagementPage) {
    yield put({ type: configConstants.SET_HAS_CHANGES, payload: false })

    return
  }

  yield put({ type: configConstants.SET_STEP_COMPLETED, payload: { no: 7 } })

  yield navigate('/config/module-options')
},
true)

export const sendInviteEmail = createSaga(function* sendInviteEmail({
  payload,
}: $TSFixMe): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const { id } = payload

  const usersApi = yield getUserManagementApi()
  yield call(
    usersApi.request('sendUserInviteEmail', {
      body: {
        id,
      },
    }),
  )
})

export const sendResetEmail = createSaga(function* sendResetEmail({
  payload,
}: $TSFixMe): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const { id } = payload

  const usersApi = yield getUserManagementApi()
  yield call(
    usersApi.request('sendUserResetEmail', {
      body: {
        id,
      },
    }),
  )
})

export const sendLock = createSaga(function* sendLock({
  payload: { lock },
}: $TSFixMe): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const usersApi = yield getUserManagementApi()

  do {
    try {
      const { user } = yield call(usersApi.request('setLockUserManagement', { body: { lock } }))

      yield put(setWhoLockedData({ whoLockedData: user?.email || null }))
    } catch (e) {
      const { name } = e as $TSFixMe
      yield put(
        showWarningMessage({
          actionType: getActionType(name),
          errorMessage: getErrorMessage(e),
        }),
      )
      yield put(setIsViewLocked(true))
      yield call(checkIsLockedBySomebody)

      throw e
    }

    yield delay(LOCK_SEND_PERIOD)
  } while (shouldSendLockCyclically)
})

export const setLock = createSaga(function* setLock({ payload }: $TSFixMe): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const { lock } = payload
  const isCurrentUserLockedData = yield select(getIsCurrentUserLockedData)
  const skipSendRequest = !lock && !isCurrentUserLockedData

  shouldSendLockCyclically = lock

  if (skipSendRequest) return

  yield call(sendLock, { payload: { lock } })
})

export function* closeCheckIsLockedBySomebody({ channel }: $TSFixMe): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  while (true) {
    yield take(CLOSE_WHO_LOCKED_DATA)

    channel.close()
  }
}

const getIsDataLockedResponse = () =>
  api.get<$TSFixMe>({
    url: `${Config.API_SERVER}/config/user/lock`,
  })

export function* checkIsLockedBySomebody(): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  // check first time
  const {
    data: { user },
  } = yield call(getIsDataLockedResponse)

  yield put(setWhoLockedData({ whoLockedData: user?.email || null }))

  // then launch the checking in a cycle with period
  const channel = eventChannel((emitter) => {
    const intervalInstance = setInterval(() => {
      getIsDataLockedResponse().then(({ data }) => emitter(data))
    }, REFRESH_INTERVAL)

    return () => clearInterval(intervalInstance)
  })

  yield fork(closeCheckIsLockedBySomebody, { channel })

  while (true) {
    const data = yield take(channel)
    const { user } = data

    yield put(setWhoLockedData({ whoLockedData: user?.email || null }))
  }
}

export function* usersWatcher() {
  yield all([
    takeLatest(GET_USERS, getUsers),
    takeLatest(UPDATE_USERS, updateUsers),
    takeLatest(SEND_INVITE_EMAIL, sendInviteEmail),
    takeLatest(SEND_RESET_EMAIL, sendResetEmail),
    takeLatest(GET_WHO_LOCKED_DATA, checkIsLockedBySomebody),
    takeLatest(SET_LOCK, setLock),
  ])
}
