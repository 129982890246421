import { createSelector } from 'reselect'
import { get, flow, pick } from 'lodash/fp'
import { managementType } from '@myImoConfig/containers/ConfigSteps/utils'
import { notSelectedOption } from '@helpers/constants'
import { TenantCustomizationType, ModuleSelectType, InstanceConfigReducerType } from './types.d'
import { DeliverableDealType } from '@common/types/deliverables'

const localState = get('instanceConfig')

export const getCurrencySetting = flow(localState, get('currency'))
export const getCurrencyRounding = flow(localState, get('currencyRounding'))
export const getSeparatorSetting = flow(localState, get('separator'))
export const getThousandSeparatorSetting = flow(localState, get('thousandsSeparator'))
export const getPlannedEndDate = flow(localState, get('plannedEndDate'))
export const getIsLaunched = flow(localState, get('isLaunched'))
export const getLegalDisclaimer = flow(localState, get('legalDisclaimer'))
export const getWelcomeNote = flow(localState, get('welcomeNote'))
export const getDealType = flow(localState, get('dealType'))
export const getFiscalYear = flow(localState, get('fiscalYear'))
export const getWeekDayAndTimeHour = flow(localState, pick(['weekDay', 'timeHour']))
export const getSupportEmail = flow(localState, get('supportEmail'))
export const getTSAVersion = flow(localState, get('TSAVersion'))
export const getStatusUpdateVersion = flow(localState, get('statusUpdateVersion'))
export const getIsResetStarted = flow(localState, get('isResetStarted'))
export const getTermsConditions = flow(localState, get('termsConditions'))
export const getCompaniesInfo = flow(localState, (state) => ({ companyA: state.companyA, companyB: state.companyB }))
export const getDayOneDate = flow(localState, get('dayOneDate'))

export const getModules = flow(localState, get('modules'))
export const getRevenueSynergiesModule = flow(getModules, get('revenueSynergies'))
export const getMirroringRestrictionsModule = flow(getModules, get('mirroringRestrictions'))
export const getMutualDiscoveryModule = flow(getModules, get('mutualDiscovery'))
export const getDocumentSharingModule = flow(getModules, get('documentSharing'))
export const getValueCaptureModule = flow(getModules, get('valueCapture'))
export const getCommunicationsModule = flow(getModules, get('communications'))
export const getDataRequestTrackerModule = flow(getModules, get('dataRequestTracker'))
export const getImoDeliverablesPanelModule = flow(getModules, get('imoDeliverablesPanel'))
export const getTeamCalendarModule = flow(getModules, get('teamCalendar'))
export const getTeamResourcesModule = flow(getModules, get('teamResources'))
export const getFileAttachmentsModule = flow(getModules, get('fileAttachments'))
export const getNWCSynergiesModule = flow(getModules, get('NWCSynergies'))
export const getContentVaultModule = flow(getModules, get('contentVault'))
export const getValueCaptureInitiativesUploadModule = flow(getModules, get('valueCaptureInitiativesUpload'))
export const getDay1HypercareLogModule = flow(getModules, get('day1HypercareLog'))
export const getMcKinseyLibraryModule = flow(getModules, get('mcKinseyLibrary'))
export const getNotificationsModule = flow(getModules, get('notifications'))
export const getKnowledgeCenterModule = flow(getModules, get('knowledgeCenter'))
export const getWeeklyStatusUpdateModule = flow(getModules, get('weeklyStatusUpdate'))
export const getAdvancedFinancialsModule = flow(getModules, get('advancedFinancials'))
export const getNPVModule = flow(getModules, get('npv'))
export const getUnreleasedFeaturesModule = flow(getModules, get('unreleasedFeatures'))
export const getOrgDesignModule = flow(getModules, get('orgDesign'))
export const getAskMyIMOModule = flow(getModules, get('askMyIMO'))

export const getModulesSelect = flow(localState, get('modulesSelect'))
export const getMirroringTeams = flow(getModulesSelect, (modulesSelect: ModuleSelectType) => {
  if (!modulesSelect.mirroringTeams?.value) return []
  return JSON.parse(modulesSelect.mirroringTeams.value)
})
export const getInitiativeColumnsCustomNames = flow(getModulesSelect, (modulesSelect: ModuleSelectType) => {
  if (!modulesSelect.initiativeColumnsCustomNames?.value) return {}
  return JSON.parse(modulesSelect.initiativeColumnsCustomNames.value)
})
export const getPublishDay = flow(getModulesSelect, get(['publishDay', 'value']))
export const getStageGatesCalculationType = flow(getModulesSelect, get(['stageGatesCalculationType', 'value']))
const getLockDueDateD1 = flow(getModulesSelect, get(['lockDueDateD1', 'value']))
export const getTargetBase = flow(getModulesSelect, get(['targetBase', 'value']))
export const getProjectTaskStatusDate = flow(getModulesSelect, get(['projectTaskStatusDate', 'value']))
export const getInitiativeStatusDate = flow(getModulesSelect, get(['initiativeStatusDate', 'value']))
export const getStatusV3SectionNames = flow(getModulesSelect, get('statusV3SectionNames'))
export const getCalendarViewSelect = flow(getModulesSelect, get(['calendarView', 'value']))
export const getVCTimelineYears = flow(getModulesSelect, get(['vcTimelineYears', 'value']))
export const getNPVSettings = flow(getModulesSelect, (modulesSelect: ModuleSelectType) => {
  if (!modulesSelect?.npvSettings?.value) {
    return {}
  }
  return JSON.parse(modulesSelect.npvSettings.value)
})

export const getTenantCreationDate = flow(localState, get('tenantCreationDate'))
export const getTenantCustomization = flow(localState, get('tenantCustomization'))
export const getLockResetButtonColumnId = flow(localState, get('lockResetButtonColumnId'))
export const getActiveRestrictions = flow(localState, get('activeRestrictions'))

export const getManagementType = <(state: InstanceConfigReducerType) => DeliverableDealType>(
  flow(getDealType, managementType)
)

export const getIsEnabledKeyIssues = flow(getTenantCustomization, get('isEnabledKeyIssues'))
export const getMacroPlanningTitles = flow(getTenantCustomization, get('macroPlanningTitles'))
export const getTSAOptions = createSelector(getTenantCustomization, (tenantCustomization: TenantCustomizationType) => [
  notSelectedOption,
  ...tenantCustomization.TSAOptions,
])
export const getPotentialImpact = createSelector(
  getTenantCustomization,
  (tenantCustomization: TenantCustomizationType) => [notSelectedOption, ...tenantCustomization.potentialImpact],
)

export const getIsMarginTargetBaseSelected = flow(getTargetBase, (targetBase) => targetBase === 'margin')

export const getLockDueDateD1Options = flow(getLockDueDateD1, (value) =>
  value.isActive ? value.allowedPrioritiesList : null,
)

export const getMdpaTSAColumnName = flow(getTenantCustomization, get('mdpaTSAColumnName'))
