import styled from 'styled-components'
import { Colors } from '@blueprintjs/core'
import { textDarkGrey, white } from '@imo/imo-ui-toolkit'
import { NavLink } from 'react-router-dom'

export const StyledTabLink = styled(NavLink)<{ $withIcon?: boolean }>`
  color: ${textDarkGrey};
  line-height: 32px;
  font-size: 13px;
  margin: 0 ${({ $withIcon }) => ($withIcon ? 5 : 15)}px;
  white-space: nowrap;

  &:first-child {
    margin-left: 0;
  }

  &:hover {
    text-decoration: none;
  }

  &.active {
    font-weight: 500;
    color: ${Colors.BLUE3};
    box-shadow: inset 0 -3px 0 ${Colors.BLUE3};
  }

  .link-text,
  .bp4-icon {
    vertical-align: middle;
  }

  .bp4-icon {
    margin-right: 5px;
  }
`

export const DisabledLink = styled.span`
  line-height: 32px;
  font-size: 13px;
  cursor: not-allowed;
  color: rgba(92, 112, 128, 0.5);
  user-select: none;
  margin: 0 15px;
  white-space: nowrap;

  &:first-child {
    margin-left: 0;
  }

  .tooltip-container {
    display: block;
    height: 100%;
    line-height: 0;
  }

  .link-text,
  .bp4-icon {
    vertical-align: middle;
  }

  .bp4-icon {
    margin-right: 5px;
  }
`

export const StyledNavigation = styled.div<{ $innerNavigation?: boolean }>`
  display: flex;
  align-items: center;
  padding: 0 11px 0 15px;
  background-color: ${white};
  border-bottom: 1px solid rgb(218, 220, 222);
  overflow: hidden;
  margin: ${({ $innerNavigation }) => ($innerNavigation ? '0 6px' : 0)};

  .bp4-divider {
    height: 14px;
    margin: 0;
  }
`
