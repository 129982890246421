import { MASTER_PLANNING_DELIVERABLE_TYPE_ID, synergyTypes } from '@helpers/constants'
import {
  ALL_INITIATIVES_ASIDE_HEADERS_HEIGHT,
  INITIATIVES_ASIDE_HEADERS_HEIGHT,
} from '@myImoClient/components/TeamValueCaptureV2/InitiativeList/AsidePanel/utils/constants'
import { routesList } from '@common/accessController/routesList'
import InstructionsTooltip from '@shared/InstructionsTooltip'
import React from 'react'
import { getDayOneDeliverable } from '@helpers/utils'
import { filter, find, first, flow, get as getFp, isEmpty, map, sortBy } from 'lodash/fp'
import PropTypes from 'prop-types'
import { initiativeStatuses } from '@helpers/statuses'
import { Colors } from '@blueprintjs/core'
import { generatePath } from 'react-router-dom'

export { getYears, getMonth } from './getOneTimeCostTime'

export const getInitiativeAlias = (synergyType) =>
  synergyType === synergyTypes.ONE_TIME_COST ? 'one-time cost' : 'initiative'

export const getSynergyInitiativeAlias = (synergyType) =>
  synergyType === synergyTypes.ONE_TIME_COST ? 'one-time cost' : 'synergy initiative'

export const getPanelHeight = (isAllInitiatives, synergyType, isBreadcrumbsEnabled) => {
  const contentHeight = isAllInitiatives ? ALL_INITIATIVES_ASIDE_HEADERS_HEIGHT : INITIATIVES_ASIDE_HEADERS_HEIGHT

  let additionalHeight = 0

  if (isBreadcrumbsEnabled) {
    if (isAllInitiatives) {
      additionalHeight = synergyType === synergyTypes.COST ? 36 : 69
    } else {
      additionalHeight = synergyType === synergyTypes.COST ? 36 : 99
    }
  }
  return `calc(100vh - ${contentHeight}px + ${additionalHeight}px)`
}

export const getRouteToInitiative = ({ linkedItem, isAllInitiatives, teamId }) => {
  const synergyType =
    !linkedItem.type || linkedItem.type === synergyTypes.ONE_TIME_COST ? 'ONE_TIME_COST' : linkedItem.type.toUpperCase()

  const routeToRedirect = isAllInitiatives
    ? routesList.VALUE_CAPTURE_TRACKER_V2.ALL_INITIATIVES[synergyType].route
    : routesList.DASHBOARD.MY_TEAM.VALUE_CAPTURE_V2.INITIATIVE_LIST[synergyType].route

  return generatePath(routeToRedirect, { teamId })
}

export const IconWithTooltip = ({ isOneTimeCostTab, managementType }) => (
  <InstructionsTooltip
    tooltipMessage={`Only team members associated to this ${
      isOneTimeCostTab ? 'one-time cost' : 'initiative'
    } plus Global ${managementType} members and Value Capture central team members can view confidential ${
      isOneTimeCostTab ? 'one-time cost' : 'initiative'
    }`}
    iconProps={{ color: Colors.GRAY5 }}
    iconSize={16}
  />
)

IconWithTooltip.propTypes = {
  isOneTimeCostTab: PropTypes.bool.isRequired,
  managementType: PropTypes.string.isRequired,
}

export const IconWithTooltipOneTimeBenefit = ({ tooltipMessage }) => (
  <InstructionsTooltip tooltipMessage={tooltipMessage} iconProps={{ color: Colors.GRAY5 }} iconSize={16} />
)

IconWithTooltipOneTimeBenefit.propTypes = {
  tooltipMessage: PropTypes.string.isRequired,
}

export const getActiveDayOneDeliverableId = ({ deliverableList, teamId, initiativeTeamId, teamDeliverables }) => {
  const deliverableTeamId = Number(teamId)
  const dayOneDeliverables = getDayOneDeliverable(deliverableList, deliverableTeamId)

  const masterPlanningTeamDeliverables = flow(
    find({ id: initiativeTeamId }),
    getFp(['deliverableTypes']),
    find({ id: MASTER_PLANNING_DELIVERABLE_TYPE_ID }),
    getFp(['teamDeliverables']),
  )(teamDeliverables?.integrationTeams)

  const activeDeliverables = flow(
    filter(({ id, active, status }) => {
      const isTeamDeliverableActive = flow(find({ deliverableId: id }), getFp('active'))(masterPlanningTeamDeliverables)
      const isAppropriateStatus = status?.toLowerCase() !== initiativeStatuses.NOT_STARTED

      return active && isTeamDeliverableActive && isAppropriateStatus
    }),
    sortBy(({ id }) => id),
    map(({ id }) => id),
  )(dayOneDeliverables)

  if (isEmpty(activeDeliverables)) return null

  return first(activeDeliverables)
}
