import { get, isNull } from 'lodash'
import moment from 'moment'

const MIN_YEARS_THRESHOLD = 10
const MAX_YEARS_THRESHOLD = 5

export const CHART_DATE_RANGE = {
  Monthly: {
    maxRange: 35,
    unit: 'M',
  },
  Weekly: {
    maxRange: 35,
    unit: 'w',
  },
  default: {
    maxRange: 35,
    unit: 'M',
  },
}

export const BIG_CHART_MONTH_RANGE = [18, 6, CHART_DATE_RANGE.Monthly.maxRange, CHART_DATE_RANGE.Monthly.unit]
export const BIG_CHART_WEEK_RANGE = [18, 6, CHART_DATE_RANGE.Weekly.maxRange, CHART_DATE_RANGE.Weekly.unit]
export const SMALL_CHART_MONTH_RANGE = [13, 4, CHART_DATE_RANGE.Monthly.maxRange, CHART_DATE_RANGE.Monthly.unit]
export const SERVER_DATE_FORMAT = 'YYYY-MM-DD[T]HH:mm:ss.SSS[Z]'

export const dateComparator = (date1, date2) => {
  const formattedDate1 = moment(date1)
  const formattedDate2 = moment(date2)

  if (!formattedDate1.isValid() && !formattedDate2.isValid()) {
    return 0
  }

  if (!formattedDate1.isValid()) {
    return -1
  }

  if (!formattedDate2.isValid()) {
    return 1
  }

  return formattedDate1 - formattedDate2
}

export const timeComparator = (time1, time2) => {
  const formattedTime1 = moment(time1, 'HH:mm')
  const formattedTime2 = moment(time2, 'HH:mm')

  if (!formattedTime1.isValid() && !formattedTime2.isValid()) {
    return 0
  }

  if (!formattedTime1.isValid()) {
    return -1
  }

  if (!formattedTime2.isValid()) {
    return 1
  }

  return formattedTime1 - formattedTime2
}

export function convertLocalDateToUTC(date) {
  return new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()))
}

export const datesComparator = (filterLocalDateAtMidnight, cellValue) => {
  if (isNull(cellValue)) {
    return -1
  }

  const cellDate = moment.utc(cellValue)
  const dateToFilter = moment.utc(convertLocalDateToUTC(filterLocalDateAtMidnight))

  if (cellDate.isSame(dateToFilter, 'day')) {
    return 0
  }

  if (cellDate.isBefore(dateToFilter, 'day')) {
    return -1
  }

  return 1
}

export const formatWithoutTimezone = (date) => {
  return moment(date).utc().format('YYYY-MM-DDTHH:mm:ss.SSS[Z]')
}

export const getDateWithFormattedTime = (date, endTime) => {
  const formattingPattern = endTime ? 'yyyy-MM-DDT[23:59:59Z]' : 'yyyy-MM-DDTHH:mm:ss[Z]'

  return moment(date).format(formattingPattern)
}

export const getDateAsUTCISOString = (date) => {
  return moment.utc(date).toISOString()
}

export const getDateIgnoreTimezone = (momentDateObj) => {
  return moment.parseZone(momentDateObj).local(true)
}

export const getMomentDateWithoutTimezone = (dateString) => {
  const dateWithoutTimeAndTimezone = moment.utc(dateString).format().split('T')[0]

  return moment.utc(dateWithoutTimeAndTimezone).toDate()
}

const shouldIgnoreTime = (colDef) =>
  // if some date requires time, pass "ignoreTime: false"
  get(colDef, ['editable']) ? get(colDef, ['cellEditorParams', 'ignoreTime'], true) : get(colDef, ['ignoreTime'], true)

export const formatDate = (props) => {
  const { value } = props
  const ignoreTime = shouldIgnoreTime(get(props, ['colDef']))

  if (!value) {
    return ''
  }

  const date = moment.utc(value)

  return date.isValid() ? (!ignoreTime ? date.format('ll') : getDateIgnoreTimezone(date).format('ll')) : 'Select date'
}

export const getFormattedDate = (date, format = 'll') => date && `${moment(date).utc().format(format)}`

export const getParamsForDate = () => ({
  valueFormatter: (params) => getFormattedDate(params.value),
  tooltipValueGetter: null,
  cellEditorPopup: true,
})

export const getCurrentDate = () => new Date()

export const getCurrentMonth = () => moment().utc().endOf('month').subtract(1, 'day').toDate()

export const getCurrentDateInUTC = () => moment().utc().startOf('day').toDate()

export const applyDateFilterWithoutFormatting = () => ({
  filter: 'agDateColumnFilter',
  valueFormatter: formatDate,
  comparator: dateComparator,
  filterParams: {
    comparator: datesComparator,
    browserDatePicker: true,
    suppressAndOrCondition: true,
    inRangeInclusive: true,
  },
})

export const applyDateFilter = () => ({
  ...applyDateFilterWithoutFormatting(),
  valueFormatter: formatDate,
})

export const getMinDateWithThreshold = (years) => moment().utc().subtract(years, 'years')

export const getMaxDateWithThreshold = (years = MAX_YEARS_THRESHOLD) => moment().utc().add(years, 'years').toDate()

export const getMinDate = (dateRaised, minDate) => {
  let minDateThreshold = minDate ? moment(minDate).utc() : getMinDateWithThreshold(MIN_YEARS_THRESHOLD)

  if (minDateThreshold.isSameOrAfter(dateRaised, 'day')) {
    minDateThreshold = moment(dateRaised)
  }

  return minDateThreshold.utc().startOf('day').toDate()
}

export const getMaxDate = (dateResolved, dateWithThreshold) => {
  const today = moment().utc()

  if (dateWithThreshold) {
    return moment(dateWithThreshold).isSameOrAfter(dateResolved, 'days')
      ? getDateIgnoreTimezone(dateResolved).toDate()
      : dateWithThreshold
  }

  return today.isSameOrAfter(dateResolved, 'days')
    ? getDateIgnoreTimezone(dateResolved).toDate()
    : getDateIgnoreTimezone(today).toDate()
}

export const getDateWithUTCTrue = (date) => moment(date).utc(true)

export const formatDateForComparing = (date) => moment(date).set({ hour: 0, minute: 0, second: 0 }).unix()

export const getEndOfCurrentDate = () => moment().utc().endOf('day').toDate()

export const getCurrentFormattedTime = (template) => moment.utc().format(template)

export const getNextYears = (startYear, nextYearsAmount, asString = false) => {
  return Array(nextYearsAmount)
    .fill()
    .map((v, i) => {
      const result = +startYear + i

      return asString ? result.toString() : result
    })
}

export const startOfMonth = (date) => moment(date).startOf('month').utc(false).format('YYYY-MM-DD')

export const getMaxDateThresholdInMonths = (monthCounter) => moment().utc().add(monthCounter, 'month').toDate()

export const getMaxAvailableDateThresholdInMonths = (dateFrom, monthCounter, durationUnit) =>
  moment(dateFrom).add(monthCounter, durationUnit).toDate()

export const getDefaultDateRange = (
  tenantCreationDate,
  monthsToSubtract = 13,
  monthsToAdd = 4,
  defaultMaxDate,
  durationUnit,
  weeklyStartDate,
) => {
  const defaultStartDate =
    durationUnit === 'M'
      ? moment().utc().subtract(monthsToSubtract, 'month').format('YYYY-MM-DD')
      : moment(weeklyStartDate).format('YYYY-MM-DD')

  const startDate =
    moment(tenantCreationDate).isAfter(defaultStartDate) && durationUnit === 'M'
      ? moment(tenantCreationDate).format('YYYY-MM-DD')
      : defaultStartDate

  const defaultEndDate = getMaxDateThresholdInMonths(monthsToAdd)

  const range = {
    endDate: moment(defaultEndDate).format('YYYY-MM-DD'),
    startDate,
  }

  if (defaultMaxDate) {
    range.defaultMaxDate = moment(getMaxAvailableDateThresholdInMonths(startDate, defaultMaxDate, durationUnit)).format(
      'YYYY-MM-DD',
    )
  }

  return range
}

export const lastYear = (date) => moment(date).subtract(1, 'year').toDate()

export const isSameDay = (date1, date2) => moment(date1).isSame(date2, 'day')

export const getFormattedDateForFileName = (date) => moment(date).format('ll')

export const getFirstDateOfYear = (date) => {
  const year = new Date(date).getFullYear()

  return getMomentDateWithoutTimezone(getFormattedDateForFileName({ year }))
}

export const getLastDateOfYear = (date, vcTimelineYears = MAX_YEARS_THRESHOLD) => {
  const year = new Date(date).getFullYear() + vcTimelineYears
  const DAY = 1

  return new Date(moment({ year }).subtract(DAY, 'd').format('ll'))
}

export const getLastYear = (date, vcTimelineYears = 5) => {
  const formattedDate = getLastDateOfYear(date, vcTimelineYears)

  return moment(formattedDate, 'DD/MM/YYYY').year()
}

export const formatLocalDate = (date, format = 'll') => {
  if (!date) return null

  return moment(new Date(date)).format(format)
}

export const getMinDateThresholdInMonths = (monthCounter) => {
  return new Date(moment().subtract(monthCounter, 'month').format('YYYY-MM-DD'))
}

export const isDateSameOrAfterIgnoringTimezone = (day1, day2) =>
  moment(formatLocalDate(day1)).isSameOrAfter(formatLocalDate(day2))

export const isFutureDate = (date) => moment(formatLocalDate(date)).isAfter(moment())

export const getSnapshotFormattedDate = (date, format) => moment.utc(date).format(format)

export const getDateIgnoreTimeAndTimezone = (date) => moment(date).startOf('day').format('YYYY-MM-DDTHH:mm:ss.SSS[Z]')
