import { Colors } from '@blueprintjs/core'
import { CellClassParams, ColDef, ColGroupDef } from 'ag-grid-community'
import { processItemFields } from '@common/accessController/strategies/deliverables/constants'
import { isFunction } from 'lodash'
import { ActiveUserFocus, ActiveUserSocketData, Rooms } from '@domain/realTime/types'
import { AUTO_GROUP_COLUMN_ID } from '@shared/Grid/constants'

type BaseFocusedCell = {
  id: number
  cellKey: string
  user: {
    id: number
    color: string
    name: string
    surname: string
  }
  field: string
  editing?: boolean
  rowId: string
  color: string
}

type DayOneFocusedCell = BaseFocusedCell & {
  type: string
  projectListId: string
}

type MDPAFocusedCell = BaseFocusedCell & {
  name: string
}

type TeamCharterFocusedCell = BaseFocusedCell & {
  type: string
}

export type FocusedCell = DayOneFocusedCell | MDPAFocusedCell | TeamCharterFocusedCell

const mapDayOneProjectListFocusData = (data: ActiveUserSocketData) => {
  if ('projectListId' in data.focus) {
    const {
      user: { id: userId, name, surname },
      color,
      focus: { id, field, rowId, editing, cellKey, projectListId, type },
    } = data

    return {
      id,
      cellKey,
      user: { id: userId, color, name, surname },
      field,
      editing,
      type,
      projectListId,
      rowId,
      color,
    }
  }

  return null
}

const mapTeamCharterFocusData = (data: ActiveUserSocketData) => {
  if ('type' in data.focus) {
    const {
      user: { id: userId, name, surname },
      color,
      focus: { id, field, rowId, editing, cellKey, type },
    } = data

    return {
      id,
      cellKey,
      user: { id: userId, color, name, surname },
      field,
      editing,
      type,
      rowId,
      color,
    }
  }

  return null
}

const mapMDPAFocusData = (data: ActiveUserSocketData) => {
  if ('name' in data.focus) {
    const {
      user: { id: userId, name, surname },
      color,
      focus: { id, field, rowId, editing, cellKey, name: processName },
    } = data

    return {
      id,
      cellKey,
      user: { id: userId, color, name, surname },
      field,
      editing,
      rowId,
      color,
      name: processName,
    }
  }

  return null
}

const mapTeamValueCaptureData = (data: ActiveUserSocketData) => {
  if ('name' in data.focus) {
    const {
      user: { id: userId, name, surname },
      color,
      focus: { id, field, rowId, editing, cellKey, name: initiativeName },
    } = data

    return {
      id,
      cellKey,
      user: { id: userId, color, name, surname },
      field,
      editing,
      rowId,
      color,
      name: initiativeName,
    }
  }

  return null
}

const focusDataMappers = {
  [Rooms.DayOneProjectList]: mapDayOneProjectListFocusData,
  [Rooms.MDPA]: mapMDPAFocusData,
  [Rooms.TeamValueCaptureCost]: mapTeamValueCaptureData,
  [Rooms.TeamCharter]: mapTeamCharterFocusData,
}

export const mapFocusedCellData = (data: ActiveUserSocketData, room: Rooms): FocusedCell | null => {
  const mappingFunction = focusDataMappers[room]

  if (mappingFunction) {
    return mappingFunction(data)
  }

  return null
}

export const getTooltipFromValueGetter = (data: $TSFixMe) => {
  const valueGetter = data?.colDef?.primaryTooltipValueGetter

  if (isFunction(valueGetter)) {
    return valueGetter(data)
  }

  return null
}

export const realtimeCellStyle = (params: CellClassParams) => {
  const activeCell = params.context.activity?.focusedCells.find((cell: ActiveUserFocus) => {
    const isRow = cell.rowId === params.node.id
    if (isRow) {
      return (
        params.colDef.field === cell.field ||
        (params.colDef.field === processItemFields.NAME && cell.field === AUTO_GROUP_COLUMN_ID)
      )
    }
    return false
  })
  if (activeCell && params.context.activity.userId !== activeCell.user?.id) {
    return {
      borderColor: activeCell.color,
      borderWidth: '2px',
      backgroundColor: activeCell.editing ? Colors.LIGHT_GRAY1 : 'initial',
    }
  }
  return { borderColor: undefined, borderWidth: undefined }
}

const decorateColumn = (column: ColDef | ColGroupDef, excludedColumns: string[]): ColDef | ColGroupDef => {
  const newColumn: Record<string, any> = {
    tooltipValueGetter: getTooltipFromValueGetter,
    tooltipParams: {
      alwaysShowTooltip: (params: $TSFixMe) => Boolean(getTooltipFromValueGetter(params)),
    },
    cellStyle: realtimeCellStyle,
  }

  if ('field' in column) {
    if (column.field && excludedColumns.includes(column.field)) {
      return column
    }

    if (column.tooltipValueGetter) newColumn.primaryTooltipValueGetter = column.tooltipValueGetter
  }

  if ('children' in column && column.children) {
    column.children = column.children.map((childColumn) => {
      return decorateColumn(childColumn, excludedColumns)
    })
  }

  return { ...column, ...newColumn }
}

export const decorateWithSocketCols = ({
  excludedColumns = [],
  colDef,
}: {
  excludedColumns: string[]
  colDef: ColDef[] | ColGroupDef[]
}): ColDef[] | ColGroupDef[] => {
  return colDef.map((column) => {
    return decorateColumn(column, excludedColumns)
  })
}
