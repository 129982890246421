import { put, call } from 'redux-saga/effects'
import { getMyTeamApi } from '@common/net'
import { setTeamProjectsStatuses } from '@teamHome/actions/actions'
import { createSaga } from '@common/sagaCreator/createSaga'

export const fetchTeamProjectsStatuses = createSaga(function* fetchTeamProjectsStatuses(
  action: $TSFixMe,
): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const { teamId } = action.payload

  const api = yield getMyTeamApi()
  const data = yield call(api.request('getTeamProjectsStatuses', { query: { teamId } }))

  yield put(setTeamProjectsStatuses(data))
})
