import styled from 'styled-components'
import { PROCESS_STATUS_COLORS } from '@helpers/statuses/processStatuses'
import { InstructionsTooltip, FONT_FAMILY, midGrey, black } from '@imo/imo-ui-toolkit'

const UPDATE_PANEL_HEIGHT = '231px'
const UPDATE_PANEL_HEIGHT_WITH_ACTIVITY_LOG = '282px'

export const Container = styled.div`
  padding: 5px;
  height: 100%;
  overflow: hidden;
`

export const StyledTooltip = styled(InstructionsTooltip)`
  margin: 2px 3px;
`

export const WeekStatusContent = styled.div<{ $isActivityLogEnabled?: boolean }>`
  display: flex;
  width: 100%;
  height: ${({ $isActivityLogEnabled }) =>
    `calc(100% - ${$isActivityLogEnabled ? UPDATE_PANEL_HEIGHT_WITH_ACTIVITY_LOG : UPDATE_PANEL_HEIGHT})`};
`

export const StatusPanelsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 291px;
  flex-shrink: 0;
  margin-top: 5px;
  height: 100%;
`

export const TextInputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  height: 100%;

  .linkify-area {
    border: none;
  }

  &:first-of-type {
    margin-right: 5px;
  }
`

export const TextInputLabel = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 7px;
  height: 13px;

  @media screen and (min-width: 460px) {
    flex-direction: row;
    justify-content: space-between;
  }
`

export const TextInputTitle = styled.div`
  font: 13px ${FONT_FAMILY};
  font-weight: 500;
  margin: 0;
  color: ${black};
`

export const UpdatedDate = styled.div`
  font: 12px ${FONT_FAMILY};
  color: ${midGrey};

  span {
    font: 12px ${FONT_FAMILY};
    font-weight: 500;
  }
`
export const StatusContentWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  padding: 15px 5px;

  textarea.bp4-input {
    font: 13px ${FONT_FAMILY};
    height: 100%;
    padding: 10px 5px;
    line-height: 21px;
  }
`

export const ImoStatusContentWrapper = styled(StatusContentWrapper)`
  padding: 15px 5px;
  gap: 0;
`

export const GridWrapper = styled.div`
  width: calc(100% - 291px);
  height: calc(100% - 5px);
  display: flex;

  @media screen and (max-width: 1280px) {
    width: 100%;
  }
`

export const TooltipListItem = styled.p`
  padding-left: 10px;
  position: relative;

  &::before {
    display: block;
    content: '•';
    position: absolute;
    left: 0;
  }
`

export const ColoredStatus = styled.span<{ status: keyof typeof PROCESS_STATUS_COLORS }>`
  color: ${({ status }) => PROCESS_STATUS_COLORS[status] || midGrey};
  text-transform: uppercase;
  font-family: ${FONT_FAMILY};
  font-weight: 500;
`

export const SECTION_STYLES = {
  status: {
    customStyles: {
      width: '100%',
      overflowY: 'auto',
      height: UPDATE_PANEL_HEIGHT,
    },
    headerStyles: {
      padding: '0',
      display: 'flex',
      flexDirection: 'column-reverse',
      maxHeight: '77px',
    },
    contentStyles: {
      padding: '0 10px',
    },
  },
  deliverablesStatus: {
    customStyles: {
      width: '100%',
      padding: '0',
      height: '236px',
    },
    contentStyles: {
      padding: '0',
      overflowY: 'overlay',
    },
    isScrollable: true,
  },
  projectsStatus: {
    customStyles: {
      width: '100%',
      height: 'calc(100% - 236px)',
      margin: '5px 0',
    },
    contentStyles: {
      padding: '5px',
      overflowY: 'auto',
      display: 'flex',
    },
  },
  keyIssues: {
    customStyles: {
      padding: '0',
      height: '100%',
      width: '100%',
      margin: '5px 0 5px 5px',
    },
    contentStyles: {
      padding: '0',
    },
  },
  otherTeamUpdates: {
    customStyles: {
      padding: '0',
      margin: '5px 0 5px 5px',
      width: 'calc(50% - 5px)',
      flexShrink: 0,
    },
    contentStyles: {
      padding: '0',
    },
  },
  flaggedRisksAndDecisions: {
    customStyles: {
      padding: '0',
      margin: '5px 0 5px 5px',
      width: 'calc(50% - 5px)',
    },
    contentStyles: {
      padding: '0',
    },
  },
}

export const ACTIVITY_LOG_SECTION_STYLES = {
  ...SECTION_STYLES,
  status: {
    ...SECTION_STYLES.status,
    customStyles: {
      ...SECTION_STYLES.status.customStyles,
      height: UPDATE_PANEL_HEIGHT_WITH_ACTIVITY_LOG,
    },
  },
}

export const ErrorDescription = styled.p`
  margin-bottom: 10px !important;

  &:last-child {
    margin-bottom: 0px !important;
  }
`

export const ActionButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  padding: 5px;
  border-bottom: 1px solid #dadcde;
`
