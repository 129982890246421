import { handleActions, combineActions } from 'redux-actions'
import { updateData } from '../utils'
import {
  FETCH_TR_DECISION_LOG_DATA,
  FETCH_DECISION_LOG_DATA_PENDING,
  FETCH_DECISION_LOG_DATA_SUCCESS,
  FETCH_DECISION_LOG_DATA_FAIL,
  UPDATE_DECISION_LOG_DATA_SUCCESS,
  UPDATE_DECISION_LOG_DATA_FAIL,
  ADD_DECISION_LOG_DATA,
  DELETE_DECISION_LOG_DATA_SUCCESS,
  FETCH_CONTROL_TOWER_DECISION_LOG_DATA,
  FETCH_CONTROL_TOWER_DECISION_LOG_DATA_SUCCESS,
  UPDATE_CONTROL_TOWER_DECISION_LOG_DATA_SUCCESS,
} from '../actions/actionTypes'

export interface IDecisionLogState {
  loading: boolean
  decisionLogData: $TSFixMe[]
}

export const initialState = {
  loading: false,
  decisionLogData: [],
}

const decisionLogReducer = handleActions<IDecisionLogState, $TSFixMe>(
  {
    [`${combineActions(
      FETCH_DECISION_LOG_DATA_PENDING,
      FETCH_TR_DECISION_LOG_DATA,
      FETCH_CONTROL_TOWER_DECISION_LOG_DATA,
    )}`]: (state) => ({
      ...state,
      loading: true,
    }),
    [`${combineActions(FETCH_DECISION_LOG_DATA_SUCCESS, FETCH_CONTROL_TOWER_DECISION_LOG_DATA_SUCCESS)}`]: (
      state,
      { payload },
    ) => ({
      ...state,
      loading: false,
      decisionLogData: payload.data,
    }),
    [`${combineActions(UPDATE_DECISION_LOG_DATA_SUCCESS, UPDATE_CONTROL_TOWER_DECISION_LOG_DATA_SUCCESS)}`]: (
      state,
      { payload: { id, body, sortOrder } },
    ) => ({
      ...state,
      decisionLogData: updateData({ data: state.decisionLogData, id, body, sortOrder }),
      loading: false,
    }),
    [ADD_DECISION_LOG_DATA]: (state, { payload }) => {
      if (!payload.isNew) {
        return state
      }

      const { decisionLogData } = state

      return {
        ...state,
        decisionLogData: [...decisionLogData, payload],
      }
    },
    [DELETE_DECISION_LOG_DATA_SUCCESS]: (state, { payload: decisionId }) => {
      const { decisionLogData } = state

      return {
        ...state,
        decisionLogData: decisionLogData.filter((item) => item.id !== decisionId),
      }
    },
    [`${combineActions(FETCH_DECISION_LOG_DATA_FAIL, UPDATE_DECISION_LOG_DATA_FAIL)}`]: (state) => ({
      ...state,
      loading: false,
    }),
  },
  initialState,
)

export default decisionLogReducer
