import React, { useCallback, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'

import HeaderTitle from '@shared/Heading/HeaderTitle'
import TeamSelector from '@shared/TeamSelector'
import LogoContainer from './LogoContainer'
import { Heading, InfoArea, Container, StyledIcon, StatusIndicator } from './Header.styles'
import Status from './Status'
import { getManagementType } from '@domain/instanceConfig/selectors'
import { getSelectedTeamIds } from '@generic/selectors/selectedTeams'
import { getLocationParams, getNotRestrictedTeamsTree, getCompanyLogos } from '@generic/selectors'

import { setSelectedTeamId } from '@generic/actions/actions'

import { teamTitles } from '@common/accessController/constants'
import { isSMO } from '@myImoConfig/containers/ConfigSteps/utils'
import { getTeamsList, getCurrentTeamId } from '@common/teamFilter/teamFilterConditions'
import { replace, flatten, head, isEmpty } from 'lodash'
import AppliedFilters from '@myImoClient/components/Header/AppliedFilters'
import { FONT_FAMILY_BOWER } from '@imo/imo-ui-toolkit'
import { useFeatureFlags } from '@views/hooks/useFeatureFlags'
import { useNavigate } from 'react-router-dom'

const Header = ({
  status,
  title,
  icon,
  iconSize,
  iconStyles,
  tooltipMessage,
  tooltipKey,
  actionButtons,
  attachment,
  hideRestrictedTeams,
  showTeamSelect,
  fontFamily,
  fontSize,
  isNested,
  showEmptyStatusIndicator,
  innerHeader,
}) => {
  const dispatch = useDispatch()

  const { isEnabled } = useFeatureFlags()
  const isRestrictedUserExtendedAccessEnabled = isEnabled('RESTRICTED_USER_EXTENDED_ACCESS')
  const isRestrictedUserWeeklyStatusAccessEnabled = isEnabled('RESTRICTED_USER_WEEKLY_STATUS_UPDATE')

  const companiesLogo = useSelector(getCompanyLogos)
  const teamsTree = useSelector((state) => getNotRestrictedTeamsTree(state, hideRestrictedTeams))
  const selectedTeamIds = useSelector(getSelectedTeamIds)
  const teamsList = useSelector(
    getTeamsList(isRestrictedUserExtendedAccessEnabled, isRestrictedUserWeeklyStatusAccessEnabled),
  )
  const managementType = useSelector(getManagementType)
  const navigate = useNavigate()

  const withSmo = isSMO(managementType)

  const locationParams = useSelector(getLocationParams)
  const { pathname, isImoControlTower, isVcTracker, teamId, fromTeam, fromImoTeam, isOrgDesignTracker, fromTeamHome } =
    locationParams

  const getTeamSelectType = useMemo(() => {
    if (isImoControlTower) return teamTitles.IMO

    if (isVcTracker) return teamTitles.CENTRAL_VC

    if (isOrgDesignTracker) return teamTitles.CENTRAL_ORG_DESIGN

    return withSmo ? teamTitles.SEPARATION : teamTitles.INTEGRATION
  }, [isImoControlTower, isVcTracker, withSmo])

  const getExistingTeam = useMemo(() => {
    const headTeam = head(flatten(Object.values(teamsList)))

    return headTeam?.id
  }, [teamsList])

  const teamExists = (id) => {
    const flattenTeams = flatten(Object.values(teamsList))

    return flattenTeams.find((team) => team.id === id)
  }

  const updateLocationWithTeam = (id) => {
    const paramsForImoTeam = fromImoTeam ? `?fromImoTeam=${fromImoTeam}` : ''
    const fromTeamParams = fromTeam ? `?fromTeam=${fromTeam}` : paramsForImoTeam
    const fromTeamHomeParams = fromTeamHome ? `?fromTeamHome=true` : ''
    const params = fromTeamParams.length ? fromTeamParams : fromTeamHomeParams
    navigate(replace(pathname, `team/${teamId}`, `team/${id}`) + params)
  }

  useEffect(() => {
    if (!teamId || isEmpty(teamsList)) return

    if (!teamExists(teamId)) {
      const selectedTeam = getCurrentTeamId(locationParams, selectedTeamIds)
      const existingSelectedTeam = teamExists(selectedTeam) ? selectedTeam : getExistingTeam

      existingSelectedTeam && updateLocationWithTeam(existingSelectedTeam)

      return
    }

    if (isImoControlTower && teamId !== selectedTeamIds.imoTeam) {
      dispatch(setSelectedTeamId({ imoTeam: teamId }))
    } else if (isVcTracker && teamId !== selectedTeamIds.centralTeam) {
      dispatch(setSelectedTeamId({ centralTeam: teamId }))
    } else if (!isImoControlTower && !isVcTracker && teamId !== selectedTeamIds.team) {
      dispatch(setSelectedTeamId({ team: teamId }))
    }
  }, [teamId, teamsTree, selectedTeamIds, teamsList])

  const handleTeamSelect = useCallback(
    (team) => {
      updateLocationWithTeam(team.id)

      if (isImoControlTower) {
        dispatch(setSelectedTeamId({ imoTeam: team.id }))

        return
      }

      if (isVcTracker) {
        // TODO: refactor selectedTeamId logic, centralTeam id always equal to the first id in teams response
        dispatch(setSelectedTeamId({ centralTeam: team.id }))

        return
      }

      if (isOrgDesignTracker) {
        dispatch(setSelectedTeamId({ centralOrgDesignTeam: team.id }))
        return
      }

      dispatch(setSelectedTeamId({ team: team.id }))
    },
    [updateLocationWithTeam, isImoControlTower, isVcTracker, isOrgDesignTracker],
  )

  return (
    <Container className={!isNested ? 'header-component' : undefined}>
      {status && <Status status={status} />}
      {showEmptyStatusIndicator && <StatusIndicator size={'min'} />}
      <Heading $innerHeader={innerHeader}>
        <InfoArea>
          {!!icon ? <StyledIcon icon={icon} size={iconSize} {...iconStyles} /> : null}
          {title && (
            <HeaderTitle
              title={title}
              tooltipMessage={tooltipMessage}
              tooltipKey={tooltipKey}
              attachment={attachment}
              fontFamily={fontFamily}
              fontSize={fontSize}
            />
          )}
          {showTeamSelect ? (
            <TeamSelector
              teamType={getTeamSelectType}
              teamsList={teamsList}
              selectedTeam={teamId}
              onTeamSelect={handleTeamSelect}
              managementType={managementType}
              isVCTracker={isVcTracker}
              isOrgDesignTracker={isOrgDesignTracker}
            />
          ) : null}
          <AppliedFilters />
        </InfoArea>
        {actionButtons || <LogoContainer sources={companiesLogo} managementType={managementType} />}
      </Heading>
    </Container>
  )
}

Header.propTypes = {
  status: PropTypes.string,
  title: PropTypes.string,
  icon: PropTypes.string,
  iconSize: PropTypes.number,
  tooltipMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.arrayOf(PropTypes.element)]),
  actionButtons: PropTypes.node,
  tooltipKey: PropTypes.string,
  attachment: PropTypes.object,
  hideRestrictedTeams: PropTypes.bool,
  showTeamSelect: PropTypes.bool,
  iconStyles: PropTypes.object,
  fontFamily: PropTypes.string,
  fontSize: PropTypes.string,
  isNested: PropTypes.bool,
  showEmptyStatusIndicator: PropTypes.bool,
  innerHeader: PropTypes.bool,
}

Header.defaultProps = {
  status: '',
  title: '',
  icon: '',
  iconSize: 18,
  tooltipMessage: '',
  tooltipKey: null,
  actionButtons: null,
  attachment: null,
  hideRestrictedTeams: false,
  showTeamSelect: true,
  iconStyles: {},
  fontFamily: FONT_FAMILY_BOWER,
  fontSize: '20px',
  showEmptyStatusIndicator: false,
  innerHeader: false,
}

export default Header
