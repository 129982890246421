import { all, call, put, takeLatest, select } from 'redux-saga/effects'
import { createSaga } from '@common/sagaCreator/createSaga'
import { getSharedApi, getTeamWorkspaceApi } from '@common/net'
import { userInfo } from '@generic/selectors'
import { prepareUpdatedData } from '../utils'
import {
  FETCH_TR_DECISION_LOG_DATA,
  UPDATE_TR_DECISION_LOG_DATA,
  FETCH_DECISION_LOG_DATA_PENDING,
  UPDATE_DECISION_LOG_DATA_PENDING,
  ADD_DECISION_LOG_DATA,
  DELETE_DECISION_LOG_DATA_PENDING,
  FETCH_CONTROL_TOWER_DECISION_LOG_DATA,
  UPDATE_CONTROL_TOWER_DECISION_LOG_DATA,
} from '../actions/actionTypes'
import {
  fetchDecisionLogDataSuccess,
  fetchDecisionLogDataFail,
  updateDecisionLogDataSuccess,
  updateDecisionLogDataFail,
  deleteDecisionLogDataSuccess,
  deleteDecisionLogDataFail,
  updateControlTowerDecisionLogDataSuccess,
  fetchControlTowerDecisionLogDataSuccess,
} from '../actions'
import { isNil, omit } from 'lodash'
import { getOr } from 'lodash/fp'
import { showWarningMessage } from '@domain/generic/actions/actions'
import { actionTypes } from '@common/notifications/utils'
import { UpdateLogPayload } from '@views/myImoClient/pages/MyTeamLayout/utils'
import { CustomColumn } from '@common/types/Initiative/shared'

export const fetchDecisionLogData = createSaga(function* fetchDecisionLogData({
  payload,
}: $TSFixMe): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const api = yield getTeamWorkspaceApi()

  try {
    const data = yield call(
      api.request('getDecisions', {
        query: {
          teamId: payload.query.teamId,
          isFull: true,
        },
      }),
    )

    yield put(fetchDecisionLogDataSuccess({ data }))
  } catch (e) {
    yield put(fetchDecisionLogDataFail())
  }
})

export const updateCustomColumn = createSaga(function* updateCustomColumn({
  payload,
}: {
  payload: UpdateLogPayload
}): Generator<any, any, any> {
  const { customColumn, data } = payload
  const column = data.customColumns?.find((column: CustomColumn) => column.columnName === customColumn)

  const body = {
    entityId: data.id,
    type: 'decisionLogItem',
    optionValue: getOr(null, ['option', 'id'], column) || getOr(null, ['option', 'name'], column),
  }

  const api = yield getSharedApi()

  try {
    yield call(
      api.request('updateCustomColumn', {
        query: {
          columnId: column?.id,
        },
        body,
      }),
    )
  } catch (e) {
    const { message } = e as unknown as Error
    yield put(
      showWarningMessage({
        actionType: actionTypes.FAIL,
        errorMessage: message,
      }),
    )
  }
})

export const updateDecisionLogData = createSaga(function* updateDecisionLogData({
  payload,
}: $TSFixMe): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const { customColumn: customColumnName, data, body } = payload
  const api = yield getTeamWorkspaceApi()

  try {
    const column = customColumnName
      ? data.customColumns.find((column: $TSFixMe) => column.columnName === customColumnName)
      : undefined
    const customColumn = column
      ? {
          columnId: column.id,
          entityId: data.id,
          optionValue: !isNil(column?.option?.id)
            ? getOr(null, ['option', 'id'], column)
            : getOr(null, ['option', 'name'], column),
        }
      : null

    yield call(
      api.request('updateDecision', {
        query: {
          teamId: payload.query.teamId,
          decisionId: payload.query.id,
        },
        body: {
          ...body,
          ...(customColumn ? { customColumn } : {}),
        },
      }),
    )
    yield call(fetchDecisionLogData, { payload })

    const user = yield select(userInfo)

    yield put(
      updateDecisionLogDataSuccess(
        prepareUpdatedData({
          id: payload.query.id,
          body: payload.body,
          user,
          sortOrder: ['status', 'dueDate'],
        }),
      ),
    )
  } catch (e) {
    yield put(updateDecisionLogDataFail())
  }
})

export const getTrDecisionLogData = createSaga(function* getTrDecisionLogData(): Generator<
  $TSFixMe,
  $TSFixMe,
  $TSFixMe
> {
  const api = yield getTeamWorkspaceApi()

  try {
    const data = yield call(api.request('getDecisionsForAllTeam'))

    yield put(fetchDecisionLogDataSuccess({ data }))
  } catch (e) {
    yield put(fetchDecisionLogDataFail())
  }
})

export const updateTrDecisionLogData = createSaga(function* updateTrDecisionLogData({
  payload,
}: $TSFixMe): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const api = yield getTeamWorkspaceApi()
  const { customColumn: customColumnName, data, body } = payload

  try {
    const column = customColumnName
      ? data.customColumns.find((column: $TSFixMe) => column.columnName === customColumnName)
      : undefined
    const customColumn = column
      ? {
          columnId: column.id,
          entityId: data.id,
          optionValue: !isNil(column?.option?.id)
            ? getOr(null, ['option', 'id'], column)
            : getOr(null, ['option', 'name'], column),
        }
      : null

    yield call(
      api.request('updateDecisionsForAllTeam', {
        query: {
          teamId: payload.query.teamId,
          decisionId: payload.query.id,
        },
        body: {
          ...body,
          ...(customColumn ? { customColumn } : {}),
        },
      }),
    )

    yield call(getTrDecisionLogData, { payload })

    const user = yield select(userInfo)

    yield put(
      updateDecisionLogDataSuccess(
        prepareUpdatedData({
          id: payload.query.id,
          body: payload.body,
          user,
          sortOrder: ['status', 'team', 'dueDate'],
        }),
      ),
    )
  } catch (e) {
    yield put(updateDecisionLogDataFail())
  }
})

export const createDecisionsLogData = createSaga(function* createDecisionsLogData({
  payload,
}: $TSFixMe): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const { teamId, data } = payload

  if (!data.isNew) {
    return
  }

  const api = yield getTeamWorkspaceApi()
  const response = yield call(
    api.request('createDecision', {
      query: { teamId },
      body: omit(data, 'isNew', 'id', 'team'),
    }),
  )

  yield put(
    updateDecisionLogDataSuccess({
      id: data.id,
      body: response,
      sortOrder: ['status', 'dueDate'],
    }),
  )
})

export const deleteDecision = createSaga(function* deleteDecision({
  payload,
}: $TSFixMe): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const {
    teamId,
    data: { isNew, id: decisionId },
  } = payload

  if (!isNew) {
    try {
      const api = yield getTeamWorkspaceApi()
      yield call(api.request('deleteDecision', { query: { teamId, decisionId } }))
    } catch (e) {
      yield put(deleteDecisionLogDataFail(e))
    }
  }

  yield put(deleteDecisionLogDataSuccess(decisionId))
})

export const getControlTowerDecisionLogData = createSaga(function* getControlTowerDecisionLogData(): Generator<
  $TSFixMe,
  $TSFixMe,
  $TSFixMe
> {
  const api = yield getTeamWorkspaceApi()

  const data = yield call(api.request('getControlTowerDecision'))

  yield put(fetchControlTowerDecisionLogDataSuccess({ data }))
})

export const updateControlTowerDecisionLogData = createSaga(function* updateControlTowerDecisionLogData({
  payload,
}: $TSFixMe): Generator<$TSFixMe, $TSFixMe, $TSFixMe> {
  const api = yield getTeamWorkspaceApi()
  const { customColumn: customColumnName, data, body } = payload

  const column = customColumnName
    ? data.customColumns.find((column: $TSFixMe) => column.columnName === customColumnName)
    : undefined
  const customColumn = column
    ? {
        columnId: column.id,
        entityId: data.id,
        optionValue: !isNil(column?.option?.id)
          ? getOr(null, ['option', 'id'], column)
          : getOr(null, ['option', 'name'], column),
      }
    : null

  yield call(
    api.request('updateControlTowerDecision', {
      query: {
        teamId: payload.query.teamId,
        decisionId: payload.query.id,
      },
      body: {
        ...body,
        ...(customColumn ? { customColumn } : {}),
      },
    }),
  )

  yield call(getControlTowerDecisionLogData, { payload })

  const user = yield select(userInfo)

  yield put(
    updateControlTowerDecisionLogDataSuccess(
      prepareUpdatedData({
        id: payload.query.id,
        body: payload.body,
        user,
        sortOrder: ['status', 'team', 'dueDate'],
      }),
    ),
  )
})

export default function* decisionLogSaga() {
  yield all([
    takeLatest(FETCH_TR_DECISION_LOG_DATA, getTrDecisionLogData),
    takeLatest(UPDATE_TR_DECISION_LOG_DATA, updateTrDecisionLogData),
    takeLatest(FETCH_DECISION_LOG_DATA_PENDING, fetchDecisionLogData),
    takeLatest(UPDATE_DECISION_LOG_DATA_PENDING, updateDecisionLogData),
    takeLatest(ADD_DECISION_LOG_DATA, createDecisionsLogData),
    takeLatest(DELETE_DECISION_LOG_DATA_PENDING, deleteDecision),
    takeLatest(FETCH_CONTROL_TOWER_DECISION_LOG_DATA, getControlTowerDecisionLogData),
    takeLatest(UPDATE_CONTROL_TOWER_DECISION_LOG_DATA, updateControlTowerDecisionLogData),
  ])
}
