import React from 'react'
import {
  find,
  findKey,
  flow,
  get,
  isEmpty,
  isFunction,
  isNil,
  isNumber,
  isString,
  map,
  sortBy,
  upperFirst,
  getOr,
} from 'lodash/fp'
import { combineGridValidators } from '@helpers/gridValidations'
import { lengthValidation } from '@helpers/validators'
import { getDateIgnoreTimezone, getMinDate, getMomentDateWithoutTimezone } from '@shared/Grid/utils/dates'
import { getInitialMonthForDates, ProcessStatus, StatusCellEditor } from '@imo/imo-ui-toolkit'
import {
  BLANKS_FILTER_LABEL,
  SPECIAL_ATTRIBUTES,
  STAGE_GATE_IDS,
  stageGateOptions,
  synergyTypes,
} from '@helpers/constants'
import { INITIATIVE_STATUS_BASED_ON } from '@helpers/moduleOptions'
import { fieldNames, requiredFieldEmptyError, synergyTypesCustomParamsMapping } from './constants'
import { formatEmptyNumberValue, getDisplayNumberCurrency, ROUNDING_PARAMS } from '@shared/Currency/utils'
import { checkIsStageGateReady } from '@myImoClient/components/TeamValueCaptureV2/InitiativeList/AsidePanel/StageGateTab/StageGatePanel/utils'
import { round, toNumber } from 'lodash'
import { CONFIDENTIAL_OPTIONS_LIST } from '@myImoClient/pages/MyTeamLayout/TeamValueCaptureV2/TeamValueCaptureController/constants'
import { getWarningAlertProps } from './components/impactWarningAlertUtils'

export const riskOptions = [
  { label: 'Low', value: 'Low' },
  { label: 'High', value: 'High' },
]

const checkCostFTE = (data) => {
  if (data.synergyType === synergyTypes.COST) return !isNil(data.FTE)

  return true
}

export const lookupValue = (categoriesList, code) => {
  const value = categoriesList.find((category) => category.id === +code)

  return get('name', value)
}

export const impactTypeSetter = ({ newValue, data }) => {
  data.netImpactItem.impactType = newValue || null

  return true
}

export const getImpactTypeOptions = () => [
  { value: 'Cost', label: 'Cost' },
  { value: 'Revenue', label: 'Revenue' },
]

export const categorySetter = ({ newValue, data, context }) => {
  const categories = get('categories', context)

  const categoryId = newValue
  const label = lookupValue(categories, categoryId)

  const category = newValue
    ? {
        id: categoryId,
        name: label,
      }
    : null

  if (data.netImpactItem) {
    data.netImpactItem.category = category
    data.netImpactItem.categoryId = categoryId

    return true
  }

  data.category = category
  data.categoryId = categoryId

  return true
}

export const mapCategories = (categories) => {
  return categories.map((category) => ({
    label: category.name,
    value: category.id,
  }))
}

export const initiativeNameValueSetter = (params) =>
  combineGridValidators(
    params,
    'name',
    params.oldValue ? [lengthValidation(null, 1, 'This field cannot be empty')] : [],
  )

export const originatingDataGetter = (params) => {
  return Boolean(
    params.data.mirroredInitiatives || (!params.data.parentInitiativeId && !params.data.mirroredInitiatives),
  ).toString()
}

export const towardsTargetGetter = ({ data }) => {
  const impactValue = data.marginImpact || data.impact

  return isEmpty(data.parentInitiative) ? impactValue : data.parentInitiative.mirroredImpact
}

export const l5DateCellEditorParams = ({ data: { plannedL4Date, plannedL5Date }, context }) => {
  const l5MinDate = getMinDate(plannedL5Date, plannedL4Date || context.tenantCreationDate)

  return {
    getMinDate: () => l5MinDate,
    canClearSelection: true,
    initialMonth: getInitialMonthForDates({ date: plannedL5Date, minDate: l5MinDate }),
  }
}

export const forecastL5DateCellEditorParams = ({ data: { forecastL4Date, forecastL5Date }, context }) => {
  const l5MinDate = getMinDate(forecastL5Date, forecastL4Date || context.tenantCreationDate)

  return {
    getMinDate: () => l5MinDate,
    canClearSelection: true,
    initialMonth: getInitialMonthForDates({ date: forecastL5Date, minDate: l5MinDate }),
  }
}

export const l4DateCellEditorParams = ({ data: { plannedL4Date, plannedL5Date }, context }) => {
  const l4MaxDate = plannedL5Date ? getDateIgnoreTimezone(plannedL5Date).toDate() : null
  const l4MinDate = getMinDate(plannedL4Date, context.tenantCreationDate)

  return {
    getMaxDate: () => l4MaxDate,
    getMinDate: () => l4MinDate,
    canClearSelection: true,
    initialMonth: getInitialMonthForDates({ date: plannedL4Date, minDate: l4MinDate, maxDate: l4MaxDate }),
  }
}

export const forecastL4DateCellEditorParams = ({ data: { forecastL4Date, forecastL5Date }, context }) => {
  const l4MaxDate = forecastL5Date ? getDateIgnoreTimezone(forecastL5Date).toDate() : null
  const l4MinDate = getMinDate(forecastL4Date, context.tenantCreationDate)

  return {
    getMaxDate: () => l4MaxDate,
    getMinDate: () => l4MinDate,
    canClearSelection: true,
    initialMonth: getInitialMonthForDates({ date: forecastL4Date, minDate: l4MinDate, maxDate: l4MaxDate }),
  }
}

export const generalL4DateCellEditorParams = ({ l4Date, l5Date, context }) => {
  const l4MaxDate = l5Date ? getDateIgnoreTimezone(l5Date).toDate() : null
  const l4MinDate = getMinDate(l4Date, context.tenantCreationDate)

  return {
    getMaxDate: () => l4MaxDate,
    getMinDate: () => l4MinDate,
    canClearSelection: true,
    initialMonth: getInitialMonthForDates({ date: l4Date, minDate: l4MinDate, maxDate: l4MaxDate }),
  }
}

export const getHeader = ({ synergyType, managementType, getCustomColumnsName, customHeaderNames }) => {
  const header = get(
    [synergyType, 'gridHeader'],
    synergyTypesCustomParamsMapping(getCustomColumnsName, customHeaderNames),
  )

  return isFunction(header) ? header({ managementType }) : header
}

export const getPercentageValue = (params, considerAdminSettings) => {
  const {
    value,
    context: { currencyInstance },
  } = params

  if (!value) return formatEmptyNumberValue(value)

  if (considerAdminSettings) {
    const { digitsAfterPoint } = currencyInstance.getRoundingParams()
    const roundedValue = round(value, digitsAfterPoint)

    return `${currencyInstance.addSeparatorsToNumber(roundedValue)}%`
  }

  return `${value}%`
}

export const isInitiativeWithDependencies = (initiative) => {
  return (
    !isNil(get('linkedProject', initiative)) ||
    !isNil(get('linkedOneTimeCost', initiative)) ||
    !isNil(get('linkedSynergyInitiative', initiative))
  )
}

export const getLinkedEntities = (initiative) => {
  if (!initiative) return null

  const result = {}

  if (initiative.linkedProject) {
    result.linkedProject = get(['linkedProject', 'name'], initiative)
  }

  if (initiative.linkedOneTimeCost) {
    result.linkedOneTimeCost = get(['linkedOneTimeCost', 'name'], initiative)
  }

  if (initiative.linkedSynergyInitiative) {
    result.linkedSynergyInitiative = get(['linkedSynergyInitiative', 'name'], initiative)
  }

  return result
}

export const isRequiredFieldsMissing = (data) => {
  switch (data.synergyType) {
    case synergyTypes.COST:
      return isNil(data.impact) || isNil(data.FTE)

    case synergyTypes.REVENUE:
      return isNil(data.impact) || isNil(data.margin)

    case synergyTypes.NWC:
    case synergyTypes.ONE_TIME_COST:
      return isNil(data.impact)

    default:
      return false
  }
}

export const stageGateEmptyValueValidation = (stageGate, customMessage) => (value, data) => {
  const isStageGateL2Submitted = checkIsStageGateReady(data, stageGate)
  const isValid = !isStageGateL2Submitted || (!isNil(value) && value !== '')

  const message = customMessage || `Value should not be empty`

  return {
    valid: isValid,
    message: isValid ? null : message,
  }
}

export const FTEValueSetter = (params) => {
  const { context, data } = params
  if (context.isAdvancedFinancials && !data.oneTimeBenefit) {
    return combineGridValidators(params, 'FTE', [
      stageGateEmptyValueValidation(STAGE_GATE_IDS.L1, requiredFieldEmptyError),
    ])
  }

  return combineGridValidators(params, 'FTE', [
    stageGateEmptyValueValidation(STAGE_GATE_IDS.L2_SUBMITTED, 'FTE should not be empty'),
  ])
}

// Plan cost impact initiative column
export const impactValueSetter = (params) => {
  const { oldValue: _oldValue, newValue: _newValue, colDef, data, context } = params
  const { field } = colDef
  const { currencyInstance } = context
  const oldValue = getOr(_oldValue, field, _oldValue)
  const safeValue = getOr(_newValue, 'fromClipboard', _newValue)
  const newValue =
    safeValue === '' || safeValue === null
      ? null
      : !isNil(_newValue?.fromClipboard)
      ? safeValue
      : currencyInstance.roundByCurrency(_newValue, true)

  if (oldValue === newValue) return false

  const formattedOldValue = getDisplayNumberCurrency({ value: oldValue, ignoreEmptyValues: false })
  const formattedNewValue = getDisplayNumberCurrency({ value: newValue, ignoreEmptyValues: false })

  if (context.isAdvancedFinancials && !data.oneTimeBenefit) {
    const isValid = combineGridValidators({ ...params, newValue }, 'impact', [
      stageGateEmptyValueValidation(STAGE_GATE_IDS.L1, requiredFieldEmptyError),
    ])

    if (!isValid) return false

    if (data.hasBeenReset && formattedOldValue && checkCostFTE(data)) {
      context.setChangesToApprove({
        ...data,
        field,
        [field]: newValue,
        alertProps: getWarningAlertProps({
          synergyType: data.synergyType,
          context,
          oldValue: formattedOldValue,
          newValue: formattedNewValue,
        }),
        [`${field}_old`]: oldValue,
      })

      return false
    }

    return true
  }

  return combineGridValidators({ ...params, newValue }, 'impact', [
    stageGateEmptyValueValidation(STAGE_GATE_IDS.L2_SUBMITTED, 'Impact should not be empty'),
  ])
}

// Plan margin impact
export const marginImpactValueSetter = (field) => (params) => {
  const { oldValue: _oldValue, newValue: _newValue, data, context } = params
  const { currencyInstance } = context
  const oldValue = getOr(_oldValue, field, _oldValue)
  const newValue = _newValue === '' || _newValue === null ? null : currencyInstance.roundByCurrency(_newValue, true)

  const formattedOldValue = getDisplayNumberCurrency({ value: oldValue, ignoreEmptyValues: false })
  const formattedNewValue = getDisplayNumberCurrency({ value: newValue, ignoreEmptyValues: false })

  if (oldValue === newValue) return false

  const isValid =
    context.isAdvancedFinancials && !data.oneTimeBenefit
      ? combineGridValidators({ ...params, newValue }, field, [
          stageGateEmptyValueValidation(STAGE_GATE_IDS.L1, requiredFieldEmptyError),
        ])
      : combineGridValidators({ ...params, newValue }, field, [
          stageGateEmptyValueValidation(STAGE_GATE_IDS.L2_SUBMITTED, 'Impact should not be empty'),
        ])

  if (context.isAdvancedFinancials && data.hasBeenReset && isValid && formattedOldValue) {
    context.setChangesToApprove({
      ...data,
      field,
      [field]: newValue,
      alertProps: getWarningAlertProps({
        synergyType: data.synergyType,
        context,
        oldValue: formattedOldValue,
        newValue: formattedNewValue,
      }),
      [`${field}_old`]: oldValue,
    })

    return false
  }

  if (!isValid) return false

  if (newValue === '' || newValue === null) {
    data[field] = null

    return true
  }

  data[field] = newValue

  return true
}

export const forecastDateValueSetter = (props) => {
  const { newValue, colDef, data } = props
  const { field } = colDef
  data[field] = isNil(newValue) ? newValue : getMomentDateWithoutTimezone(new Date(newValue))

  return true
}

export const forecastDateValueGetter = (props) => {
  const { colDef, data } = props
  const { field } = colDef

  return data[field] ? getDateIgnoreTimezone(data?.[field]).toDate() : null
}

export const l4PlannedDateValueSetter = (props) => {
  const { newValue, colDef, data, context } = props
  const { field } = colDef

  if (context.isAdvancedFinancials && !data.oneTimeBenefit) {
    if (checkIsStageGateReady(data, STAGE_GATE_IDS.L1)) {
      const isValid = combineGridValidators(
        props,
        field,
        props.oldValue ? [lengthValidation(null, 1, requiredFieldEmptyError)] : [],
      )

      if (!isValid) return false
    }

    // once populate hasBeenReset becomes true, so hasBeenReset is used to determine if the initiative has been populated
    const isPopulated = data.hasBeenReset

    if (isPopulated && field === fieldNames.L4_DATE) {
      const isL2ApprovedOrHigher = checkIsStageGateReady(data, STAGE_GATE_IDS.L2_APPROVED)

      context.showDay4Alert(isL2ApprovedOrHigher)
    }
  } else if (
    checkIsStageGateReady(data, STAGE_GATE_IDS.L2_APPROVED) &&
    field === fieldNames.L4_DATE &&
    !data.oneTimeBenefit
  ) {
    context.setChangesToApprove({ ...data, [field]: newValue })

    return false
  }

  data[field] = newValue

  return true
}

export const marginValueSetter = (params) => {
  return combineGridValidators(params, 'margin', [
    stageGateEmptyValueValidation(STAGE_GATE_IDS.L2_SUBMITTED, 'Margin should not be empty'),
  ])
}

export const getOtcUrl = (teamId) => `/dashboard/team/${teamId}/team-value-capture/initiative-list/otc`

export const xlsUrlDataTypes = {
  simple: 'Simple',
  financials: 'Financial',
  advancedFinancial: 'AdvancedFinancial',
  advancedUsers: 'AdvancedUsers',
}

export const getFilteredAttachmentsZipFileUrl = (teamId) =>
  `teams/${teamId}/value-capture/v2/initiatives-v2/filter-attachments`

export const getXlsInitiativesFileUrl = (teamId) =>
  `teams/${teamId}/value-capture/v2/initiatives-v2/export/excel/filter`

export const getCsvFileUrl = (teamId, date) =>
  `teams/${teamId}/value-capture/v2/initiatives-v2/export/csv?exportDate=${date}`

export const getUploadFileUrl = (teamId) =>
  `teams/${teamId}/value-capture/v2/initiatives-v2/export/initiative-actuals-template`

export const checkIsOneTimeCost = (synergyType) => synergyType === synergyTypes.ONE_TIME_COST

export const getCustomColumnName = (columnName, columns) =>
  columns.reduce((acc, item) => {
    if (item.name === columnName) acc = item.name

    return acc
  }, null)

export const commonVCColumnNames = [
  'value capture initiative',
  'id',
  'category',
  'owners',
  'status',
  'stage gate',
  'l4 date (plan)',
  'l5 date (plan)',
  'one-time cost',
  'confidential initiative',
  'forecasted l4 date',
  'forecasted l5 date',
  'description',
  'linked one-time cost',
  'linked project',
  'comments on status',
  'cost impact',
  'fte',
  'revenue impact',
  'margin (%)',
  'margin impact',
  'nwc impact',
]

export const getInitiativeXLSColumnsToExclude = (isAdvancedFinancials) => {
  if (isAdvancedFinancials) return ['linkedOneTimeCost.name', 'linkedOneTimeCost.impact']

  return [
    SPECIAL_ATTRIBUTES,
    fieldNames.FORECAST_COST,
    fieldNames.FORECAST_FTE,
    fieldNames.FORECAST_MARGIN,
    fieldNames.FORECAST_REVENUE,
  ]
}

export const addFilter = (columnDefs, filters) => {
  if (isNil(filters)) return columnDefs

  columnDefs.forEach((column) => {
    switch (column.field) {
      case 'listId': {
        const filterValues = filters['listIds']

        if (filterValues) column.filterParams = { values: filterValues }

        break
      }
      case 'status': {
        const filterValues = filters['statuses']

        if (filterValues) column.filterParams.values = filterValues.map((label) => label)

        break
      }
      case SPECIAL_ATTRIBUTES: {
        const filterValues = filters['specialAttributes']

        if (filterValues) {
          column.filterParams = {
            values: filterValues
              .map((label) => label ?? BLANKS_FILTER_LABEL)
              .sort((a, b) => {
                if (a === b) return 0
                if (a === BLANKS_FILTER_LABEL) return -1
                if (b === BLANKS_FILTER_LABEL) return 1
                return a < b ? -1 : 1
              }),
          }
        }

        break
      }
      case 'stageGate.id': {
        const filterValues = filters['stageGates']

        if (filterValues) {
          column.filterParams.values = filterValues.map((value) =>
            flow([find(({ label }) => label === value), get('id')])(stageGateOptions),
          )
        }

        break
      }
      default:
        if (filters.customColumns && column.customColumnId) {
          const filterValues = flow([
            get('customColumns'),
            find({ dictionaryId: column.customColumnId }),
            get('values'),
            map((value) => (value === null ? BLANKS_FILTER_LABEL : value)),
            sortBy((a, b) => {
              if (a === b) return 0

              if (a === BLANKS_FILTER_LABEL) return -1

              if (b === BLANKS_FILTER_LABEL) return 1

              return a < b ? -1 : 1
            }),
          ])(filters)

          if (filterValues) column.filterParams.values = filterValues
        }
    }
  })
}

const filterFieldMap = {
  'category.name': 'category',
  'Special attributes': 'specialAttributes',
  forecastCost: 'forecastImpact',
  owners: 'owner',
  'stageGate.id': 'stageGate',
  plannedL4Date: 'l4date',
  plannedL5Date: 'l5date',
  FTE: 'fte',
  forecastFTE: 'forecastFte',
  forecastMargin: 'forecastMarginImpact',
}

export const getSortingForRequest = (sorting, customColumns) => {
  let sort = null

  if (sorting?.length > 0) {
    for (let i = 0; i < sorting.length; i++) {
      const colId = sorting[i].colId
      const fieldName = filterFieldMap[colId] || colId
      const isCustom = Boolean(getCustomColumnName(fieldName, customColumns))

      sort = { fieldName, sortBy: sorting[i].sort, custom: isCustom }
    }
  }

  return sort
}

export const getFilterForRequest = (filter, customColumns) => {
  if (isEmpty(filter)) return {}

  const requestFilter = {}

  Object.keys(filter).forEach((key) => {
    const customFieldId = flow(find({ name: key }), get('id'))(customColumns)

    if (customFieldId) {
      requestFilter[customFieldId] = { ...filter[key], custom: true }
    } else {
      requestFilter[filterFieldMap[key] || key] = filter[key]
    }
  })

  return requestFilter
}

export const getFilterForTable = (filter, customColumns) => {
  if (isEmpty(filter)) return {}

  const requestFilter = {}

  Object.keys(filter).forEach((key) => {
    const customFieldId = flow(find({ id: toNumber(key) }), get('name'))(customColumns)

    if (customFieldId) {
      requestFilter[customFieldId] = { ...filter[key], custom: true }
    } else {
      const customKey = findKey((customKey) => customKey === key, filterFieldMap)

      requestFilter[customKey || key] = filter[key]
    }
  })

  return requestFilter
}

const needCurrencyTransform = (field) =>
  ['impact', 'forecastCost', 'forecastRevenue', 'marginImpact', 'forecastMargin'].includes(field)

export const normalizeCurrencyToFilter = ({ filter, currencyRounding }) => {
  if (isEmpty(filter)) return {}

  const { denominator = 100 } = ROUNDING_PARAMS[currencyRounding] || {}

  const requestFilter = {}

  const normalize = (filter, field) => {
    let valueNumber = Number(filter[field])

    return isNumber(valueNumber) ? valueNumber / denominator : filter[field]
  }

  Object.keys(filter).forEach((key) => {
    if (needCurrencyTransform(key)) {
      requestFilter[key] = {
        ...filter[key],
        filter: normalize(filter[key], 'filter'),
      }

      if (filter[key].type === 'inRange') {
        requestFilter[key].filterTo = normalize(filter[key], 'filterTo')
      }

      return
    }

    requestFilter[key] = filter[key]
  })

  return requestFilter
}

export const getDisplayingConfidentialValue = (value) => {
  const textValue = flow(find({ value }), get('text'))(CONFIDENTIAL_OPTIONS_LIST)
  const defaultValue = 'All'

  return isEmpty(textValue) || value === defaultValue ? '' : `${textValue} `
}

export const isMirroredInitiative = (selectedInitiative) => {
  const { parentInitiative, mirroredInitiatives } = selectedInitiative

  return Boolean(parentInitiative) && isEmpty(mirroredInitiatives)
}

const createSynergyInPeriodFieldNames = (field, dayOneYear, financialYearsToShow) => {
  return Array.from(Array(financialYearsToShow - 1).keys()).reduce((acc, index) => {
    const year = index + 1
    let fieldType = ''
    if (field === 'impact') fieldType = 'ImpactInPeriod'

    if (field === 'fte') fieldType = 'FTE'

    if (field === 'revenue') fieldType = 'RevenueInPeriod'

    if (field === 'margin') fieldType = 'MarginInPeriod'

    const inPeriodType = field === 'fte' ? 'FTE' : 'in-period'

    const fields = [
      {
        field: `year${year}${fieldType}Planned`,
        headerName: `${Number(dayOneYear) + index} ${inPeriodType} planned`,
      },
      {
        field: `year${year}${fieldType}Forecast`,
        headerName: `${Number(dayOneYear) + index} ${inPeriodType} forecast`,
      },
    ]

    acc.push(fields)

    return acc
  }, [])
}

export const synergyInPeriodFieldList = (dayOneYear, financialYearsToShow) => ({
  impact: createSynergyInPeriodFieldNames('impact', dayOneYear, financialYearsToShow),
  fte: createSynergyInPeriodFieldNames('fte', dayOneYear, financialYearsToShow),
  revenue: createSynergyInPeriodFieldNames('revenue', dayOneYear, financialYearsToShow),
  margin: createSynergyInPeriodFieldNames('margin', dayOneYear, financialYearsToShow),
})

export const checkIsFilterValid = ({ filter, columnDefs }) => {
  const filterObject = filter.filter
  const savedUserFiltersNames = Object.keys(filterObject)
  const errorNames = []

  savedUserFiltersNames.forEach((columnField) => {
    const column = find({ field: columnField }, columnDefs)
    const savedFilterType = filterObject[columnField].filterType

    if (isNil(column)) {
      errorNames.push(columnField)

      return
    }

    if (column.customField !== fieldNames.CUSTOM_COLUMN) return

    // TODO: get rid of condition column.cellEditor !== 'inputCellEditor'
    const savedTextFilterDontMatchColumn =
      savedFilterType === 'text' && column.cellEditor.render.name !== 'InputCellEditor'
    const savedSelectFilterDontMatchColumn = savedFilterType === 'set' && column.cellEditor !== 'agRichSelectCellEditor'

    if (savedTextFilterDontMatchColumn || savedSelectFilterDontMatchColumn) {
      errorNames.push(columnField)
    }
  })

  return isEmpty(errorNames)
}

export const statusColumnValueSetter = ({ newValue, data }) => {
  data.status = newValue === ProcessStatus.ON_HOLD ? ProcessStatus.ON_HOLD : null

  return true
}

export const getStatusCellEditorParams = ({ data }) => ({
  values: data.status === ProcessStatus.ON_HOLD ? ['Active'] : [ProcessStatus.ON_HOLD],
  cellRenderer: (props) => {
    if (isString(props.value)) return upperFirst(props.value)

    return <StatusCellEditor value={props.value} />
  },
})

export const getStatusColumnTooltip = (statusBasedOn, managementType) => {
  const forecastedDateTooltip = `The status for initiatives in L1 - L2 is defined based on ${managementType} Deliverables date. For initiatives in L3, the status is defined based on the forecasted L4 date. And for initiatives in L4, the status is defined based on forecasted L5 date.`
  const dueDateTooltip = `The status for initiatives in L1 - L2 is defined based on ${managementType} Deliverables date. For initiatives in L3, the status is defined based on L4 due date. And for initiatives in L4, the status is defined based on L5 due date.`

  return statusBasedOn === INITIATIVE_STATUS_BASED_ON.FORECAST_DATE ? forecastedDateTooltip : dueDateTooltip
}
