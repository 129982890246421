import { EntityType, EntityName, NotificationEntityTypes } from '@helpers/constants'

export const entityNameMap: Record<EntityType, EntityName> = {
  ValueCaptureInitiativeV2: 'Initiative',
  TeamKeyProcessL2Project: 'Project',
  TeamKeyProcessL2ProjectTask: 'Task',
  TSADeliverableV2: 'TSADeliverableV2',
  InterdependencyTask: 'Incoming Interdependency',
  OutgoingInterdependencyTask: 'Outgoing Interdependency',
}

export const entityValueMap: Record<EntityName, EntityType> = {
  Initiative: NotificationEntityTypes.ValueCaptureInitiativeV2,
  Project: NotificationEntityTypes.TeamKeyProcessL2Project,
  Task: NotificationEntityTypes.TeamKeyProcessL2ProjectTask,
  'Incoming Interdependency': NotificationEntityTypes.InterdependencyTask,
  'Outgoing Interdependency': NotificationEntityTypes.OutgoingInterdependencyTask,
  TSADeliverableV2: NotificationEntityTypes.TSADeliverableV2,
}
