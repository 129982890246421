import HttpClient from '../HttpClient'
import Config from '@root/src/config'
import { getFeatureFlags } from '@views/hooks/useFeatureFlags'
import { DayOneProjectListDto } from '@common/types/dtos/DayOneProjectListDto'

//TODO create separate APIs files for each page (My team, Weekly status update etc.)

interface GenericParams {
  offset?: $TSFixMe
  limit?: $TSFixMe
  type: string
}

export default class MyTeamApi extends HttpClient {
  constructor(fetch: $TSFixMe) {
    super(fetch, '')

    this.host = Config.API_SERVER
  }

  getProjectPlanList = ({ query }: $TSFixMe): Promise<DayOneProjectListDto> => {
    const { teamId, offset, limit, withoutInterdependencies, isTsaExitPlans } = query
    const queryParams = (
      isTsaExitPlans
        ? { type: 'plain' }
        : {
            include: withoutInterdependencies ? '["tasks-l2"]' : '["tasks-l2", "interdependencies"]',
            type: 'plain',
          }
    ) as GenericParams

    if (offset && limit) {
      queryParams.offset = offset
      queryParams.limit = limit
    }

    return this.call({
      pathname: `teams/${teamId}/team-key-process-v2/key-processes-v2`,
      query: { ...queryParams },
    })
  }

  getProgramProjectList = ({ query }: $TSFixMe): Promise<DayOneProjectListDto> => {
    const { teamId, offset, limit, isTsaExitPlans } = query
    const programProjectPlanQueryParams = { excludeKeyProcesses: true, excludeProjectMirrors: true }
    const { ALL_PROJECTS_SERVER_SIDE } = getFeatureFlags()

    const queryParams = (isTsaExitPlans ? { type: 'plain' } : programProjectPlanQueryParams) as GenericParams & {
      newQuery?: boolean
    }

    if (offset && limit) {
      queryParams.offset = offset
      queryParams.limit = limit
    }

    queryParams.newQuery = true

    if (ALL_PROJECTS_SERVER_SIDE?.enabled) {
      return this.call({
        pathname: `teams/${teamId}/team-key-process-v2/imo-program-project-plan-v3`,
      })
    }

    return this.call({
      pathname: `teams/${teamId}/team-key-process-v2/imo-program-project-plan-v2`,
      query: queryParams,
    })
  }

  getTeamWeeklyStatus = ({ query }: $TSFixMe) => {
    return this.call({
      pathname: `teams/${query.teamId}/weekly-status`,
    })
  }

  updateTeamWeeklyStatus = ({ body, query }: $TSFixMe) => {
    return this.call(
      { pathname: `teams/${query.teamId}/weekly-status` },
      {
        method: 'PATCH',
        body,
      },
    )
  }

  getKeyIssues = ({ query }: $TSFixMe) => {
    return this.call({
      pathname: `teams/${query.teamId}/key-issues`,
    })
  }

  createKeyIssue = ({ body, query }: $TSFixMe) => {
    return this.call(
      { pathname: `teams/${query.teamId}/key-issues` },
      {
        method: 'POST',
        body,
      },
    )
  }

  updateKeyIssue = ({ body, query }: $TSFixMe) => {
    return this.call(
      { pathname: `teams/${query.teamId}/key-issues/${query.keyIssueId}` },
      {
        method: 'PATCH',
        body,
      },
    )
  }

  deleteKeyIssue = ({ query }: $TSFixMe) => {
    return this.call(
      { pathname: `teams/${query.teamId}/key-issues/${query.keyIssueId}` },
      {
        method: 'DELETE',
      },
    )
  }

  getDeliverableTypesStatuses = ({ query }: $TSFixMe) => {
    return this.call({ pathname: `teams/${query.teamId}/deliverable-types` })
  }

  getTeamProjectsStatuses = ({ query }: $TSFixMe) => {
    return this.call({ pathname: `teams/${query.teamId}/project-status/v2` })
  }

  getFlaggedRisksAndDecisions = ({ query }: $TSFixMe) => {
    return this.call({ pathname: `teams/${query.teamId}/flagged-risk-decision-logs` })
  }

  getOtherTeamUpdates = ({ query }: $TSFixMe) => {
    return this.call({ pathname: `teams/${query.teamId}/other-team-updates` })
  }

  createOtherTeamUpdate = ({ body, query }: $TSFixMe) => {
    return this.call(
      {
        pathname: `teams/${query.teamId}/other-team-updates`,
      },
      {
        method: 'POST',
        body,
      },
    )
  }

  updateOtherTeamUpdate = ({ query, body }: $TSFixMe) => {
    return this.call(
      {
        pathname: `teams/${query.teamId}/other-team-updates/${query.id}`,
      },
      {
        method: 'PATCH',
        body,
      },
    )
  }

  deleteOtherTeamUpdate = ({ query }: $TSFixMe) => {
    return this.call(
      {
        pathname: `teams/${query.teamId}/other-team-updates/${query.id}`,
      },
      {
        method: 'DELETE',
      },
    )
  }

  submitWeeklyStatusUpdate = ({ query }: $TSFixMe) => {
    return this.call(
      {
        pathname: `teams/${query.teamId}/weekly-status/update`,
      },
      {
        method: 'PATCH',
        body: {},
      },
    )
  }

  getKeyProcessV2DeleteDetails = ({ query }: $TSFixMe) => {
    const { ids, teamId } = query

    return this.call({
      pathname: `/teams/${teamId}/team-key-process-v2/delete-details`,
      query: { ids },
    })
  }
}
