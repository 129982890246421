import styled from 'styled-components'

import MyImoFestiveLogo from '@assets/img/logo/sider-logo-festive.png'
// @ts-expect-error allow svg imports
import MyImoLogo from '@assets/img/logo/sider-logo.svg'
// @ts-expect-error allow svg imports
import MyImoLogoBlack from '@assets/img/logo/sider-logo-black.svg'

export const Logo = styled.div`
  background-image: url(${MyImoLogo});
  background-attachment: unset;
  background-repeat: no-repeat;
  background-position: top;
  background-size: contain;
  width: 25px;
  height: 25px;
  margin: 16px 11px 0;
`

export const LogoBlack = styled.div`
  background-image: url(${MyImoLogoBlack});
  background-attachment: unset;
  background-repeat: no-repeat;
  background-position: top;
  background-size: contain;
  width: 125px;
  margin: 36px 21px 0 5px;
`

export const FestiveLogo = styled.div`
  background-image: url(${MyImoFestiveLogo});
  background-attachment: unset;
  background-repeat: no-repeat;
  background-position: top;
  background-size: contain;
  width: 25px;
  height: 25px;
  margin: 16px 11px 0;
`
