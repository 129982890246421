import styled from 'styled-components'
import myImoLogo from '@assets/img/logo/welcomeNote/logo@3x.png'
import mySmoLogo from '@assets/img/logo/welcomeNote/logoSmo.png'
import { Button, Text } from '@blueprintjs/core'
import { Popup, FONT_FAMILY, white, black } from '@imo/imo-ui-toolkit'

export const Container = styled.div``

export const LogoContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`

export const LogoPrefix = styled.div`
  font-family: ${FONT_FAMILY};
  font-size: 30px;
  font-weight: 300;
  color: ${black};
  margin: 0 20px;
  line-height: 2em;
`

const Logo = styled.div`
  position: relative;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 201px;
  height: 55px;
  width: 201px;
`

export const ImoLogo = styled(Logo)`
  background-image: url(${myImoLogo});
`

export const SmoLogo = styled(Logo)`
  background-image: url(${mySmoLogo});
`

export const AreaContainer = styled.div`
  margin: 15px 0;
  padding: 0 15px;
  width: 100%;
`

export const AreaHeader = styled.div`
  font-size: 12px;
  font-weight: 500;
  color: ${black};
  padding: 3px 0;
  font-family: ${FONT_FAMILY};
`

export const StyledTextarea = styled(Text)`
  white-space: break-spaces;
  width: 100%;
  height: 170px;
  overflow-x: hidden;
  overflow-y: auto;
  border-color: ${white};
  border-radius: 3px;
  box-shadow: inset 0 1px 1px 0 rgba(16, 22, 26, 0.2), inset 0 0 0 1px rgba(16, 22, 26, 0.15);
  font-size: 12px;
  padding: 5px;
  font-family: ${FONT_FAMILY};
  ${(props) => props.customStyles}
`

export const ActionsContainer = styled.div`
  display: flex;
  flex-flow: column wrap;
  width: 100%;
  padding: 0 15px;
  font-size: 12px;
  font-weight: 300;

  &.accept-block {
    font-family: ${FONT_FAMILY};
    font-weight: 300;
    margin: 20px 0;
    height: 30px;
  }

  .checkbox {
    margin-top: 4px;
  }

  &:last-child {
    padding: 0 15px;
  }

  .bp4-control {
    margin-bottom: 0;
  }
`

export const AcceptButton = styled(Button)`
  width: 86px;
  align-self: flex-end;
  font-size: 14px;
`

export const StyledPopup = styled(Popup)`
  width: 1000px;
  max-height: 90vh;
  overflow-y: auto;
`
