import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { getSelectedCentralOrgDesignTeamId } from '@generic/selectors/selectedTeams'
import NoResults from '@shared/NoResults'
import { Icon } from '@blueprintjs/core'
import { useFeatureFlags } from '@views/hooks/useFeatureFlags'
import { Outlet, useMatch, useNavigate } from 'react-router-dom'

const OrgDesignTrackerRoot = () => {
  const centralTeam = useSelector(getSelectedCentralOrgDesignTeamId)
  const { isEnabled } = useFeatureFlags()
  const isODTSummaryStatusEnabled = isEnabled('ODT_SUMMARY_STATUS')
  const isRoot = useMatch('/org-design-tracker')
  const navigate = useNavigate()

  useEffect(() => {
    const pathname = isODTSummaryStatusEnabled
      ? `/org-design-tracker/team/${centralTeam}/dashboard`
      : `/org-design-tracker/team/${centralTeam}/setup`
    if (centralTeam && isRoot) {
      navigate(pathname)
    }
  }, [isRoot, centralTeam, navigate])

  if (!centralTeam) {
    const content = (
      <>
        <Icon icon={'warning-sign'} size={23} />
        <br />
        The Org Design Tracker is currently unavailable.
        <br />
        There are no central teams classified as Org Design and Talent in the admin configuration.
      </>
    )

    return <NoResults content={content} />
  }

  return <Outlet />
}

export default OrgDesignTrackerRoot
