import React from 'react'
import { StyledHeaderTitle, Title, Question } from './HeaderTitle.styles'
import InstructionsTooltip from '@shared/InstructionsTooltip'

interface Props {
  tooltipMessage?: string | React.ReactElement | React.ReactElement[]
  tooltipKey?: string
  title?: string
  attachment?: $TSFixMe
  fontFamily?: string
  fontSize?: string
}

const HeaderTitle = ({ title, tooltipMessage, tooltipKey, attachment, fontFamily, fontSize }: Props) => {
  return (
    <StyledHeaderTitle>
      <Title $fontFamily={fontFamily} $fontSize={fontSize}>
        {title}
      </Title>
      {(!!tooltipMessage || !!attachment || !!tooltipKey) && (
        <Question>
          <InstructionsTooltip
            tooltipMessage={tooltipMessage}
            tooltipKey={tooltipKey}
            attachment={attachment}
            iconProps={{ size: 16 }}
          />
        </Question>
      )}
    </StyledHeaderTitle>
  )
}

HeaderTitle.defaultProps = {
  tooltipMessage: null,
  tooltipKey: null,
  title: '',
  attachment: null,
}

export default HeaderTitle
