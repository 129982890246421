import { flow, get, getOr, map, orderBy, sortBy } from 'lodash/fp'
import { YEAR_ONE } from '@myImoClient/components/VCTrackerV2/TeamSetup/constants'
import { getDateIgnoreTimezone, getNextYears } from '@shared/Grid/utils/dates'
import moment from 'moment'
import { CalendarViewOption } from '@domain/myImoConfig/moduleOptions/reducer/types.d'

export const getTargetObject = (target: $TSFixMe[]) => {
  if (!target) return {}

  return target.reduce((obj, { year, ...value }) => {
    obj[year] = value

    return obj
  }, {})
}

export const getSortedTargetsListForGrid = flow([
  get('targetList'),
  sortBy([({ team }) => !team, ({ team }) => getOr('', 'longName', team).toLowerCase()]),
  map(({ runRateTarget, inPeriodTarget, ...target }) => ({
    ...target,
    runRateTarget: getTargetObject(runRateTarget),
    inPeriodTarget: getTargetObject(inPeriodTarget),
  })),
])

export const getSortedVCTeamsFromState = flow([
  get('valueCaptureTeams'),
  sortBy(({ longName }) => longName.toLowerCase()),
])

export const getYear = (dayOneDate: string) => {
  const date = getDateIgnoreTimezone(moment.utc(new Date(dayOneDate))).toDate()

  return date.getFullYear()
}

export const getMonthName = (dayOneDate: string, monthFormat: string) => {
  const date = getDateIgnoreTimezone(moment.utc(new Date(dayOneDate)))

  return date.format(monthFormat)
}

export const getFormedYearsList = (dayOneDate: $TSFixMe, fiscalYear: number, calendarView: CalendarViewOption, financialYearsToShow: number) => {
  if (!dayOneDate) return []

  let startYear = getYear(dayOneDate)

  if (calendarView === 'Fiscal year' && fiscalYear > new Date(dayOneDate).getMonth()) {
    startYear = startYear - 1
  }

  return [startYear, YEAR_ONE, ...getNextYears(startYear + 1, financialYearsToShow - 1)]
}

export const sortCategoriesComparator = orderBy(
  [({ name }) => Boolean(name), ({ name }) => (name ? name.toLowerCase() : '')],
  ['desc', 'asc'],
)
