export const en = {
  COMMON: {
    ROLES: {
      IMO: {
        'Team Member (with Value Capture and Org Design)': 'Team Member (with Value Capture and Org Design)',
        'Team Member': 'Team Member (with Value Capture)',
        'Team Member without VC': 'Team Member',
        'Team Member without VC (read-only)': 'Team Member (read-only)',
        Inactive: 'Inactive',
        IMO: 'IMO',
        SMO: 'SMO',
      },
      CENTRAL: {
        'Team Lead': 'Team Lead',
        'Team Member': 'Team Member',
        'Team Member (read-only)': 'Team Member (read-only)',
        'Restricted User': 'Restricted User',
      },
      INTEGRATION: {
        'Team Lead': 'Team Lead',
        'Team Member': 'Team Member',
        'Team Member (with Value Capture and Org Design)': 'Team Member (with Value Capture and Org Design)',
        'Team Member with VC': 'Team Member (with Value Capture)',
        'Team Member without VC': 'Team Member',
        'Team Member without VC (read-only)': 'Team Member (read-only)',
        'Restricted User': 'Restricted User',
        'Restricted User (with Value Capture)': 'Restricted User (with Value Capture)',
      },
      ADMIN: {
        'Config Admin': 'Config Admin',
        'User Admin': 'User Admin',
      },
      UNKNOWN: '',
    },
    ACTIVITY_LOG: {
      TITLE: 'Activity log',
      LOAD_MORE: 'Load more events',
      VIEW_CHANGES: 'View changes',
      CLOSE_BUTTON: 'Close',
      NONE: 'None',
    },
    RISK_LOG: {
      STATUS_HEADER: 'Status',
      RISKS_HEADER: 'Risks',
      DATES_HEADER: 'Dates',
      IMPACT_HEADER: 'Impact',
      PROBABILITY_HEADER: 'Probability',
      STATUS_CHART_CARD_HEADER: 'Status of risks',
      STATUS_BY_DUE_DATE_CHART_CARD_HEADER: 'Number of risks by due date',
      IMPACT_VS_PROBABILITY_CHART_CARD_HEADER: 'Potential impact vs. probability of risks',
      ACTIVITY_LOG: {
        MESSAGES: {
          CREATE: '<strong>{{user}}</strong> has created the <strong>Risk</strong> "{{topic}}"',
          DELETE: '<strong>{{user}}</strong> has deleted the <strong>Risk</strong> "{{topic}}"',
          UPDATE: {
            DEFAULT:
              '<strong>{{user}}</strong> has updated the <strong>{{field}}</strong> for "{{topic}}" from "{{previousValue}}" to "{{currentValue}}"',
            DEFAULT_from_blank:
              '<strong>{{user}}</strong> has updated the <strong>{{field}}</strong> for "{{topic}}" to "{{currentValue}}"',
            DEFAULT_to_blank:
              '<strong>{{user}}</strong> has removed {{previousValue}} from the <strong>{{field}}</strong> for {{topic}}',
            STATUS:
              '<strong>{{user}}</strong> has updated the <strong>Status</strong> from {{previousValue}} to {{currentValue}} for "{{topic}}"',
            TOPIC:
              '<strong>{{user}}</strong> has updated the <strong>Topic</strong> from "{{previousValue}}" to "{{currentValue}}"',
            ISVISIBLETOIMO_flagged: '<strong>{{user}}</strong> has <strong>Flagged</strong> risk "{{topic}}" to IMO',
            ISVISIBLETOIMO_unflagged:
              '<strong>{{user}}</strong> has removed the <strong>Flag to IMO</strong> for "{{topic}}"',
            DUEDATE_from_blank:
              '<strong>{{user}}</strong> has set the <strong>Due date</strong> to {{currentValue}} for risk "{{topic}}"',
            DUEDATE:
              '<strong>{{user}}</strong> has updated the <strong>Due date</strong> from {{previousValue}} to {{currentValue}} for risk "{{topic}}"',
            DUEDATE_to_blank:
              '<strong>{{user}}</strong> has removed the <strong>Due date</strong> of {{previousValue}} for risk "{{topic}}"',
            POTENTIALIMPACT_from_blank:
              '<strong>{{user}}</strong> has set the <strong>Potential impact</strong> for risk "{{topic}}" to "{{currentValue}}"',
            POTENTIALIMPACT:
              '<strong>{{user}}</strong> has updated the <strong>Potential impact</strong> for risk "{{topic}}" from "{{previousValue}}" to "{{currentValue}}"',
            POTENTIALIMPACT_to_blank:
              '<strong>{{user}}</strong> has removed the <strong>Potential impact</strong> for risk "{{topic}}"',
            PROBABILITY_from_blank:
              '<strong>{{user}}</strong> has set the <strong>Probability</strong> for risk "{{topic}}" to "{{currentValue}}"',
            PROBABILITY:
              '<strong>{{user}}</strong> has updated the <strong>Probability</strong> for risk "{{topic}}" from "{{previousValue}}" to "{{currentValue}}"',
            PROBABILITY_to_blank:
              '<strong>{{user}}</strong> has removed the <strong>Probability</strong> "{{previousValue}}" for risk "{{topic}}"',
            LONG_DESCRIPTION_from_blank:
              '<strong>{{user}}</strong> has added the <strong>Description</strong> for risk "{{topic}}"',
            LONG_DESCRIPTION:
              '<strong>{{user}}</strong> has updated the <strong>Description</strong> for risk "{{topic}}"',
            LONG_DESCRIPTION_to_blank:
              '<strong>{{user}}</strong> has removed the <strong>Description</strong> for risk "{{topic}}"',
            DESCRIPTION_from_blank:
              '<strong>{{user}}</strong> has added the <strong>Description</strong> "{{currentValue}}" for risk "{{topic}}"',
            DESCRIPTION:
              '<strong>{{user}}</strong> has updated the <strong>Description</strong> for risk "{{topic}}" from "{{previousValue}}" to "{{currentValue}}"',
            DESCRIPTION_to_blank:
              '<strong>{{user}}</strong> has removed the <strong>Description</strong> "{{previousValue}}" for risk "{{topic}}"',
            OWNER_from_blank:
              '<strong>{{user}}</strong> has assigned {{currentValue}} as the <strong>Team PoC</strong> for risk "{{topic}}"',
            OWNER:
              '<strong>{{user}}</strong> has updated the <strong>Team PoC</strong> from {{previousValue}} to {{currentValue}} for risk "{{topic}}"',
            OWNER_to_blank:
              '<strong>{{user}}</strong> has removed {{previousValue}} as the <strong>Team PoC</strong> for risk "{{topic}}"',
            LONG_MITIGATIONACTIONS_from_blank:
              '<strong>{{user}}</strong> has added the <strong>Mitigation actions</strong> for risk "{{topic}}"',
            LONG_MITIGATIONACTIONS:
              '<strong>{{user}}</strong> has updated the <strong>Mitigation actions</strong> for risk "{{topic}}"',
            LONG_MITIGATIONACTIONS_to_blank:
              '<strong>{{user}}</strong> has removed the <strong>Mitigation actions</strong> for risk "{{topic}}"',
            MITIGATIONACTIONS_from_blank:
              '<strong>{{user}}</strong> has added the <strong>Mitigation actions</strong> "{{currentValue}}" for risk "{{topic}}"',
            MITIGATIONACTIONS:
              '<strong>{{user}}</strong> has updated the <strong>Mitigation actions</strong> for risk "{{topic}}" from "{{previousValue}}" to "{{currentValue}}"',
            MITIGATIONACTIONS_to_blank:
              '<strong>{{user}}</strong> has removed the <strong>Mitigation actions</strong> "{{previousValue}}" for risk "{{topic}}"',
            CONFIDENTIAL: '<strong>{{user}}</strong> has made the risk "{{topic}}" <strong>Confidential</strong>',
            NON_CONFIDENTIAL:
              '<strong>{{user}}</strong> has made the risk "{{topic}}" <strong>Non-confidential</strong>',
            LONG_CUSTOMCOLUMN_from_blank:
              '<strong>{{user}}</strong> has added the <strong>{{customColumnName}}</strong> for risk "{{topic}}"',
            LONG_CUSTOMCOLUMN:
              '<strong>{{user}}</strong> has updated the <strong>{{customColumnName}}</strong> for risk "{{topic}}"',
            LONG_CUSTOMCOLUMN_to_blank:
              '<strong>{{user}}</strong> has removed the <strong>{{customColumnName}}</strong> for risk "{{topic}}"',
            CUSTOMCOLUMN_from_blank:
              '<strong>{{user}}</strong> has added the <strong>{{customColumnName}}</strong> "{{currentValue}}" for risk "{{topic}}"',
            CUSTOMCOLUMN:
              '<strong>{{user}}</strong> has updated the <strong>{{customColumnName}}</strong> for risk "{{topic}}" from "{{previousValue}}" to "{{currentValue}}"',
            CUSTOMCOLUMN_to_blank:
              '<strong>{{user}}</strong> has removed the <strong>{{customColumnName}}</strong> "{{previousValue}}" for risk "{{topic}}"',
          },
        },
      },
    },
    DECISION_LOG: {
      PIE_CHART_CARD_HEADER: 'Status of decisions',
      STATUS_HEADER: 'Status',
      PRIORITY_HEADER: 'Priority',
      MATRIX_CHART_CARD_HEADER: 'Status vs. priority',
      DECISIONS_HEADER: 'Decisions',
      AREA_CHART_CARD_HEADER: 'Number of decisions by due date',
      DATES_HEADER: 'Dates',
      ACTIVITY_LOG: {
        MESSAGES: {
          CREATE: '<strong>{{user}}</strong> has created the <strong>Decision</strong> "{{topic}}"',
          DELETE: '<strong>{{user}}</strong> has deleted the <strong>Decision</strong> "{{topic}}"',
          UPDATE: {
            DEFAULT:
              '<strong>{{user}}</strong> has updated the <strong>{{field}}</strong> for "{{topic}}" from "{{previousValue}}" to "{{currentValue}}"',
            DEFAULT_from_blank:
              '<strong>{{user}}</strong> has updated the <strong>{{field}}</strong> for "{{topic}}" to "{{currentValue}}"',
            DEFAULT_to_blank:
              '<strong>{{user}}</strong> has removed {{previousValue}} from the <strong>{{field}}</strong> for {{topic}}',
            STATUS:
              '<strong>{{user}}</strong> has updated the <strong>Status</strong> from {{previousValue}} to {{currentValue}} for "{{topic}}"',
            TOPIC:
              '<strong>{{user}}</strong> has updated the <strong>Topic</strong> from "{{previousValue}}" to "{{currentValue}}"',
            ISVISIBLETOIMO_flagged:
              '<strong>{{user}}</strong> has <strong>Flagged</strong> decision "{{topic}}" to IMO',
            ISVISIBLETOIMO_unflagged:
              '<strong>{{user}}</strong> has removed the <strong>Flag to IMO</strong> for decision "{{topic}}"',
            DUEDATE_from_blank:
              '<strong>{{user}}</strong> has set the <strong>Due date</strong> to {{currentValue}} for decision "{{topic}}"',
            DUEDATE:
              '<strong>{{user}}</strong> has updated the <strong>Due date</strong> from {{previousValue}} to {{currentValue}} for decision "{{topic}}"',
            DUEDATE_to_blank:
              '<strong>{{user}}</strong> has removed the <strong>Due date</strong> of {{previousValue}} for decision "{{topic}}"',
            OWNER_from_blank:
              '<strong>{{user}}</strong> has assigned {{currentValue}} as the <strong>Team PoC</strong> for decision "{{topic}}"',
            OWNER:
              '<strong>{{user}}</strong> has updated the <strong>Team PoC</strong> from {{previousValue}} to {{currentValue}} for decision "{{topic}}"',
            OWNER_to_blank:
              '<strong>{{user}}</strong> has removed {{previousValue}} as the <strong>Team PoC</strong> for decision "{{topic}}"',
            PRIORITY_from_blank:
              '<strong>{{user}}</strong> has set the <strong>Priority</strong> to "{{currentValue}}" for decision "{{topic}}"',
            PRIORITY:
              '<strong>{{user}}</strong> has updated the <strong>Priority</strong> from "{{previousValue}}" to "{{currentValue}}" for decision "{{topic}}"',
            PRIORITY_to_blank:
              '<strong>{{user}}</strong> has removed the <strong>Priority</strong> "{{previousValue}}" for decision "{{topic}}"',
            PRIORITYID_from_blank:
              '<strong>{{user}}</strong> has set the <strong>Priority</strong> to "{{currentValue}}" for decision "{{topic}}"',
            PRIORITYID:
              '<strong>{{user}}</strong> has updated the <strong>Priority</strong> from "{{previousValue}}" to "{{currentValue}}" for decision "{{topic}}"',
            PRIORITYID_to_blank:
              '<strong>{{user}}</strong> has removed the <strong>Priority</strong> "{{previousValue}}" for decision "{{topic}}"',
            LONG_DESCRIPTION_from_blank:
              '<strong>{{user}}</strong> has added the <strong>Description</strong> for decision "{{topic}}"',
            LONG_DESCRIPTION:
              '<strong>{{user}}</strong> has updated the <strong>Description</strong> for decision "{{topic}}"',
            LONG_DESCRIPTION_to_blank:
              '<strong>{{user}}</strong> has removed the <strong>Description</strong> for decision "{{topic}}"',
            DESCRIPTION_from_blank:
              '<strong>{{user}}</strong> has added the <strong>Description</strong> "{{currentValue}}" for decision "{{topic}}"',
            DESCRIPTION:
              '<strong>{{user}}</strong> has updated the <strong>Description</strong> of decision "{{topic}}" from "{{previousValue}}" to "{{currentValue}}"',
            DESCRIPTION_to_blank:
              '<strong>{{user}}</strong> has removed the <strong>Description</strong> "{{previousValue}}" for decision "{{topic}}"',
            DECISIONMAKER_from_blank:
              '<strong>{{user}}</strong> has assigned {{currentValue}} as the <strong>Decision maker</strong> for "{{topic}}"',
            DECISIONMAKER:
              '<strong>{{user}}</strong> has updated the <strong>Decision maker</strong> from {{previousValue}} to {{currentValue}} for decision "{{topic}}"',
            DECISIONMAKER_to_blank:
              '<strong>{{user}}</strong> has removed {{previousValue}} as the <strong>Decision maker</strong> for decision "{{topic}}"',
            LONG_COMMENTS_from_blank:
              '<strong>{{user}}</strong> has added the <strong>Comments</strong> for decision "{{topic}}"',
            LONG_COMMENTS:
              '<strong>{{user}}</strong> has updated the <strong>Comments</strong> for decision "{{topic}}"',
            LONG_COMMENTS_to_blank:
              '<strong>{{user}}</strong> has removed the <strong>Comments</strong> for decision "{{topic}}"',
            COMMENTS_from_blank:
              '<strong>{{user}}</strong> has added the <strong>Comments</strong> "{{currentValue}}" for decision "{{topic}}"',
            COMMENTS:
              '<strong>{{user}}</strong> has updated the <strong>Comments</strong> for decision "{{topic}}" from "{{previousValue}}" to "{{currentValue}}"',
            COMMENTS_to_blank:
              '<strong>{{user}}</strong> has removed the <strong>Comments</strong> "{{previousValue}}" for decision "{{topic}}"',
            CONFIDENTIAL: '<strong>{{user}}</strong> has made the decision "{{topic}}" <strong>Confidential</strong>',
            NON_CONFIDENTIAL:
              '<strong>{{user}}</strong> has made the decision "{{topic}}" <strong>Non-confidential</strong>',
            LONG_CUSTOMCOLUMN_from_blank:
              '<strong>{{user}}</strong> has added the <strong>{{customColumnName}}</strong> for decision "{{topic}}"',
            LONG_CUSTOMCOLUMN:
              '<strong>{{user}}</strong> has updated the <strong>{{customColumnName}}</strong> for decision "{{topic}}"',
            LONG_CUSTOMCOLUMN_to_blank:
              '<strong>{{user}}</strong> has removed the <strong>{{customColumnName}}</strong> for decision "{{topic}}"',
            CUSTOMCOLUMN_from_blank:
              '<strong>{{user}}</strong> has added the <strong>{{customColumnName}}</strong> "{{currentValue}}" for decision "{{topic}}"',
            CUSTOMCOLUMN:
              '<strong>{{user}}</strong> has updated the <strong>{{customColumnName}}</strong> for decision "{{topic}}" from "{{previousValue}}" to "{{currentValue}}"',
            CUSTOMCOLUMN_to_blank:
              '<strong>{{user}}</strong> has removed the <strong>{{customColumnName}}</strong> "{{previousValue}}" for decision "{{topic}}"',
          },
        },
      },
    },
    ENTITY: {
      // plurals - see https://www.i18next.com/translation-function/plurals
      INTERDEPENDENT_PROJECT_one: 'Interdependent Project',
      INTERDEPENDENT_PROJECT_other: 'Interdependent Projects',
      INTERDEPENDENT_TASK_one: 'Interdependent Task',
      INTERDEPENDENT_TASK_other: 'Interdependent Tasks',
      PROJECT_one: 'Project',
      PROJECT_other: 'Projects',
      TASK_one: 'Task',
      TASK_other: 'Tasks',
      VALUE_CAPTURE_INITIATIVE_one: 'Value capture initiative',
      VALUE_CAPTURE_INITIATIVE_other: 'Value capture initiatives',
      INTERDEPENDENCY_one: 'Interdependency',
      INTERDEPENDENCY_other: 'Interdependencies',
      RISK_one: 'Risk',
      RISK_other: 'Risks',
      TODO_one: 'To-do',
      TODO_other: 'To-dos',
      DECISION_one: 'Decision',
      DECISION_other: 'Decisions',
      HYPERCARE: 'Hypercare',
    },
    PROJECT: {
      START: 'Start',
      END: 'End',
      STATUS: 'Status',
      TIME_FLAG: 'Time flag',
      CATEGORY: 'Category',
      OWNER: 'Owner',
      COMPLETION: 'Completion',
      DUE_DATE: 'Due date',
      START_DATE: 'Start date',
      FORECAST_DUE_DATE: 'Forecast due date',
      FORECAST_DATE: 'Forecast date',
      KEY_PROCESS: 'Key process',
      PRIORITY: 'Priority',
      COUNTERPARTY: 'Counterparty',
      TEAM_NAME: 'Team',
    },
    STATUS: {
      NOT_STARTED: 'Not started',
      ON_TRACK: 'On track',
      AT_RISK: 'At risk',
      DELAYED: 'Delayed',
      COMPLETED: 'Completed',
    },
    INTERDEPENDENCY_STATUS: {
      REJECTED: 'Rejected',
      REQUIRES_DISCUSSION: 'Requires discussion',
      REQUESTED: 'Requested',
      ACCEPTED: 'Accepted',
    },
    RISK_STATUS: {
      ISSUES: 'Issues',
      OPEN: 'Open',
      MITIGATED: 'Mitigated',
    },
    IMPACTS: {
      DAY_1_MODERATE: 'Day 1 - Moderate',
      DAY_1_SIGNIFICANT: 'Day 1 - Significant',
      POST_CLOSE_MODERATE: 'Post-close - Moderate',
      POST_CLOSE_SIGNIFICANT: 'Post-close - Significant',
      NOT_SELECTED: 'Not selected',
    },
    SIMPLE_IMPACTS: {
      HIGH: 'High',
      MEDIUM: 'Medium',
      LOW: 'Low',
    },
    RISK_PROBABILITY: {
      TITLE: 'Probability',
      DESCRIPTION: 'Probability of risk occurring',
      VALUE: {
        VERY_LIKELY: 'Very likely (>90%)',
        LIKELY: 'Likely (60-90%)',
        POSSIBLE: 'Possible (30-60%)',
        UNLIKELY: 'Unlikely (10-30%)',
        HIGHLY_UNLIKELY: 'Highly unlikely (0-10%)',
        NOT_SELECTED: 'Not selected',
      },
      VALUE_PLAIN: {
        VERY_LIKELY: 'Very likely',
        LIKELY: 'Likely',
        POSSIBLE: 'Possible',
        UNLIKELY: 'Unlikely',
        HIGHLY_UNLIKELY: 'Highly unlikely',
      },
      SIMPLE: {
        VALUE: {
          VERY_LIKELY: 'High',
          LIKELY: 'High',
          POSSIBLE: 'Medium',
          UNLIKELY: 'Low',
          HIGHLY_UNLIKELY: 'Low',
        },
      },
    },
    DECISION_STATUS: {
      ISSUES: 'Issues',
      OPEN: 'Open',
      DECIDED: 'Decided',
    },
    HYPERCARELOG_STATUS: {
      OPEN: 'Open',
      RESOLVED: 'Resolved',
      OPEN_LOW: 'Open (Low)',
      OPEN_HIGH: 'Open (High)',
    },
    HYPERCARELOG_CRITICALITY: {
      NOT_SELECTED: 'Not selected',
    },
    TIME_FLAG: {
      LATE_TO_FINISH: {
        LABEL: 'Late to finish',
        DESCRIPTION:
          '{{typeLabel}}s that are either marked as delayed or are incomplete and are past their due date for completion',
      },
      LATE_TO_START: {
        LABEL: 'Late to start',
        DESCRIPTION: '{{typeLabel}}s that have not yet been started and are past their planned starting date',
      },
      COMING_DUE: {
        LABEL: 'Coming due',
        DESCRIPTION: '{{typeLabel}}s that are currently incomplete and have a due date in the next two weeks',
      },
      DUE_DATE_CHANGED: {
        LABEL: 'Due date changed',
        DESCRIPTION:
          '{{typeLabel}}s that are not yet completed where the users have changed their due date at least once in the last two weeks',
      },
      FORECASTING_LAG: {
        LABEL: 'Forecasting lag',
        DESCRIPTION:
          '{{typeLabel}}s with a forecasted completion date that is more than one week out from the originally planned due date',
      },
      CREATING_RISK: {
        LABEL: 'Creating risk',
        DESCRIPTION:
          "Displays the team's {{type}}s that are putting other {{relatedType}}s at risk, where: \n\n" +
          "• The team's {{type}} is a predecessor to another {{relatedType}} \n" +
          "• The team's {{type}} is late to start, late to finish or its due date has been changed in the last two weeks",
      },
      AT_RISK: {
        LABEL: 'At risk',
        DESCRIPTION:
          "Displays the team's {{type}}s that are at risk due to other {{relatedType}}s, where:\n\n " +
          "• Another {{relatedType}} is a predecessor to the team's {{type}} \n" +
          '• That predecessor {{relatedType}} is late to start, late to finish or its due date has been changed in the last two weeks',
      },
      REQUESTED: {
        LABEL: 'Requested',
        DESCRIPTION:
          '{{prefix}} interdependency tasks that have been requested to other teams and are awaiting further action from the receiving teams',
      },
      REJECTED: {
        LABEL: 'Rejected',
        DESCRIPTION: '{{prefix}} interdependency tasks that have been rejected by the receiving teams',
      },
      REQUIRES_DISCUSSION: {
        LABEL: 'Requires discussion',
        DESCRIPTION:
          '{{prefix}} interdependency tasks that have been flagged as requiring additional discussion by the receiving teams',
      },
    },
    TEAM_HOME: {
      STATUS_RATIONALE: 'Status rationale',
      SETUP_TEAM_TITLE: 'Set up your team',
      SETUP_TEAM_DESCRIPTION:
        'This module is locked because the targets are not yet assigned on the Value Capture Tracker Team Setup tab',
      DELIVERABLES_TAB_TITLE: 'Deliverables',
      PROJECT_PLAN_TAB_TITLE: 'Project Plan',
      OPEN_FULL_PROJECT_PLAN_LABEL: 'Full project plan',
      OPEN_DELIVERABLES_OVERVIEW_LABEL: 'Team deliverable overview',
      OPEN_DELIVERABLES_TRACKER_LABEL: 'Team deliverable tracker',
      COLLAPSE_SIDEBAR_LABEL: 'Collapse sidebar panel',
      EXPAND_SIDEBAR_LABEL: 'Expand sidebar panel',
      IMO_DELIVERABLES_TRACKER_TITLE: 'Integration team deliverable tracker',
      SMO_DELIVERABLES_TRACKER_TITLE: 'Separation team deliverable tracker',
      IN_LATE_OR_AT_RISK: 'in late or at risk',
      IN_LATE_OR_AT_RISK_NON_IDEAL: 'No initiatives late or at risk',
      IN_PIPELINE: 'in pipeline or executed',
      IN_PIPELINE_NON_IDEAL: 'No initiatives in pipeline or executed',
      EXECUTED: 'executed',
      EXECUTED_NON_IDEAL: 'No initiatives executed',
      OF_TARGET: 'of target',
      NON_IDEAL_STATE_MESSAGE: 'There is currently no data to display here',
      INITIATIVES: {
        DISTRIBUTION_BY_COUNT_CHART_TITLE: 'Distribution by Count',
        DISTRIBUTION_BY_VALUE_CHART_TITLE: 'Distribution by Value',
      },
      ORG_DESIGN_LAYER_DELIVERABLES: {
        TOOLTIP: {
          DESIGN_START: 'Design start',
          DESIGN_END: 'Design end',
          SELECTION_END: 'Selection end',
          IMPLEMENTATION_END: 'Implementation end',
          FOOTER_INFO: 'The deliverable status is determined by the least performing phase',
        },
      },
      TEAM_ORG_DESIGN: {
        TAB_TITLE: 'Team Org Design',
        ADD_TEAM_SETUP_NOTE: 'Add this team to the Team Setup on the Org Design Tracker in order to unlock this module',
        ADD_TEAM_SETUP_ODT_NOTE: 'Add a team on Team Setup',
        LAYERS: {
          DESIGN: {
            COLUMNS: {
              FULL_BASELINE: 'Full baseline',
              PLANNED_SYNERGIES: 'Planned synergies',
              DESIGN: 'Design',
            },
            TOOLTIPS: {
              DIS_SYNERGY: 'This value creates a dis-synergy because it is greater than the Full baseline',
              REQUIRED: 'This field is required',
              FULL_BASELINE_HEADER: '(data provided by the central team and IMO)',
              PLANNED_SYNERGIES_HEADER: {
                RESULT: 'Planned synergies',
                FULL_BASELINE: 'Full baseline',
                DESIGN: 'Design',
              },
            },
            LINKED_INITIATIVE: {
              TOAST: 'FTE, Cost Impact, and Financials for linked value capture initiative have been updated',
            },
          },
          SELECTION: {
            COLUMNS: {
              INTERNAL: 'Internal',
              TERMINATIONS: 'Terminations (synergies)',
              PEOPLE_WAITING_DECISIONS: 'People awaiting decision',
              POSITIONS_TO_FILL: 'Positions to fill',
              FORECAST_SYNERGIES: 'Forecast/actual synergies',
              DELTA_SYNERGIES: 'Delta synergies (selection vs design)',
            },
            TOOLTIPS: {
              REQUIRED: 'This field is required',
              TOTAL_VALUE_GREATER_BASELINE:
                'The total value of Internal and Termination fields cannot be greater than the Full baseline',
              PEOPLE_AWAITING_DECISIONS_HEADER: {
                RESULT: 'People awaiting decision',
                FULL_BASELINE: 'Full baseline',
                INTERNAL: 'Internal',
                TERMINATIONS: 'Terminations',
              },
              POSITIONS_TO_FILL_HEADER: {
                RESULT: 'Positions to fill',
                DESIGN: 'Design',
                INTERNAL: 'Internal',
              },
              FORECAST_SYNERGIES_HEADER: {
                RESULT: 'Forecast/actual synergies',
                FULL_BASELINE: 'Full baseline',
                INTERNAL: 'Internal',
                PEOPLE_WAITING_DECISIONS: 'People awaiting decision',
              },
              DELTA_SYNERGIES_HEADER: {
                RESULT: 'Delta synergies (selection vs design)',
                FORECAST_ACTUALS: 'Forecast/actual synergies',
                PLANNED: 'Planned synergies',
              },
            },
            LINKED_INITIATIVE: {
              TOAST: 'Financials(Forecast/actuals) for linked value capture initiative have been updated',
            },
          },
          IMPLEMENTATION: {
            COLUMNS: {
              TERMINATIONS: 'Terminations',
              SELECTIONS: 'Selections',
              COMMUNICATIONS: '1:1 communications',
              REQUIRED: 'Required',
              MADE: 'Made',
              ANNOUNCEMENT: 'Announcement',
              PLANNED_DATE: 'Date (plan)',
              COMPLETED: 'Completed',
              TRANSITION: 'Transition',
              EXIT_WINDOW_START: 'Exit window start',
              EXIT_WINDOW_END: 'Exit window end',
            },
            TOOLTIPS: {
              TERMINATIONS_HEADER: "(data taken from 'Terminations' in the Selection phase)",
              SELECTIONS_HEADER: "(data taken from 'Internal' in the Selection phase)",
            },
          },
          COMMON: {
            COLUMNS: {
              TEAM: 'Team',
              LAYER: 'Layer',
              STAGE: 'Stage',
              COST: 'Cost',
              FTE: 'FTE',
              DESCRIPTION: 'Description',
              TEMPLATE: 'Template',
              INSTRUCTIONS: 'Instructions',
              ORG_CHART: 'Org chart',
              TRANSITION_PLAN: 'Transition plan',
              LINKED_ATTACHMENTS: 'Linked attachments',
              COMMENTS: 'Comments on layer',
              OTM: 'Other team members',
              OWNER: 'Owner',
              STATUS: 'Status',
              LINKED_INITIATIVE: 'Linked synergy initiative',
            },
            DUE_DATE: {
              PHASE_DUE_DATE: '{{phase, string}} due date',
              DUE_IN: 'Due in',
              PAST_DUE: 'PAST DUE',
              DAY_one: '{{count}} day',
              DAY_other: '{{count}} days',
              HOUR_one: '{{count}} hour',
              HOUR_other: '{{count}} hours',
              MINUTE_one: '{{count}} minute',
              MINUTE_other: '{{count}} minutes',
            },
            PLACEHOLDERS: {
              DESCRIPTION: 'Enter description',
              COMMENTS: 'Enter comment',
              OTM: 'Find team members…',
            },
            STAGES_BUTTONS: {
              MOVE_TO_IN_PROGRESS: "Move to 'In progress'",
              MOVE_TO_READY_FOR_REVIEW: "Move to 'Ready for review'",
              REVERT_TO_TO_DO: "Revert to 'To do'",
              SUBMIT_FOR_APPROVAL: 'Submit for approval',
              REVERT_TO_IN_PROGRESS: "Revert to 'In progress'",
              APPROVE: 'Approve',
              REJECT: 'Reject',
              CANCEL_SUBMISSION: 'Cancel submission',
              RESET: 'Revoke approval',
              MOVE_TO_TRANSITION_PLAN: "Move to 'Transition plan'",
              MOVE_TO_ONE_TO_ONE_COMMUNICATION: "Move to '1:1 communication'",
              MOVE_TO_ANNOUNCED: "Move to 'Announcement'",
              REVERT_TO_TRANSITION_PLAN: "Revert to 'Transition plan'",
              REVERT_TO_ONE_TO_ONE_COMMUNICATION: "Revert to '1:1 communication'",
              COMPLETED: 'Completed',
            },
            STATE_BUTTON_ALERT: {
              REVIEW: {
                MESSAGE: `Progressing to 'Ready for review' means <strong>fields will no longer be editable for this layer.</strong> Please confirm that the data you have entered for this layer is correct.`,
                CONFIRM: 'Progress to next stage',
              },
              REVERT: {
                MESSAGE: `Reverting back to 'In progress' means <strong>data shared with the linked value capture initiative will be lost</strong>.`,
                CONFIRM: 'Revert to previous stage',
                CONFIRM_MESSAGE: 'Are you sure you want to revert stages?',
              },
              COMMON: {
                TITLE: 'Warning',
                BACK: 'Go back',
              },
            },
            STAGE_BUTTONS_TOOLTIPS: {
              EMPTY_DESIGN_FTE_COST: 'Design FTE and Cost must be populated',
              EMPTY_INTERNAL_FTE_COST: 'Internal FTE and Cost must be populated',
              EMPTY_TERMINATIONS_FTE_COST: 'Terminations FTE and Cost must be populated',
              INCORRECT_PEOPLE_WAITING_DECISION: 'People awaiting decisions FTE must be equal to zero',
              INCORRECT_POSITION_TO_FILL: 'Positions to fill FTE must be less than or equal to zero',
              INCORRECT_DESIGN_STAGE:
                'Design for this layer needs to be <strong>Final (approved)</strong> in order to progress the Selection',
              NO_LINKED_PROJECT: 'The linked value capture initiative must have a linked project',
              INCORRECT_SELECTION_STAGE_FOR_DESIGN:
                "Selection is in {{stage, string}} stage, please revert it to 'In progress' before resetting the Design.",
              INCORRECT_IMPLEMENTATION_STAGE:
                "Implementation is in {{stage, string}} stage, please revert it to 'Transition plan' before resetting the Design",
              INCORRECT_IMPLEMENTATION_STAGE_FOR_SELECTION:
                "Implementation is in {{stage, string}} stage, please revert it to 'Transition plan' before resetting the Selection",
              INCORRECT_SELECTION_STAGE_FOR_IMPLEMENTATION:
                'Selection for this layer needs to be <strong>Final (approved)</strong> in order to progress the Implementation',
              LINKED_PROJECT_IS_NOT_COMPLETE:
                'The project {{id}} {{name}} linked to the linked value capture initiative must be COMPLETED',
              LINKED_PROJECT_DOES_NOT_EXIST: 'The linked value capture initiative must have a completed linked project',
              ANNOUNCEMENT_NOT_COMPLETED_FOR_IMPLEMENTATION: 'The Announcement needs to be marked as completed',
              INCORRECT_PERMISSION_TO_SUBMIT_FOR_APPROVAL: 'Team lead is responsible for submitting the approval',
              INCORRECT_PERMISSION_TO_CANCEL_SUBMISSION: 'Team lead has permissions to cancel the submission',
              INCORRECT_PERMISSION_TO_APPROVE: 'Responsible Central team members are responsible for the approval',
              INCORRECT_PERMISSION_TO_REJECT: 'Responsible Central team members are responsible for the rejection',
              UNABLE_TO_REVERT_DESIGN_TO_IN_PROGRESS_DUE_TO_SELECTION_STAGE:
                'Selection is in {{stageName}} stage, please revert it to <strong>In progress</strong> before moving the Design backwards',
              UNABLE_TO_PROGRESS_SELECTION_WHILE_DESIGN_IS_NOT_READY_FOR_REVIEW:
                'Design is in {{stageName}} stage, please move it to <strong>Ready for review</strong> before moving the Selection forward',
            },
            LINK_INITIATIVE_STAGE_GATE: {
              LABEL: 'Linked value capture initiative stage gate',
              DIALOG: {
                TITLE: 'Relationship between Stages and Stage Gates',
                SUBTITLE:
                  'Org Design layers are linked to value capture initiatives. L1-L4 stage gates are automatically linked to the Org Design layer stages. However, the L5 stage gate will require manual submission and approval.',
                ORG_DESIGN_COLUMN: {
                  TITLE: 'Org Design Stage',
                  DESIGN: {
                    TITLE: 'Design',
                  },
                  SELECTION: {
                    TITLE: 'Selection',
                  },
                  IMPLEMENTATION: {
                    TITLE: 'Implementation',
                    TRANSITION_PLAN: 'Transition plan',
                    ONE_TO_ONE_COMMS: '1:1 communication',
                    ANNOUNCEMENT: 'Announcement',
                    IMPLEMENTED_SUBMITTED: 'Implemented (submitted)',
                    IMPLEMENTED_APPROVED: 'Implemented (approved)',
                  },
                  COMMON: {
                    TO_DO_STAGE: 'To do',
                    IN_PROGRESS_STAGE: 'In progress',
                    READY_FOR_REVIEW: 'Ready for review',
                    FINAL_SUBMITTED: 'Final (submitted)',
                    FINAL_APPROVED: 'Final (approved)',
                  },
                },
                VC_COLUMN: {
                  TITLE: 'Linked value capture intiative stage gate',
                  L1: 'L1',
                  SUBMITTED: '{{type}} Submitted',
                  APPROVED: '{{type}} Approved',
                },
              },
            },
            TOOLTIPS: {
              READ_ONLY_COLUMN: 'This is a read-only column',
              CALCULATED_COLUMN: 'This is a calculated column',
              STATUS: {
                AT_RISK_BY_DUE_DATE: 'This status is at risk because the due date is less than in 3 business days',
                DELAYED_BY_DUE_DATE: 'This status is delayed because the due date is in the past',
                DELAYED_BY_DELTA: 'This status is delayed because the Synergy delta Cost is less than 0',
                LOCKED_BASELINES: 'Locked because baseline values are not set in Org Design Tracker Team Setup',
                LOCKED_START_DATE: 'Locked until {{startDate, string}}',
              },
            },
            LINKED_INITIATIVE: {
              VALUE: 'Cost initiative for org design ',
              LABEL: 'Linked value capture initiative',
              TOOLTIP: 'This value capture initiative was automatically created and linked for each org design layer',
              BUTTON: 'Go to value capture',
              TOAST_ACTION: 'View initiative',
            },
          },
        },
        DASHBOARD: {
          PROGRESS_BY_PHASE: {
            TITLE: 'Progress by phase',
            NO_DATA: 'No data to display',
            TOOLTIPS: {
              COMPLETED: 'Completed',
              TOTAL: 'Total',
              PROGRESS: 'Progress',
              WEIGHTED_BY_FTE: 'Weighted by baseline FTEs in completed layers',
              WEIGHTED_BY_LAYER: 'Weighted by number of completed layers',
            },
          },
          DELTA_CARDS: {
            NO_DATA: 'No data to display',
            TOOLTIPS: {
              NO_LAYERS: 'No layers',
              FTES: 'Total FTEs for layers when implementation stage is approved',
              FTES_ODT:
                'Total FTEs for layers when the <strong>{{implementation}}</strong> phase has reached the <strong>{{implementationApproved}}</strong> stage',
              CURRENT_DELTA: 'Current delta',
              BASELINE_TOTAL: 'Baseline total',
              PHASE_TOTAL: '{{phase}} total',
              LOCKED_LAYERS_MESSAGE: 'Calculations do not include locked layers',
            },
            DESIGN_VS_BASELINE: {
              TITLE: 'Design vs. baseline',
              TREND_TEXT: 'vs. baseline',
            },
            SELECTION_VS_DESIGN: {
              TITLE: 'Selection vs. design',
              TREND_TEXT: 'vs. design',
            },
            SELECTION_VS_BASELINE: {
              TITLE: 'Selection vs. baseline',
              TREND_TEXT: 'vs. baseline',
            },
            COMPLETED_EXITS: {
              TITLE: 'Completed exits',
              TREND_TEXT: 'of selection',
              SUB_VALUE_one: 'FTE',
              SUB_VALUE_other: 'FTEs',
            },
          },
          WEEKLY_STATUS: {
            PLACEHOLDER_TEXT: 'Add context, decisions, risks or requests for support with your status update',
          },
          LAYERS_BY: {
            DELTA_TOGGLE_TITLE: 'Show deltas',
            DELTA_TITLE: 'Delta',
            DELTA_DESIGN_BASELINE: 'Design vs baseline',
            DELTA_SELECTION_BASELINE: 'Selection vs baseline',
            DELTA_DESIGN_SELECTION: 'Selection vs design',
            TITLE: 'Layers by',
            TRACKER_TITLE: 'Team org design by',
            EMPTY_TITLE: 'There is currently no data to display here.',
            EMPTY_SUBTITLE: 'Please try again later.',
          },
        },
        OVERVIEW: {
          PROGRESS_BY_LAYERS: {
            TITLE: 'Progress by layers',
            HEADERS: {
              LAYER: 'Layer',
              TEAM: 'Team',
              APPROVAL: 'Approval',
              TEAM_STATUS: 'Status',
              TEAM_STATUS_RATIONALE: 'Status rationale',
              PHASE_PROGRESS: 'Phase progress (DSI)',
              OWNER: 'Owner',
              BASELINE: 'Baseline',
              DESIGN: 'Design',
              SELECTION: 'Selection',
              COST: 'Cost',
              FTE: 'FTE',
              VS_BASELINE: 'vs. baseline',
              VS_DESIGN: 'vs. design',
              SPAN_OF_CONTROL: 'Span of control',
              ORG_CHART: 'Org chart',
            },
            TOTAL: {
              LAYER: 'Total',
              SPAN_OF_CONTROL: 'Average',
            },
            PLACEHOLDERS: {
              OWNER: 'Enter name',
            },
            TOOLTIPS: {
              SPAN_OF_CONTROL: 'FTEs in the subsequent layer divided by FTEs in the current layer',
              FINAL_LAYER_SPAN_OF_CONTROL: 'Span of control does not apply to the final layer of the org',
              NO_ORG_CHARTS: 'There are no Org chart files uploaded',
              NO_TEAM_ORG_CHARTS: 'There are no Org chart files uploaded for the teams',
              DOWNLOAD_ORG_CHARTS: 'Download org charts',
              PHASE_PROGRESS: {
                STAGE: 'Stage',
                STATUS: 'Status',
                DUE_DATE: 'Due date',
              },
              TOTAL_DSI: {
                PERCENTAGE_COMPLETE: '{{ value }}% complete',
              },
            },
            APPROVALS: {
              FILTER: 'Approval requested',
              TEAM_TOOLTIP_one:
                'There is {{count}} org design layer in the submitted stage that require approval to proceed',
              TEAM_TOOLTIP_other:
                'There are {{count}} org design layers in the submitted stage that require approval to proceed',
              LAYER_TOOLTIP: 'Approval is requested for the <strong>{{phase}}</strong> phase',
              TEAM_TOOLTIP_HEADER: 'Approval requested',
            },
            REORDER_TEAMS: {
              SAVE: 'Save changes',
              CANCEL: 'Cancel',
              TITLE: 'Reorder teams',
              DESCRIPTION: 'Move teams by dragging and dropping',
              COLUMN_NAME: 'Team',
            },
            SWITCHER: {
              COST: 'Cost',
              FTE: 'FTE',
              NUMERICAL: 'Numerical',
              PERCENTAGE: 'Percentage',
            },
            DOWNLOAD_ORG_CHARTS: 'Download org charts',
            SHOW_LAYERS: 'Show layers',
            HIDE_LAYERS: 'Hide layers',
            EXPAND_STATUS_RATIONALE: 'Expand status rationale',
            COLLAPSE_STATUS_RATIONALE: 'Collapse status rationale',
          },
        },
      },
      DELIVERABLE_STATUS_EXPLAINER: {
        NOT_APPLICABLE: 'n/a',
        OVERALL_DELIVERABLE_STATUS: 'Overall deliverable status is {{status}}',
        ORG_DESIGN: {
          TITLE: '{{layer}} status overview',
          DESCRIPTION:
            "The table below shows the status of each phase for the team's <strong>{{layer}}</strong>. At the very bottom, you will find the overall deliverable status for this layer.",
          LINK_TEXT: 'Go to org design layer',
          OVERALL_STATUS_EXPLAINER: 'The overall deliverable status factors the status of all three phases',
          DESIGN_TAB_TITLE: 'Design',
          SELECTION_TAB_TITLE: 'Selection',
          IMPLEMENTATION_TAB_TITLE: 'Implementation',
          TABLE_HEADERS: {
            TO_DO: 'To do',
            IN_PROGRESS: 'In progress',
            READY_FOR_REVIEW: 'Ready for review',
            FINAL_SUBMITTED: 'Final (submitted)',
            FINAL_APPROVED: 'Final (approved)',
            TRANSITION_PLAN: 'Transition plan',
            ONE_TO_ONE_COMMUNICATION: '1:1 communication',
            ANNOUNCED: 'Announced',
            IMPLEMENTATION_SUBMITTED: 'Imp. (submitted)',
            IMPLEMENTATION_APPROVED: 'Imp. (approved)',
            PHASE_STAGES: '{{phase}} stages',
            MORE_THAN_3_DAYS: 'More than 3 business days of due date',
            WITHIN_3_DAYS: 'Within 3 business days of due date',
            AFTER_END_DATE: 'After end date',
            NEGATIVE_DELTA_SYNERGY: 'If a delta synergy is negative',
          },
        },
      },
    },
    PROJECT_ANALYTICS: {
      UNFLAGGED: `Excludes {{excluded, string}} {{entity, string}} without a time flag`,
      EMPTY_RESULT_TITLE: 'No {{view, string}} to show',
      EMPTY_RESULT_DESCRIPTION: 'There are no {{view, string}} to show based on your selected date range and filters',
      FILTERS: {
        ALL: 'All',
        TEAM_LABEL: 'Team',
        VIEW_LABEL: 'View',
        VIEW_PROJECT: 'Project',
        VIEW_PROJECTS: 'Projects',
        VIEW_TASK: 'Task',
        VIEW_TASKS: 'Tasks',
        COMPLETED_LABEL: 'Completed items',
        COMPLETED: {
          INCLUDE: 'Include',
          EXCLUDE: 'Exclude',
        },
        PRIORITY_LABEL: 'Priority',
        FREQUENCY_LABEL: 'Frequency',
        FREQUENCY: {
          DAILY: 'Daily',
          WEEKLY: 'Weekly',
          MONTHLY: 'Monthly',
          QUARTERLY: 'Quarterly',
        },
        BREAKDOWN_LABEL: 'Breakdown',
        CUSTOM_COLUMNS_LABEL: 'Custom columns',
        FILTER_LABEL: 'Filter',
        NO_FILTER_LABEL: 'No Filter',
        CONFIDENTIAL_LABEL: 'Confidential',
        CONFIDENTIAL: {
          SHOW: 'Show',
          HIDE: 'Hide',
        },
        SHOW_ALL: 'Show all',
        SHOW_ONLY_INTERDEPENDENCIES: 'Show only interdependencies',
        INTERDEPENDENCY_STATUS: 'Interdependency status',
      },
      OVERVIEW: {
        TITLE: 'Overview',
        PANEL_TITLE: {
          TASKS: 'Number of tasks by',
          INTERDEPENDENT_TASKS: 'Number of interdependent tasks by',
          PROJECTS: 'Number of projects by',
          INTERDEPENDENT_PROJECTS: 'Number of interdependent projects by',
        },
        EXCLUDES_COMPLETED: '(excludes completed)',
        ALL_INTERDEPENDENT: 'All interdependent {{type, string}}s',
        ALL_TYPE: 'All {{type, string}}s',
        ANALYTICS_ERROR: 'Error loading analytic data',
        PAST_DUE: 'Past Due',
        UPCOMING: 'Upcoming',
        OUTBOUND: 'Outbound',
        OUTBOUND_PROJECTS: 'Projects with outbound',
        PROJECT_TABLE: {
          PREDECESSORS_HEADER: 'Predecessors',
          FOLLOWERS_HEADER: 'Followers',
          INTERDEPENDENCIES_HEADER: 'Outgoing Interdependencies',
        },
        CHART_TOOLTIP: {
          TIMELINE: 'Number of {{entityTypeLabel}} with a {{groupByLabel}} in ',
          TIMELINE_PAST: 'Number of {{entityTypeLabel}} with a {{groupByLabel}} ',
          TIMELINE_INTERDEPENDENT: 'Number of interdependent {{entityTypeLabel}} with a {{groupByLabel}} in ',
          TIMELINE_INTERDEPENDENT_PAST: 'Number of interdependent {{entityTypeLabel}} with a {{groupByLabel}} ',
        },
        NON_IDEAL_STATE: {
          TITLE: 'No data to show',
          DESCRIPTION: 'There is currently no data to show. Please check back later.',
        },
      },
      GANTT: {
        TITLE: 'Gantt',
        EMPTY_SELECTION_DESCRIPTION: 'Please select which {{view, string}} you wish to display',
        EMPTY_SELECTION_DESCRIPTION_LOWER: 'on the Gantt chart',
        KEY_PROCESS_POPUP_TITLE: 'Select Gantt chart items to display',
        TOOLTIP_INTERDEPENDENT: 'This task is interdependent with the {{interdependentTeam}} team',
      },
      BURNDOWN: {
        TITLE: 'Burndown',
        FILTERS_ARIA_LABEL: 'Burndown Chart Filters',
        X_AXIS_LABEL: 'Timeline',
        Y_AXIS_LABEL: 'Number of {{view, string}}s',
        TOOLTIP_TITLE_one: 'There is {{count, number}} {{view, string}} with the following status:',
        TOOLTIP_TITLE_other: 'There are {{count, number}} {{view, string}}s with the following statuses:',
        TOOLTIP_TOTAL: 'Total number of {{view}}s',
        TOOLTIP_COMPLETED: 'Expected number of completed {{view}}s',
        TOOLTIP_OUTSTANDING: 'Expected number of outstanding {{view}}s*',
        TOOLTIP_OUTSTANDING_FOOTNOTE: '*includes {{view}}s without due dates',
        PLAN: 'Plan',
        PLAN_TOOLTIP_MESSAGE:
          'This line shows the total number of expected {{view, string}}s outstanding at a given point in time based on planned due dates',
      },
    },
    PROJECT_PLAN: {
      SHOW_KEY_PROCESSES: 'Show key processes',
      SHOW_PROJECTS: 'Show projects',
      SHOW_TASKS: 'Show tasks',
      SPECIAL_ATTRIBUTES: {
        LABELS: {
          LINKED_VALUE_CAPTURE_INITIATIVE: 'Linked synergy initiative',
          LINKED_ONE_TIME_COST: 'Linked one-time cost',
          PREDECESSOR: 'Predecessor',
          FOLLOWER: 'Follower',
          LINKED_TSA: 'Support TSA',
          OUTGOING_INTERDEPENDENCY: 'Outgoing interdependency',
          INCOMING_INTERDEPENDENCY: 'Incoming interdependency',
          INCOMING_MIRROR: 'Mirrored from',
          OUTGOING_MIRROR: 'Mirrored to',
        },
        TOOLTIPS: {
          LINKED_VALUE_CAPTURE_INITIATIVE: 'Linked to a value capture initiative',
          LINKED_ONE_TIME_COST: 'Linked to a one-time cost',
          PREDECESSOR: 'Has a {{type}} that precedes it',
          FOLLOWER: 'Has a {{type}} that follows it',
          LINKED_TSA: 'Project supports exit of TSA',
          OUTGOING_INTERDEPENDENCY: 'Outgoing interdependency',
          INCOMING_INTERDEPENDENCY: 'Incoming interdependency',
          INCOMING_MIRROR: 'Mirrored from the {{team}} team',
          OUTGOING_MIRROR_one: 'Mirrored to the {{teams}} team',
          OUTGOING_MIRROR_other: 'Mirrored to the {{teams, list}} teams',
        },
      },
      LINK_INITIATIVE: {
        LABEL: 'Linked synergy initiative',
        TOOLTIP: 'Go to the <strong>Project Map</strong> to link a synergy initiative',
        BUTTON_WITH_VALUE: 'Go to initiative',
        BUTTON_WITHOUT_VALUE: 'Go to project map',
      },
      LINK_ONE_TIME_COST: {
        LABEL: 'Linked one-time cost',
        TOOLTIP: 'Go to the <strong>Project Map</strong> to link a one-time cost',
        BUTTON_WITH_VALUE: 'Go to one-time cost',
        BUTTON_WITHOUT_VALUE: 'Go to project map',
      },
      LINK_TSA: {
        LABEL: 'Linked TSA',
        TOOLTIP: 'Go to the <strong>TSA Phase 4</strong> to link a project',
        BUTTON: 'Go to TSA',
      },
      ACTIVE_USER_CELL_INFO: 'Editing {{field}} for {{type}} {{projectListId}}',
      LOCKED_FIELD_WARNING_MESSAGE:
        'This field is locked because the project has a Priority of <strong>{{priority}}</strong>',
      INVALID_DATE_FORMAT: 'Invalid data type, the date is expected',
      ASK_MYIMO_ANALYZE: 'Analyze',
    },
    MDPA: {
      ACTIVE_USER_CELL_INFO: 'Editing {{field}} for {{processName}}',
    },
    PROJECT_MIRRORING: {
      SHARED_COMMENTS_TITLE: 'Shared comments coming soon',
      SHARED_COMMENTS_DESCRIPTION: 'Use shared comments to collaborate with others on mirrored projects',
      LABEL: 'Project mirroring',
      TOOLTIP_INFO: 'Project mirroring gives receiving teams read-only access to the project on their project plan',
      TOOLTIP_DISABLED: 'You do not have permission to mirror projects',
      MIRRORED_PROJECT_BANNER: 'This project is mirrored from the <strong>{{teamName}}</strong> team.',
      MIRRORED_TASK_BANNER: 'This task is from a mirrored project from the <strong>{{teamName}}</strong> team.',
      PROJECT_WATCH_DISABLED_TOOLTIP: 'You cannot watch a mirrored project',
      TASK_WATCH_DISABLED_TOOLTIP: 'You cannot watch a task within a mirrored project',
    },
    PROJECT_PLAN_MOVE_TASKS: {
      UNABLE_TO_MOVE_TASK: 'Unable to move task, please try again',
      INVALID_DROP_TARGET: 'Cannot move a task to an interdepdency or mirror',
      DUPLICATE_TASK_NAME: 'Task with name {{taskName}} already exist',
      NO_PROJECT: 'Cannot move task to a key process without a project',
    },
    PROJECT_PLAN_MOVE_PROJECTS: {
      UNABLE_TO_MOVE_PROJECT: 'Unable to move project, please try again',
      INVALID_DROP_TARGET: 'Cannot move a project to an interdepdency or mirror',
      DUPLICATE_PROJECT_NAME: 'Project with name {{projectName}} already exist',
    },
    PROJECT_PLAN_MOVE_PROCESSES: {
      UNABLE_TO_MOVE_PROCESS: 'Unable to move key process, please try again',
      INVALID_DROP_TARGET: 'Cannot move a project to an interdependency or mirror',
    },
    ORG_DESIGN_TRACKER: {
      SETTINGS: 'Settings',
      AUTO_COMPLETION: 'Layer deliverable auto-completion',
      AUTO_COMPLETION_SUBTEXT:
        'When enabled, layer deliverables are considered Completed upon submission and prior to approval',
      MAX_LAYER: 'Maximum layer threshold',
      MAX_LAYER_TOOLTIP: 'This value can only be modified in the Admin Configuration portal',
      NO_ROWS_MESSAGE: 'No rows to show',
      TARGET_THRESHOLD_SPAN: {
        TITLE: 'Target span of control',
        TOOLTIP:
          'FTEs in the subsequent layer divided by FTEs in the current layer (e.g. Layer 3 FTEs divided by Layer 2 FTEs)',
        ON_TRACK: 'On track',
        AT_RISK: 'At risk',
        DELAYED: 'Delayed',
        ON_TRACK_VALIDATION: 'Value must be between "At risk" threshold and 100',
        AT_RISK_VALIDATION: 'Value must be between "Delayed" and "On track" thresholds',
      },
      TEAM_PROGRESS_CALC: {
        TITLE: 'Team progress calculation method',
        SUBTEXT: 'How should progress be tracked once a layer is complete?',
        NUMBER_OF_LAYERS: 'Number of layers',
        NUMBER_OF_LAYERS_DESC: 'Each layer weighted equally',
        FTE: 'Weighted by FTEs',
        FTE_DESC: 'Layers with more FTEs will carry greater weight',
      },
      PAGES: {
        ROOT: 'Org Design Tracker',
        ALL_LAYERS: 'All Layers',
        TEAM_SETUP: 'Team Setup',
        DESIGN: 'Design',
        SELECTION: 'Selection',
        IMPLEMENTATION: 'Implementation',
        TIMELINE: 'Timeline',
        SUMMARY_STATUS: 'Summary Status',
        DASHBOARD: 'Dashboard',
        OVERVIEW: 'Overview',
      },
      SUMMARY_STATUS: {
        OVERVIEW: {
          TITLE: 'Summary status by integration team',
        },
      },
      TIMELINE: {
        GROUP_BY: {
          TEAMS: 'Teams',
          WAVES: 'Waves',
        },
        CHART_LABELS: {
          DESIGN: 'Design',
          SELECTION: 'Selection',
          IMPLEMENTATION: 'Implementation',
          ANNOUNCEMENT: 'Announcement',
          EXIT_WINDOW: 'Exit window',
        },
        CHART_ACTIONS: {
          SHOW_QUIET_PERIOD: 'Show quiet periods',
          SHOW_QUIET_PERIOD_TOOLTIP:
            'There are no quiet periods configured in the Org Design Tracker <strong>Team Setup</strong> tab',
          EDIT_WAVES_BTN: 'Edit waves',
        },
        CHART_TOOLTIP: {
          DESIGN_START: 'Design start',
          DESIGN_END_SELECTION_START: 'Design end / Selection start',
          SELECTION_END_IMPLEMENTATION_START: 'Selection end / Implementation start',
          IMPLEMENTATION_END: 'Implementation end',
          ANNOUNCEMENT: 'Announcement',
          EXIT_WINDOW: 'Exit window',
        },
        WAVES_MODAL: {
          TITLE: 'Assign teams to waves',
          DESCRIPTION: 'Move teams by dragging and dropping into different waves',
          TOGGLE_ENABLED: 'Waves enabled',
          TOGGLE_DISABLED: 'Waves disabled',
          WAVE_DRAG_TEXT: 'Wave {{waveNumber}}',
          TEAMS_one: 'team',
          TEAMS_other: 'teams',
          WAVE_NAME: '<strong>Wave {{waveNumber}}</strong> ({{teamCount}} {{teams}})',
          COLLAPSE_ALL: 'Collapse all',
          EXPAND_ALL: 'Expand all',
          ADD_WAVE: 'Add wave',
          ADD_WAVE_DISABLED: 'Cannot exceed 10 waves',
          REMOVE_WAVE: 'Remove wave',
          REMOVE_WAVE_DISABLED: 'At least one wave is required',
          REMOVE_WAVE_WARNING:
            'Are you sure you want to remove Wave {{waveToRemove}}? The teams in this wave will be moved to Wave {{closestWave}}.',
          REMOVE_WAVE_CANCEL: 'Cancel',
          REMOVE_WAVE_CONFIRM: 'Remove Wave {{waveToRemove}}',
          COLUMNS: {
            NAME: 'Name',
            DESIGN_START: '{{design}} start',
            IMPLEMENTATION_END: '{{implementation}} end',
          },
        },
        WAVES_NON_IDEAL_STATE: {
          DESCRIPTION: 'Waves are not currently enabled',
          ENABLE_WAVES_BTN: 'Enable waves',
        },
        CHART_FOOTER: 'Timeline uses the earliest start date and the latest end date to each group',
      },
      QUIET_PERIOD: {
        TITLE: 'Quiet periods',
        HELPER_TEXT:
          'Periods during which there should be no people announcements, organization structure changes or terminations',
        EDIT_BTN_TEXT: 'Edit',
        POPUP: {
          TITLE: 'Set up quiet periods',
          HELPER_TEXT:
            'Set up quiet period during which there should be no people announcements, organization structure changes or terminations. Visible on Timeline.',
          CONFIRM_BUTTON: 'Save changes',
          DELETE_BUTTON: 'Remove',
          NON_IDEAL_STATE_MESSAGE: 'No quiet period(s) set up yet',
          COLUMNS: {
            NAME: 'Name',
            START_DATE: 'Start date',
            END_DATE: 'End date',
          },
          TOOLTIPS: {
            REQUIRED: 'This field is required',
            FORBIDDEN_EMPTY_VALUE: 'This cell cannot have an empty value',
          },
          PLACEHOLDERS: {
            NAME: 'Limit to 100 characters',
          },
        },
      },
    },
    WATCHING: {
      TOOLTIP_DISABLED: 'You do not have permissions to change your watch status',
      TOOLTIP_DISABLED_OWNER: 'You cannot unwatch this because you are listed as the owner of this {{processType}}',
      TOOLTIP_DISABLED_OTM: `You cannot unwatch this because you are listed as a team member of this {{processType}}`,
    },
    CONFIG: {
      MODULE_OPTIONS: {
        CUSTOM_COLUMNS: {
          SECTIONS: {
            RISK_LOG: 'Risk log',
            DECISION_LOG: 'Decision log',
          },
          TITLES: {
            PROJECT_PLAN: 'project plan',
            VALUE_CAPTURE: 'value capture initiative list',
            RISK_LOG: 'risk log',
            DECISION_LOG: 'decision log',
            EDIT: 'Edit custom column',
            ADD_TO: 'Add custom column to the {{title}}',
            ADD: 'Add custom column',
            EDIT_DEFAULT_COLUMN: 'Edit the {{name}} column',
          },
          TOOLTIPS: {
            ALREADY_ACTIVE: 'This column is currently active on the {{title}}',
            CURRENTLY_INACTIVE: 'The column is currently inactive and hidden on the {{title}}',
          },
          LABELS: {
            NAME: 'Name of column',
            TYPE: 'Type of column',
            LIMIT: 'Character limit',
          },
          MESSAGES: {
            CHARACTER_LIMIT: 'Enter limit in characters for the column text',
            DELETE_COLUMN: 'You are about to delete the <strong>{{columnName}}</strong> column.',
            DELETE_COLUMN_DETAIL: 'Are you sure you want to continue? This action cannot be reversed.',
            DELETE_COLUMN_DETAIL_AIRTABLE:
              'Are you sure you want to continue? This action cannot be reversed. Deleting this column will also disable your Airtable integration as it is set as the linked column.',
            INACTIVE_COLUMN: 'Are you sure you want to make this column inactive?',
            INACTIVE_COLUMN_DETAIL:
              'This action will also disable your Airtable integration as it is set as the linking column',
            DELETE_TITLE: 'Confirm permanent deletion',
            INACTIVE_TITLE: 'Confirm set inactive',
            ERROR_UNLINK_FIRST: "{{value}} can't be deleted, please unlink {{columnName}} on the {{logType}}",
          },
        },
        AIRTABLE: {
          LABELS: {
            SHARED_COLUMN: 'Linked column',
            PAT: 'Personal access token',
            TEAM_NAME: 'Team name',
            BASE_ID: 'Base ID',
            TABLE_ID: 'Table ID',
            RECIPIENTS: 'Report recipient list',
            MAPPINGS: 'Match column names to Project Plan',
          },
          PLACEHOLDERS: {
            SHARED_COLUMN: 'Select linked column',
            PAT: 'Enter personal access token from Airtable',
            TEAM_NAME: 'Select team',
            BASE_ID: 'Enter base ID from Airtable',
            TABLE_ID: 'Enter table ID from Airtable',
            RECIPIENTS: 'Enter email',
            MAPPINGS: 'Enter column name from Airtable',
          },
          TOOLTIPS: {
            SHARED_COLUMN:
              "Select a project plan custom column from the dropdown that contains the unique identifier that links the record to the client's Airtable record",
            INTEGRATION_TOGGLE_DISABLED:
              "In order to enable the toggle, please create an active custom column containing the unique identifier that links the record to the client's Airtable record",
          },
          BUTTONS: {
            ADD_CONFIGURATION: 'Manage teams with integration',
            ADD_TEAM: 'Add team',
            UPDATE_TEAM: 'Update team',
            REMOVE_TEAM: 'Remove team from integration',
            CANCEL: 'Cancel',
            DELETION_CANCEL: 'Cancel',
            DELETION_CONFIRM: 'Remove team from integration',
          },
          MODALS: {
            DELETION:
              'This action will permanently remove this team from this integration. Updates to Project Plan will no longer occur.',
          },
          HEADINGS: {
            ADD_TEAM: 'Add team details to integration',
            UPDATE_TEAM: 'Edit team details for integration',
            RECIPIENTS: 'Recipients will receive regular reports on integration',
          },
          COLUMN_NAMES: {
            EMAIL: "Team members' emails",
            PROJECT_PLAN_COLUMN: 'Project plan column name',
            IMPORTED_COLUMN: 'Imported column name',
          },
          FIELD_NAMES: {
            SHARED_ID: 'Airtable ID',
            START_DATE: 'Start Date',
            DUE_DATE: 'Due Date',
            STATUS: 'Status',
          },
          NOTIFICATIONS: {
            TEAM_ADDED: 'Team added',
            TEAM_UPDATED: 'Team updated',
            TEAM_REMOVED: 'Team removed from Airtable integration',
            BAD_SHARED_COLUMN: 'A linked column for your Airtable integration must be specified.',
          },
          ERRORS: {
            UNCHECKABLE: 'Unable to check whether this field is valid',
            INVALID_TOKEN: 'Personal Access token is incorrect',
            BASE_NOT_FOUND: 'Base ID is incorrect',
            TABLE_NOT_FOUND: 'Table ID is incorrect',
            START_DATE_FIELD_NOT_FOUND: "The 'Start Date' column name does not match",
            DUE_DATE_FIELD_NOT_FOUND: "The 'Due Date' column name does not match",
            ID_FIELD_NOT_FOUND: "The 'Airtable ID' column name does not match",
            STATUS_FIELD_NOT_FOUND: "The 'Status' column name does not match",
            VALIDATION_FAILED:
              'There was an issue checking these credentials. Please try again. If the issue persists, please contact the McKinsey team on the ground.',
          },
          PROCESSING_MESSAGE: 'Processing for integration will occur every day at 11:59 GMT',
          VALIDATION_INTRO: 'Please fix the following errors and try again:',
        },
        CALENDAR_VIEW: {
          LABEL: 'Calendar view',
          TOOLTIP:
            'This setting applies to the detailed financials grid plus all charts and grids within value capture',
        },
        NPV: {
          LABEL: 'Enable NPV tracking',
          TOOLTIP: 'This setting adds target, planned, and forecast NPV values for both Cost and Revenue initiatives',
          TAX_RATE: 'Tax rate',
          WACC: 'Weighted average cost of capital (WACC)',
          VALIDATION_ERRORS: {
            MISSING_VALUES: 'Please fill in required fields',
            ZERO_WACC:
              'Weighted average cost of capital (WACC) cannot be 0% because it leads to dividing by 0 during NPV calculation',
            INVALID_VALUES: 'Some NPV values are invalid',
          },
          WARNING_BODY: 'All NPV values will be recalculated based on the updated parameter.',
          WARNING_PROMPT: 'Are you sure you wish to make this change?',
          WARNING_CONFIRM: 'Confirm',
          WARNING_CANCEL: 'Cancel',
        },
        TIMELINE: {
          LABEL: 'Number of years to show',
          TOOLTIP: 'This setting changes the number of years that are displayed for all Value Capture initiatives',
        },
      },
      LOCK_PROJECT_EDITING_PRIORITIES: {
        DESCRIPTION: 'Lock project editing when priority is',
        TOOLTIP:
          'When a project has one of the following Priority values, the entire project plus all tasks under it will have all fields frozen except for <strong>Status</strong>, <strong>Comments</strong>, <strong>Linked attachments</strong> and <strong>Mirroring</strong>',
      },
      PROJECT_SETUP: {
        DATES_CHANGE_CONFIRMATION_POPUP: {
          HEADER: 'Changing date(s)',
          BODY1:
            "This action will <strong>change the calendar view configuration in Step 8 from 'Fiscal year' to 'Calendar year'</strong>.",
          BODY2:
            'If the project has kicked off and there are both integration projects and/or value capture initiatives, please make sure you work with the relevant integration teams to update their plans based on the new date(s).',
          BODY3:
            'This may result in a longer than usual waiting period and may interfere with team activity in the application. Ensure there is no risk of interrupted work (e.g. outside of office hours).',
          BODY4: 'Are you sure you wish to change the date(s)?',
          CONFIRM_BUTTON: 'Change date(s)',
        },
        KEY_DETAILS: {
          CODENAME_LABEL: 'Program codename',
          CODENAME_HELPER_TEXT: 'To change program codename, please contact the admin.',
          DEAL_TYPE_LABEL: 'Deal type',
          DEAL_TYPE_HELPER_TEXT:
            'Select deal type: Merger, Integration, Buy-side carve out, Divestiture (sale), Demerger (spin, split, IPO).',
          RESPONSIBLE_ADMIN_LABEL: 'Program admin',
          RESPONSIBLE_ADMIN_HELPER_TEXT: 'To change this, please contact the admin.',
          IMO_COMPANY_A: 'Company A',
          IMO_COMPANY_B: 'Company B',
          SMO_COMPANY_A: 'RemainCo',
          SMO_COMPANY_B: 'DivestCo',
          COMPANY_NAME_LABEL: '{{company}} name',
          COMPANY_NAME_HELPER_TEXT: 'Enter {{company}} name.',
          COMPANY_LOGO_LABEL: '{{company}} logo',
          COMPANY_LOGO_HELPER_TEXT:
            'Upload {{company}} logo, click on the icon to select .jpg or .png file to upload (up to 2mb).',
          CHOOSE_FILE: 'Choose file',
          SELECT_INDUSTRY: 'Select industry from the dropdown.',
          WEEKLY_STATUS_RESET_LABEL: 'Weekly status reset',
          WEEKLY_STATUS_RESET_HELPER_TEXT:
            'Please select the day and time when the weekly status should be reset (after the weekly all-hands call).',
          SUPPORT_EMAIL_LABEL: 'Support email',
          SUPPORT_EMAIL_HELPER_TEXT:
            'This support email inbox should be specific to the engagement and will be visible in the global sidebar navigation help icon.',
          SUPPORT_EMAIL_PLACEHOLDER: 'Enter the support email address',
          FIRST_DAY_LABEL: 'Day 1',
          FIRST_DAY_HELPER_TEXT:
            'Select your Day 1 date here which will be key to how various modules of my{{managementType}} operate.',
          FIELDS_LABEL: {
            FISCAL_YEAR: 'Fiscal year',
          },
          HELPER_TEXT: {
            FISCAL_YEAR: 'Select the first month of your fiscal year to enable this calendar view for value capture',
          },
          PLACEHOLDER: {
            FISCAL_YEAR: 'Select month',
            FIRST_DAY_PLACEHOLDER: 'Select date',
          },
        },
      },
    },
    VALUE_CAPTURE_V2: {
      INITIATIVE: {
        SPECIAL_ATTRIBUTES: {
          MIRRORED_FROM: 'Mirrored from',
          MIRRORED_TO: 'Mirrored to',
          ORG_DESIGN_INITIATIVE: 'Linked to org layer',
          ONE_TIME_BENEFIT: 'One-time benefit',
        },
        LINK_ORG_DESIGN: {
          TOOLTIP: 'This org design layer is automatically linked to this value capture initiative',
          LABEL: 'Linked org design layer',
          BUTTON: 'Go to org design',
          VALUE: 'Org design {{name}}',
        },
        LINK_PROJECT: {
          TOOLTIP_WITH_VALUE: 'Go to the linked project',
          TOOLTIP_WITHOUT_VALUE:
            'Click here to go to the <strong>Project Map</strong> to link a project to this {{type}}',
          LABEL: 'Linked project',
          BUTTON_WITH_VALUE: 'Go to project',
          BUTTON_WITHOUT_VALUE: 'Go to project map',
        },
        LINK_INITIATIVE: {
          TOOLTIP_WITH_VALUE: 'Go to the linked {{linkType}}',
          TOOLTIP_WITHOUT_VALUE:
            'Click here to go to the <strong>Project Map</strong> to link a {{linkType}} to this {{type}}',
          OTC_LABEL: 'Linked one-time cost',
          SYNERGY_LABEL: 'Linked synergy initiative',
          OTC_BUTTON_WITH_VALUE: 'Go to one-time cost',
          SYNERGY_BUTTON_WITH_VALUE: 'Go to initiative',
          BUTTON_WITHOUT_VALUE: 'Go to project map',
        },
        ASIDE: {
          OD_INITIATIVE_CALLOUT_TEXT:
            'This initiative is linked to an Org Design layer. L1-L4 stage gates are automatically linked to the Org Design layer stages. However, the L5 stage gate will require manual submission and approval.',
        },
        TOOLTIPS: {
          CALCULATED_COLUMN: 'This is a calculated column',
          NPV_TOOLTIP: 'The percentages used for the calculation are set on <strong>Admin Configuration</strong>:',
          DEFAULT_MIRROR_TOOLTIP: 'This can be changed by editing the mirroring options for this initiative.',
          INITIATIVE_MIRRORED_FROM:
            'This initiative is mirrored from the team: <strong>{{teamName}}</strong> team ({{totalImpact}} total impact, {{mirroredImpact}} mirrored impact)',
          INITIATIVE_MIRRORED_TO_SINGLE:
            'This initiative is mirrored to the following team: <strong>{{teamName}}</strong> {{runRateImpact}}',
          INITIATIVE_MIRRORED_TO_MULTIPLE: 'This initiative is mirrored to the following teams: ',
          NETWORTH_MIRRORED_FROM: 'This net impact is mirrored from the <strong>{{teamName}}</strong> team',
          NETWORTH_MIRRORED_TO_SINGLE: 'This net impact is mirrored to the <strong>{{teamName}}</strong> team',
          NETWORTH_MIRRORED_TO_MULTIPLE: 'This net impact is mirrored to the following teams: ',
          ORG_DESIGN_INITIATIVE: 'Linked to org layer',
          ONE_TIME_BENEFIT_GENERAL_TOOLTIP:
            'Enable this setting if your value capture initiative generates a benefit that should not count towards the overall run-rate targets',
          ONE_TIME_BENEFIT_TO_ENABLE: 'To enable this, please delete all data in the Detailed Financials',
          ONE_TIME_BENEFIT_TO_DISABLE: 'To disable this, please delete all data in the Detailed Financials',
          ONE_TIME_BENEFIT_DISABLED_RESET: 'This is disabled because this initiative is a one-time benefit',
          ORG_LAYER_AF_DISABLED: 'Advanced Financials is disabled for initiatives linked to Org design layers',
          COST_FTE_DISABLED: `This value will be automatically populated when the linked Org Design layer's design phase moves to <strong>Ready for review</strong>`,
        },
        COLUMNS: {
          VC2_0: {
            NPV_PLANNED: 'NPV (plan)',
            NPV_FORECAST: 'NPV (forecast)',
          },
          VC2_1: {
            NPV_PLANNED: 'Plan NPV',
            NPV_FORECAST: 'Forecast NPV',
          },
        },
      },
      ACTIVE_USER_CELL_INFO: 'Editing {{field}} for {{initiativeName}}',
    },
    EXECUTIVE_DASHBOARD: {
      TOP_HEADER: { TITLE: 'Executive Dashboard' },
      GRID: {
        TEAM_HEADER: 'Team',
        START_DATE: 'Start date',
        DUE_DATE: 'Due date',
        FORECAST_DATE: 'Forecast due date',
        CREATED_AT: 'Date created',
        UPDATED_AT: 'Last update',
        UPDATED_BY: 'Updated by',
        CONFIDENTIAL: 'Confidential',
        PROJECT_LIST_ID: 'ID',
        RISK_ID: 'ID',
        DECISION_ID: 'ID',
        ISSUE_ID: 'Issue ID',
        HYPERCARE_DESCRIPTION: 'Description of the issue and its impact',
        HYPERCARE_COMMENTS: 'Comments on the status',
        IMO_VISIBILITY: '{{managementType}} visibility',
        FLAG_TO_IMO: 'Flag to {{managementType}}',
        DESCRIPTION: 'Description',
        SPECIAL_ATTRIBUTES: 'Special attributes',
        UNIQUE_ID: 'Unique ID',
        CATEGORY: 'Category',
        STATUS: 'Status',
        TOPIC: 'Topic',
        POTENTIAL_IMPACT: 'Potential impact',
        PROBABILITY: 'Probability',
        MITIGATION_ACTIONS: 'Mitigation actions',
        TEAM_POC: 'Team PoC',
        DECISION_MAKER: 'Decision maker',
        COMMENTS: 'Comments',
        PRIORITY: 'Priority',
        ISSUE_NAME: 'Issue name',
        CRITICALITY: 'Criticality',
        POINT_OF_CONTACT: 'Point of contact',
        DATE_RAISED: 'Date raised',
        DATE_RESOLVED: 'Date resolved',
        INCOMING: 'Incoming',
        OUTGOING: 'Outgoing',
        COST_IMPACT: 'cost impact',
        FORECAST_COST_IMPACT: 'Forecast cost impact',
        PLAN_COST_IMPACT: 'Plan cost impact',
        REVENUE_IMPACT: 'revenue impact',
        PLAN_REVENUE_IMPACT: 'Plan revenue impact',
        FORECAST_REVENUE_IMPACT: 'Forecast revenue impact',
        VALUE_CAPTURE_ID: 'ID',
        COST: 'Cost',
        REVENUE: 'Revenue',
        STAGE_GATE: 'Stage gate',
        NAME: 'Name',
        MARGIN_IMPACT: 'margin impact',
        PLAN_MARGIN_IMPACT: 'Plan margin impact',
        FORECAST_MARGIN_IMPACT: 'Forecast margin impact',
        FORECAST_NWC_IMPACT: 'Forecasted NWC/Capex impact',
        MARGIN_PERCENT: 'Margin %',
        L4DATE: 'L4 date (plan)',
        L5DATE: 'L5 date (plan)',
        L4DATE_FORECAST: 'Forecasted L4 date',
        L5DATE_FORECAST: 'Forecasted L5 date',
        NPV_PLAN: 'NPV (plan)',
        NPV_FORECAST: 'NPV (forecast)',
        FTE: '{{prefix}} FTE',
        FORECAST_FTE: 'Forecast FTE',
        OTC: 'One-time cost',
        NWC_CAPEX_IMPACT: 'NWC/Capex impact',
        PLANNED_NWC_CAPEX_IMPACT: 'Planned NWC/Capex impact',
        NWC_CAPEX: 'NWC/Capex',
        OWNERS: 'Owners',
        INITIATIVE_MIRROR_one: 'Mirrored to the {{teams}} team',
        INITIATIVE_MIRROR_other: 'Mirrored to the {{teams, list}} teams',
      },
      MENU: {
        PRIORITIES_ALL_SELECITON: 'All priorities',
        PRIORITIES_NO_PRIORITY: 'No priority',
        PRIORITIES: 'priorities',
      },
      CHART: {
        SET_CHART: 'Set chart',
        INVALID_CARD_HEADER: 'Unavailable chart',
        CARD_HEADER: '{{domainName}} by ',
        DOUGHNUT: 'Doughnut',
        PIE: 'Pie',
        BAR: 'Bar',
        WATERFALL: 'Waterfall',
        DATA_TYPE: 'Data type',
        CHART_TYPE: 'Chart type',
        DEFAULT_EMPTY: 'Not selected',
      },
      DOMAINS: {
        ORG_DESIGN: 'Org design',
        VALUE_CAPTURE: 'Value Capture',
      },
    },
    ERROR_DIALOG: {
      MESSAGE:
        ' myIMO has encountered an error. Please contact your program administrator with the specific steps you took that triggered this error, and a copy of the error message text below so that they can further assist you.',
      QUOTE_ERROR_ID: 'Please quote the error ID',
      ERROR_STACK: 'Error stack',
      COMPONENT_STACK: 'Component stack',
      PLEASE_CLICK: 'Please click on the button below to send us a copy of the message, so we can investigate.',
      REFRESH_PAGE: 'Refresh page',
      SEND_REPORT: 'Send report',
      PAGE_URL: 'Page URL',
      ERROR_ID: 'Error ID',
      NEGATIVE_INITIATIVE_VALUE_PRE: 'This initiative will be regarded as a ',
      DIS_SYNERGY: 'dis-synergy',
      NEGATIVE_INITIATIVE_VALUE_POST: ' given the negative impact',
    },
    ORG_DESIGN: {
      BASELINE: 'Baseline',
      FTES_one: '{{value}} FTE',
      FTES_other: '{{value}} FTEs',
      LAYERS_one: '{{value}} layer',
      LAYERS_other: '{{value}} layers',
      PHASES: {
        DESIGN: 'Design',
        SELECTION: 'Selection',
        IMPLEMENTATION: 'Implementation',
      },
      STAGES: {
        TO_DO: 'To do',
        IN_PROGRESS: 'In progress',
        READY_FOR_REVIEW: 'Ready for review',
        FINAL_SUBMITTED: 'Final (submitted)',
        FINAL_APPROVED: 'Final (approved)',
        TRANSITION_PLAN: 'Transition plan',
        ONE_TO_ONE_COMMUNICATION: '1:1 communication',
        ANNOUNCED: 'Announced',
        IMPLEMENTATION_SUBMITTED: 'Imp. (submitted)',
        IMPLEMENTATION_APPROVED: 'Imp. (approved)',
        IMPLEMENTATION_APPROVED_LONG: 'Implementation (approved)',
      },
    },
    CALENDAR_YEAR: {
      YEAR_1: 'Year {{year}}',
      FISCAL_YEAR: 'FY{{year}}',
    },
    TEAM_CHARTER: {
      ERRORS: {
        REORDERING_FAILED: 'Unable to reorder items, please try again.',
        MPA_DRAGGING_PARENT_AND_CHILD: 'Parent and child items cannot be moved together.',
        MPA_DRAGGING_PARENT_INVALID_POSITION: 'Parent items must be moved below the last item of another parent.',
        MPA_DRAGGING_CHILDREN_WITH_MULTIPLE_PARENT_IDS: 'Unable to move items across multiple parents.',
        MPA_DRAGGING_CHILDREN_DUPLICATE_NAME_one: 'Macro assumption {{name}} already exists.',
        MPA_DRAGGING_CHILDREN_DUPLICATE_NAME_other: 'Macro assumptions {{name}} already exist.',
      },
      ACTIVITY_LOG: {
        MESSAGES: {
          TEAM_DELIVERABLE: {
            META_KEY_RISKS: {
              UPDATE:
                '<strong>{{user}}</strong> has renamed <strong>{{previous}}</strong> to <strong>{{current}}</strong>',
            },
            META_TEAM_MEMBERS: {
              UPDATE:
                '<strong>{{user}}</strong> has renamed <strong>{{previous}}</strong> to <strong>{{current}}</strong>',
            },
            META_KEY_DECISIONS: {
              UPDATE:
                '<strong>{{user}}</strong> has renamed <strong>{{previous}}</strong> to <strong>{{current}}</strong>',
            },
            META_KEY_PROCESSES: {
              UPDATE:
                '<strong>{{user}}</strong> has renamed <strong>{{previous}}</strong> to <strong>{{current}}</strong>',
            },
            META_KEY_INTERDEPENDENCIES: {
              UPDATE:
                '<strong>{{user}}</strong> has renamed <strong>{{previous}}</strong> to <strong>{{current}}</strong>',
            },
            META_OUT_SCOPE_KEY_PROCESSES: {
              UPDATE:
                '<strong>{{user}}</strong> has renamed <strong>{{previous}}</strong> to <strong>{{current}}</strong>',
            },
            META_MACRO_PLANNING_ASSUMPTIONS: {
              UPDATE:
                '<strong>{{user}}</strong> has renamed <strong>{{previous}}</strong> to <strong>{{current}}</strong>',
            },
          },
          TEAM_CHARTER: {
            LIBRARY_ITEMS: {
              UPDATE: '<strong>{{user}}</strong> has imported data from the library to {{section, stronglist}}',
            },
            DATA_SPONSORS: {
              UPDATE:
                '<strong>{{user}}</strong> has updated the <strong>Executive sponsor(s)</strong> from {{previous}} to {{current}}',
              UPDATE_from_blank:
                '<strong>{{user}}</strong> has added {{current}} as an <strong>Executive sponsor(s)</strong>',
              UPDATE_to_blank:
                '<strong>{{user}}</strong> has removed {{previous}} as an <strong>Executive sponsor(s)</strong>',
            },
          },
          TEAM_CHARTER_MEMBER: {
            USER_NAME_A: {
              UPDATE:
                '<strong>{{user}}</strong> has replaced {{previous}} with {{current}} from {{companyAName}} for "{{row}}" topic in <strong>{{section}}</strong>',
              UPDATE_from_blank:
                '<strong>{{user}}</strong> has set {{current}} from {{companyAName}} for "{{row}}" topic in <strong>{{section}}</strong>',
              UPDATE_to_blank:
                '<strong>{{user}}</strong> has removed {{previous}} from {{companyAName}} for "{{row}}" topic in <strong>{{section}}</strong>',
            },
            USER_NAME_B: {
              UPDATE:
                '<strong>{{user}}</strong> has replaced {{previous}} with {{current}} from {{companyBName}} for "{{row}}" topic in <strong>{{section}}</strong>',
              UPDATE_from_blank:
                '<strong>{{user}}</strong> has set {{current}} from {{companyBName}} for "{{row}}" topic in <strong>{{section}}</strong>',
              UPDATE_to_blank:
                '<strong>{{user}}</strong> has removed {{previous}} from {{companyBName}} for "{{row}}" topic in <strong>{{section}}</strong>',
            },
          },
          TEAM_CHARTER_KEY_INTERDEPENDENCY: {
            NAME: {
              UPDATE:
                '<strong>{{user}}</strong> has updated "{{previous}}" to "{{current}}" rationale for {{row}} in <strong>{{section}}</strong>',
              UPDATE_from_blank:
                '<strong>{{user}}</strong> has added "{{current}}" rationale for {{row}} in <strong>{{section}}</strong>',
              UPDATE_to_blank:
                '<strong>{{user}}</strong> has deleted "{{previous}}" rationale for {{row}} in <strong>{{section}}</strong>',
            },
            TEAM: {
              UPDATE:
                '<strong>{{user}}</strong> has updated "{{previous}}" to "{{current}}" team name in <strong>{{section}}</strong>',
            },
          },
          DEFAULT: {
            CREATE: '<strong>{{user}}</strong> has added "{{current}}" {{type}} to <strong>{{section}}</strong>',
            UPDATE:
              '<strong>{{user}}</strong> has updated "{{previous}}" to "{{current}}" {{type}} in <strong>{{section}}</strong>',
            DELETE: '<strong>{{user}}</strong> has deleted "{{current}}" {{type}} from <strong>{{section}}</strong>',
          },
        },
      },
      HISTORY_VIEW: {
        BANNER: 'You are viewing a historical read-only version of this page from {{date}}',
        BUTTON: 'View current version',
        DIALOG_TEXT: 'Select a date to view. The data displayed will be from 00:00 GMT on the selected day.',
      },
      ACTIVE_USER_CELL_INFO: 'Editing {{type}}',
      SECTION_TITLES: {
        EXECUTIVE_SPONSORS: 'Executive sponsor(s)',
      },
    },
    TEAM_STATUS_UPDATE: {
      ACTIVITY_LOG: {
        MESSAGES: {
          TEAM_WEEKLY_STATUS: {
            NAME: {
              UPDATE:
                '<strong>{{user}}</strong> has updated the <strong>Status</strong> from {{previous}} to {{current}}',
            },
            UPDATED_AT: {
              UPDATE: '<strong>{{user}}</strong> has submitted the <strong>Weekly Status</strong>',
            },
            RATIONALE: {
              CREATE: '<strong>{{user}}</strong> has added the <strong>Status rationale</strong>',
              DELETE: '<strong>{{user}}</strong> has removed the <strong>Status rationale</strong>',
              UPDATE: '<strong>{{user}}</strong> has updated the <strong>Status rationale</strong>',
            },
          },
          OTHER_TEAM_UPDATE: {
            CREATE:
              '<strong>{{user}}</strong> has added "{{entity.description}}" in <strong>Other team updates</strong>',
            DELETE:
              '<strong>{{user}}</strong> has deleted "{{entity.description}}" in <strong>Other team updates</strong>',
            DESCRIPTION: {
              UPDATE:
                '<strong>{{user}}</strong> has updated "{{previous}}" to "{{current}}" in <strong>Other team updates</strong>',
            },
          },
          TEAM_WEEKLY_MEETING_TOPIC: {
            CREATE: '<strong>{{user}}</strong> has added "{{entity.topicName}}" in <strong>Requested by Team</strong>',
            DELETE:
              '<strong>{{user}}</strong> has deleted "{{entity.topicName}}" in <strong>Requested by Team</strong>',
            TOPIC_NAME: {
              UPDATE:
                '<strong>{{user}}</strong> has updated "{{previous}}" to "{{current}}" in <strong>Requested by Team</strong>',
            },
          },
          DEFAULT: {
            CREATE: '<strong>{{user}}</strong> has added "{{current}}"',
            UPDATE: '<strong>{{user}}</strong> has updated "{{previous}}" to "{{current}}"',
            DELETE: '<strong>{{user}}</strong> has deleted "{{current}}"',
          },
        },
      },
    },
  },
}
