import React from 'react'
import { TooltipContent } from '@shared/Popover'
import { get } from 'lodash/fp'
import { synergyTypes } from '@helpers/constants'

export const LOCKED_VALUE = 'locked_value'
export const LAST_YEAR = 'last_year'
export const IN_PERIOD_TARGET = 'inPeriodTarget'
export const RUN_RATE_TARGET = 'runRateTarget'
export const BASELINE = 'baseline'
export const YEAR_ONE = 'Year 1'

export const lastYearTooltip = `End-state or long-term target for the team`

export const yearOneTooltip = 'Target to achieve in first 12 months after Day 1'

export const valueTypesMapping = {
  [synergyTypes.COST]: {
    label: 'cost',
    longLabel: 'cost',
    headerTooltipBaseline: (
      <TooltipContent>
        Combined <b>cost</b> structure in-scope for this team, typically based on last fiscal year results.
      </TooltipContent>
    ),
    headerTooltipRunRate: ({ lastYear }) =>
      `Enter the annual run-rate synergy target each team is expected to achieve at the end of each calendar year. The target value in the final year ${lastYear} will represent the full target that each team is measured against.`,
    headerTooltipTarget: ({ lastYear }) =>
      `Enter the synergy target that each team is expected to capture in-period for that calendar year. The target value in the final year ${lastYear} will represent the full target that each team is measured against.`,
    cellTooltip: (value, { isYearColumn, context: { currencyInstance } }) => (
      <TooltipContent>
        <b>{currencyInstance.roundCurrencyWithSymbolAndWord(get('value', value))}</b> in cost{' '}
        {isYearColumn && ' synergies'}
        <br />
        <b>{get('FTE', value) || 0}</b> FTEs
      </TooltipContent>
    ),
    getDefaultValue: ({ groupId }) => (groupId === IN_PERIOD_TARGET ? { value: null } : { value: null, FTE: null }),
  },
  [synergyTypes.REVENUE]: {
    label: 'revenue',
    longLabel: 'revenue',
    headerTooltipBaseline: (
      <TooltipContent>
        Combined <b>revenue</b> structure in-scope for the team, typically based on last year&apos;s fiscal results.
      </TooltipContent>
    ),
    headerTooltipRunRate: ({ lastYear }) =>
      `Enter the annual run-rate synergy target each team is expected to achieve at the end of each calendar year. The target value in the final year ${lastYear} will represent the full target that each team is measured against.`,
    headerTooltipTarget: ({ lastYear }) =>
      `Enter the synergy target that each team is expected to capture in-period for that calendar year. The target value in the final year ${lastYear} will represent the full target that each team is measured against.`,
    cellTooltip: (value, { isYearColumn, context: { currencyInstance } }) => (
      <TooltipContent>
        <b>{currencyInstance.roundCurrencyWithSymbolAndWord(get('value', value))}</b> in revenue{' '}
        {isYearColumn && ' synergies'}
        <br />
        <b>{currencyInstance.roundCurrencyWithSymbolAndWord(get('gross_margin', value))}</b> in gross margin
      </TooltipContent>
    ),
    getDefaultValue: () => ({ value: null, gross_margin: null }),
  },
  [synergyTypes.NWC]: {
    label: 'NWC/Capex',
    longLabel: 'net-working capital',
    headerTooltipBaseline: (
      <TooltipContent>
        Combined <b>net-working capital</b> structure in-scope for the team, typically based on last year&apos;s fiscal
        results.
      </TooltipContent>
    ),
    headerTooltipRunRate: (
      <TooltipContent>
        For <b>net-working capital</b> synergies, there are no recurring savings assumed. Please fill out the in-period
        target by year.
      </TooltipContent>
    ),
    headerTooltipTarget:
      'Enter the synergy target that each team is expected to capture in-period for that calendar year. Targets are cumulative relative to the baseline starting point.',
    cellTooltip: (value, { isYearColumn, context: { currencyInstance } }) => (
      <TooltipContent>
        <b>{currencyInstance.roundCurrencyWithSymbolAndWord(value)}</b> in net-working capital{' '}
        {isYearColumn && ' synergy'}
      </TooltipContent>
    ),
    getDefaultValue: ({ groupId }) => ([IN_PERIOD_TARGET, BASELINE].includes(groupId) ? { value: null } : null),
  },
}

export const SHARED_TARGETS_TOOLTIP_TEXT =
  "These integration teams don't own P&L. They share targets with other integration teams. Hide their baseline and top down target from dashboards."

export const MAX_DECIMAL_COUNT = 6
